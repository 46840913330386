import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getData, postData, updateImage } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/toaster';
import {
  deleteChallanFail,
  deleteChallanSuccess,
  getChallanDetails,
  getChallanDetailsFail,
  getChallanDetailsSuccess,
  getChallanFail,
  getChallanStock,
  getChallanStockFail,
  getChallanStockSuccess,
  getChallanSuccess,
  storeChallanData,
  getBufferChallanSuccess,
  getBufferChallanFail,
  getMissingChallanSuccess,
  getMissingChallanFail,
  getDamageChallanSuccess,
  getDamageChallanFail,
  getIssueListSuccess,
  getIssueListFail,
  getSettlementChallanSuccess,
  getSettlementChallanFail,
  getChallan
} from './action.js';
import {
  ADD_CHALLAN,
  ADD_DH_CHALLAN,
  CHALLAN_APPROVED,
  CHALLAN_DELETE,
  GET_CHALLAN,
  GET_CHALLAN_DETAILS,
  GET_CHALLAN_STOCK_LIST,
  UPDATE_CHALLAN_DETAILS,
  RETURN_CHALLAN,
  GET_DAMAGE_CHALLAN,
  GET_MISSING_CHALLAN,
  GET_BUFFER_CHALLAN,
  GET_ISSUE_LIST,
  GET_SETTLEMENT_CHALLAN,
  CLAIM_CHALLAN,
  UPDATE_CHALLAN_IMAGE
} from './actionTypes';

function* fetchChallan({
  payload: { authtoken, pageRange, currentPage, distribution, status, value, settlement, damageStatus, missingStatus, bufferStatus },
}) {
  try {
    // console.log(role)
    // const newQuery = `?${query}`
    const url = `/dh-inventory/get-challan-list?limit=${pageRange}&pageNo=${currentPage}&distributionHubId=${distribution}&status=${status}&challanNo=${value}&settlement=${settlement || ""}&damageStatus=${damageStatus|| ""}&missingStatus=${missingStatus || ""}&bufferStatus=${bufferStatus || ""}`;
    const response = yield call(getData, url, authtoken);

    yield put(getChallanSuccess(response));
  } catch (error) {
    yield put(getChallanFail(error));
  }
}

function* fetchStokeList({ payload: { authtoken, warehouseId } }) {
  try {
    // console.log(role)
    // const newQuery = `?${query}`
    const url = `/product/get-all-product-stock?warehouse=${warehouseId}`;
    const response = yield call(getData, url, authtoken);
    console.log('rrrr', response);
    response?.totalProducts?.forEach((product, index) => {
      product.quantity = 0;
      product.totalAmount = 0;
      product.unitPrice = product?.unitPrice || 0;
    });

    yield put(getChallanStockSuccess(response));
    yield put(storeChallanData('challanListStock', response.totalProducts));
  } catch (error) {
    yield put(getChallanStockFail(error));
  }
}

function* fetchChallanDetails({ payload: { authtoken, id } }) {
  try {
    const url = `/dh-inventory/get-challan-details/${id}`;
    const response = yield call(getData, url, authtoken);
    console.log('response', response);
    response?.challanDetails?.forEach((product, index) => {
      // product.receivedQty = product?.quantity;
      product.receivedQtyData = product?.quantity;
      product.challanDamageData = product?.damageQty;
      product.missingQtyInfo = product?.missingQty;
      product.missingQty = 0;
      product.bufferQty = 0;
      product.damageQtyData = 0;
      product.missingStatus = false;
      product.damageStatus = false;
      product.bufferStatus = false;
      product.IsChecked = false
      product.returnQty = product?.quantity - product?.receivedQty
    });
    yield put(storeChallanData('callanRecieved', response?.challanDetails));
    yield put(storeChallanData('MissedChallan', response?.challanDetails));
    yield put(storeChallanData('challanDetailsInfo', response?.challanDetails));
    yield put(storeChallanData('damageChallan', response?.challanDetails));
    yield put(getChallanDetailsSuccess(response));
  } catch (error) {
    yield put(getChallanDetailsFail(error));
  }
}

function* addChallanData({ payload: { data, history, authtoken } }) {
  try {
    const url = `/dh-inventory/dis-hub-inventory-challan-create`;
    const response = yield call(postData, url, data, authtoken);
    yield put(storeChallanData('ChallanData', response));
    yield put(storeChallanData('addChallanLoading', false));
    toast('Challan added successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.push('/challan');
  } catch (error) {
    console.log(error.response);
  }
}
function* addChallanConfirmation({
  payload: { data, history, authtoken, id },
}) {
  try {
    const url = `/dh-inventory/approve-chalan/${id}`;
    const response = yield call(postData, url, data, authtoken);
    yield put(storeChallanData('challanConfirmation', response));
    yield put(storeChallanData('approveChallanLoading', false));
    toast('Challan Approved successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.push('/challan');
  } catch (error) {
    console.log(error.response);
  }
}
function* DeleteChallan({
  payload: {
    data,
    options: { authtoken, id, toggle, challanID, challanId },
  },
}) {
  try {
    const url = `/dh-inventory/dis-hub-inventory-challan-product-delete/${id}`;
    const response = yield call(postData, url, data, authtoken);
    yield put(deleteChallanSuccess(response));
    toaster('success', 'Challan Deleted successfully!');
    toggle();
    yield put(getChallanDetails(authtoken, challanID));
    yield put(getChallanStock(authtoken, challanId));
  } catch (error) {
    const message = error.response?.data?.message || 'Something went wrong!';
    toaster('error', message);
    yield put(deleteChallanFail());
    console.log(error.response);
  }
}

function* onUpdateChallan({
  payload: {
    data,
    options: { authtoken, id, setChallan, challanID },
  },
}) {
  try {
    const url = `/dh-inventory/product-add-to-challan`;
    const response = yield call(postData, url, data, authtoken);
    if (response) {
      yield put(storeChallanData('addChallanLoading', false));
      toaster('success', 'Challan added successfully!');
      yield put(getChallanDetails(authtoken, id));
      yield put(getChallanStock(authtoken, challanID));
      setChallan({ itemId: '', quantity: '', unitPrice: '', price: '' });
    }
  } catch (error) {
    const message = error.response?.data?.message || 'Something went wrong!';
    toaster('error', message);
    yield put(storeChallanData('addChallanLoading', false));
  }
}

function* returnChallan({ payload: { data, history, authtoken } }) {
  try {
    const url = `dh-inventory/challan-settlement`;
    const response = yield call(postData, url, data, authtoken);
    yield put(storeChallanData('returnChallanLoading', false));
    toast('Challan settlement successful!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.push('/challan');
  } catch (error) {
    yield put(storeChallanData('returnChallanLoading', false));
    console.log(error.response);
  }
}
function* addDhChallanData({ payload: { data, history, authtoken } }) {
  try {
    const url = `/dh-inventory/hub-to-hub-challan`;
    const response = yield call(postData, url, data, authtoken);
    // yield put(storeChallanData('ChallanData', response));
    yield put(storeChallanData('addDhChallanLoading', false));
    toast('Challan added successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.push('/challan');
  } catch (error) {
    console.log(error.response);
  }
}
function* fetchDamageChallan({
  payload: { authtoken, pageRange, currentPage, distribution, status, value },
}) {
  try {
    // console.log(role)
    // const newQuery = `?${query}`
    const url = `/dh-inventory/get-challan-list?limit=${pageRange}&pageNo=${currentPage}&distributionHubId=${distribution}&status=${status}&challanNo=${value}`;
    const response = yield call(getData, url, authtoken);

    yield put(getDamageChallanSuccess(response));
  } catch (error) {
    yield put(getDamageChallanFail(error));
  }
}
function* fetchMissingChallan({
  payload: { authtoken, pageRange, currentPage, distribution, status, value },
}) {
  try {
    // console.log(role)
    // const newQuery = `?${query}`
    const url = `/dh-inventory/get-challan-list?limit=${pageRange}&pageNo=${currentPage}&distributionHubId=${distribution}&status=${status}&challanNo=${value}`;
    const response = yield call(getData, url, authtoken);

    yield put(getMissingChallanSuccess(response));
  } catch (error) {
    yield put(getMissingChallanFail(error));
  }
}
function* fetchBufferChallan({
  payload: { authtoken, pageRange, currentPage, distribution, status, value },
}) {
  try {
    // console.log(role)
    // const newQuery = `?${query}`
    const url = `/dh-inventory/get-challan-list?limit=${pageRange}&pageNo=${currentPage}&distributionHubId=${distribution}&status=${status}&challanNo=${value}`;
    const response = yield call(getData, url, authtoken);

    yield put(getBufferChallanSuccess(response));
  } catch (error) {
    yield put(getBufferChallanFail(error));
  }
}
function* claimChallan({
  payload: { data, history, authtoken},
}) {
  try {
    const url = `dh-inventory/claim-damage-product`;
    const response = yield call(postData, url, data, authtoken);
  
    yield put(storeChallanData('claimChallanLoading', false));
    toast('Challan claimed successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.push('/challan');
  } catch (error) {
    console.log(error.response);
    yield put(storeChallanData('claimChallanLoading', false));
  }
}

function* updateChallanImage({
  payload: { data, history, authtoken, id},
}) {
  console.log('history',history);
  try {
    const url = `dh-inventory/claim-update/${id}`;
    const response = yield call(updateImage, url, data, authtoken);
  
    yield put(storeChallanData('updateChallanImageLoading', false));
    toast('Image Updated successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(getChallan(authtoken, 100, 1, "", "", "", "","","",""));
    
  } catch (error) {
    console.log(error.response);
    yield put(storeChallanData('updateChallanImageLoading', false));
  }
}
function* challanSaga() {
  yield takeEvery(GET_CHALLAN, fetchChallan);
  yield takeEvery(ADD_CHALLAN, addChallanData);
  yield takeEvery(GET_CHALLAN_DETAILS, fetchChallanDetails);
  yield takeEvery(CHALLAN_APPROVED, addChallanConfirmation);
  yield takeEvery(GET_CHALLAN_STOCK_LIST, fetchStokeList);
  yield takeEvery(CHALLAN_DELETE, DeleteChallan);
  yield takeEvery(UPDATE_CHALLAN_DETAILS, onUpdateChallan);
  yield takeEvery(RETURN_CHALLAN, returnChallan);
  yield takeEvery(ADD_DH_CHALLAN, addDhChallanData);
  yield takeEvery(GET_DAMAGE_CHALLAN, fetchDamageChallan);
  yield takeEvery(GET_MISSING_CHALLAN, fetchMissingChallan);
  yield takeEvery(GET_BUFFER_CHALLAN, fetchBufferChallan);
  yield takeEvery(CLAIM_CHALLAN, claimChallan);
  yield takeEvery(UPDATE_CHALLAN_IMAGE, updateChallanImage);
}

export default challanSaga;
