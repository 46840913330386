import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Col, Form, Input, Row, Spinner, Table } from "reactstrap";
import * as XLSX from "xlsx";
import { get } from "../../helpers/api_helper";

import { useFormik } from "formik";
import { toast } from "react-toastify";
import Question from "../../assests/images/question.svg";
import {
  claimChallanData,
  getChallan,
  getChallanDetails,
  getChallanStock,
  storeChallanData,
  updateChallanImageData
} from "../../store/Challan/action";
import { getSingleDistribution } from "../../store/Distribution Hub/actions";
import SearchInput from "../Atoms/SearchInput";
import FileUpload from "../Common/FileUpload";
import Pagination from "../Common/Pagination";
import CardComponent from "../Layout/CardComponent";
import InnerLayer from "../Layout/InnerLayer";
import { updateChallanSchema } from "../Schemas/UpdateChallan.schema";
const tableHead = [
  "No.",
  "Delivery From",
  "Central Warehouse",
  "Vendor",
  "Hub Name",
  "Challan",
  "Date",
  "Image",
  "Status",
  "Action",
];
const renderTooltipPrint = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Print View
  </Tooltip>
);
const renderTooltipEdit = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Edit
  </Tooltip>
);
const renderTooltipView = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    View
  </Tooltip>
);
const renderTooltipUpdateImage = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Update Image
  </Tooltip>
);
const initialValues = {
  
  photo:""
 
};
const Challan = ({ history }) => {
  const [detailShow, setDetailShow] = useState(false);
  const [updateImageModal, setUpdateImageModal] = useState(false);
  const [imageUp, setImageUp] = useState("");
  const [updateCallanId, setUpdateCallanId] = useState("");
  const [challanUpdateShow, setChallanUpdateShow] = useState(false);
  let dispatch = useDispatch();
  const [damageChallanID, setDamageChallanID] = useState("");
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(100);
  //let totalPageNumber = Math.ceil(areaData?.length / dataRange)
  const [status, setStatus] = useState("");
  const [issueStatus, setIssueStatus] = useState("");
  const [damageStatus, setDamageStatus] = useState("");
  const [missingStatus, setMissingStatus] = useState("");
  const [bufferStatus, setBufferStatus] = useState("");
  const [distribution, setDistribution] = useState("");
  const [displayImage, setDisplayImage] = useState("")
  const [displayRecieveImage, setDisplayRecieveImage] = useState("")
  // const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [imageShow, setImageShow] = useState(false)
  const [recieveImageShow, setRecieveImageShow] = useState(false)
  
  const [challanList, setChallanList] = useState([
    {
      itemId: "",
      quantity: "",
    },
  ]);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    console.log("data", data);
    setDamageChallanID(data?._id)
    setShow(true);
  };

  const {
    role,
    authtoken,
    challanData,
    singleDistribution,
    challanDetails,
    userId,
    challanDataLoading,
    MissedChallan,
    returnChallanLoading,
    damageChallan,
    claimChallanLoading,
    updateChallanImageLoading
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    role: state.Login.userrole,
    challanData: state.ChallanReducer.challanData,
    challanDataLoading: state.ChallanReducer.challanDataLoading,
    singleDistribution: state.DistributionReducer.singleDistribution,
    MissedChallan: state.ChallanReducer.MissedChallan,
    damageChallan: state.ChallanReducer.damageChallan,
    challanDetails: state.ChallanReducer.challanDetails,
    userId: state.Login.warehouse,
    returnChallanLoading: state.ChallanReducer.returnChallanLoading,
    claimChallanLoading: state.ChallanReducer.claimChallanLoading,
    updateChallanImageLoading: state.ChallanReducer.updateChallanImageLoading,
  }));
  console.log("challanData", challanData);
  console.log("challanDetails", challanDetails);
  let totalPageNumber = Math.ceil(challanData?.data?.totalLength / pageRange);
  const handleStatus = (e) => {
    if(e.target.value ==="damageIssue"){
      setDamageStatus(true)
      setBufferStatus("");
      setMissingStatus("");
      setStatus("");
    }
    else if(e.target.value ==="missingIssue"){
      setMissingStatus(true);
      setBufferStatus("");
      setDamageStatus("")
      setStatus("");
    }
    else if(e.target.value ==="bufferIssue"){
     
      setBufferStatus(true);
      setMissingStatus("");
      setDamageStatus("")
      setStatus("");
    }
    else{
      setStatus(e.target.value);
      setBufferStatus("");
      setMissingStatus("");
      setDamageStatus("")
    }
 
  };
  const handlePageRange = (e) => {
    setPageRange(e.target.value);
  };
  const handleDistribution = (value) => {
    if (value) {
      setDistribution(value?._id);
    } else {
      setDistribution("");
    }
  };

  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, "Hub"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getChallan(
        authtoken,
        pageRange,
        currentPage,
        role === "HUB" ? userId : distribution,
        status,
        value,
        "",
        damageStatus,
        missingStatus,
        bufferStatus
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageRange, currentPage, distribution, status, value, damageStatus,missingStatus,bufferStatus]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value !== "") {
        setStatus("");
        setDistribution("");

        dispatch(
          getChallan(
            authtoken,
            pageRange,
            currentPage,
            role === "HUB" ? userId : distribution,
            status,
            value
          )
        );
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const handleChallanDetails = (data) => {
    setDetailShow(true);
    dispatch(getChallanDetails(authtoken, data._id));
  };
  const handleUpdateChallan = (data) => {
    setChallanUpdateShow(true);
    dispatch(getChallanDetails(authtoken, data._id));
    dispatch(getChallanStock(authtoken, data?._id));
  };

  const handleExcel = async () => {
    setLoading(true);
    let storeChallan = [];

    let totalPage = Math.ceil(challanData?.data?.totalLength / 3);
    for (let i = 0; i < totalPage; i++) {
      await get(
        `/dh-inventory/get-challan-list?limit=${3}&pageNo=${
          i + 1
        }&distributionHubId=${distribution}&status=${status}&challanNo=${value}`,
        { headers: { Authorization: `Bearer ${authtoken}` } }
      )
        .then((response) => {
          // setResults((prev) => [...prev, response?.challans]);
          response?.challans.forEach((challan, index) => {
            let data = {};
            data.ChallanNo = "CH- " + challan?.challanNo;
            data.deliverySource = challan?.deliveryFrom;
            data.hubName = challan?.distributionHubId?.name;
            data.challanDate = challan?.challanDate?.slice(0, 10);
            data.status = challan?.status;
            if (challan?.deliveryFrom === "Warehouse") {
              data.Warehouse = challan?.centralWarehouseId?.name;
            }
            if (challan?.deliveryFrom === "Vendor") {
              data.vendor = challan?.vendorId?.name;
            }

            storeChallan.push(data);
          });
        })
        .catch((error) => {
          // setResults((prev) => [...prev]);
        });
    }

    downloadxls(storeChallan);
  };

  const downloadxls = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Challan.xlsx");
    setLoading(false);
  };
  const handleSubmitQtyEdit = (e, idx, data) => {
    console.log("sd", e.target.value);
    const oldValue = [...damageChallan];
    console.log("oldValue", oldValue);

    if (e.target.value <= oldValue[idx]?.damageQty) {
      oldValue[idx].challanDamageData = e.target.value;
      oldValue[idx].claimQty = e.target.value;

      dispatch(storeChallanData("damageChallan", oldValue));

      console.log("oldValue", oldValue);
    }
    if (e.target.value * 1 > oldValue[idx]?.damageQty) {
      toast("Received QTY can not be bigger than Qty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //   var returnList = {};
  //   var returnProd = [];

  //   MissedChallan.map((data) => {
  //     if (data?.returnQty > 0) {
  //       returnList.itemId = data?.itemId?._id;
  //       returnList.returnQty = data?.returnQty;
  //       let dataList = { ...returnList };
  //       returnProd.push(dataList);
  //     }
  //   });
  //   const newProductList = returnProd.map((data) => ({
  //     itemId: data?.itemId,
  //     quantity: data?.returnQty * 1,
  //     unitPrice: 0,
  //   }));
  //   if (challanDetails?.data?.challan?.centralWarehouseId) {
  //     let body = {
  //       InventoryOperation: challanDetails?.data?.challan?.InventoryOperation,
  //       productList: newProductList,
  //       chalanId: challanDetails?.data?.challan?._id,
  //       centralWarehouseId:
  //         challanDetails?.data?.challan?.centralWarehouseId?._id,
  //       iswarehouse: true,
  //     };
  //     console.log("bbbbb", body);
  //     dispatch(returnChallan(body, history, authtoken));
  //     setShow(false);
  //     setDetailShow(false);
  //   } else {
  //     let body = {
  //       InventoryOperation: challanDetails?.data?.challan?.InventoryOperation,
  //       productList: newProductList,
  //       chalanId: challanDetails?.data?.challan?._id,
  //       iswarehouse: false,
  //     };
  //     console.log("bbbbb", body);
  //     dispatch(returnChallan(body, history, authtoken));
  //     setShow(false);
  //     setDetailShow(false);
  //   }

  //   //
  // };
  const handleClaimChallan = () => {
    console.log("damageChallan", damageChallan);
    // console.log("productData", productData);
    var objList = {};
    var dynamicProd = [];
    damageChallan.map((data) => {
      if (data?.challanDamageData > 0 && data?._id === damageChallanID) {
        objList.id = data?._id;
        objList.claimQty = data?.challanDamageData * 1;
        objList.claimStatus = true;
        let dataList = { ...objList };
        dynamicProd.push(dataList);
      }
    });
    console.log("dynamicProd", dynamicProd);
    const newProduct = dynamicProd.map((data) => ({
      id: data?.id,
      claimQty: data?.claimQty,
      claimStatus: data?.claimStatus,
    }));
    console.log("newProduct", newProduct[0]);
    dispatch(claimChallanData(newProduct[0], history, authtoken));
    setShow(false)
    setDetailShow(false)

  };
  const handleImageData = (data) =>{
    setImageShow(true)
    if(data){
      setDisplayImage(data?.image)
    }
  }
  const handleRecieveimage = (data) =>{
    setRecieveImageShow(true)
    if(data){
      setDisplayRecieveImage(data?.receiveImage)
    }
  }
  const handleUpdateImage = (data) =>{
    setUpdateImageModal(true)
    if(data)
    setUpdateCallanId(data?._id)
    dispatch(getChallanDetails(authtoken, data._id));

  }


  const onSubmit = (values) =>{
    let body ={
      receiveImage: values?.photo
    }
    dispatch(updateChallanImageData(body, history, authtoken, updateCallanId));
    setUpdateImageModal(false) // if submit then close button
  }
  const UpdateChallan = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: updateChallanSchema,
    onSubmit,
    // handleDelivery,
  });
  return (
    <React.Fragment>
      <InnerLayer
        title="Challan List"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Challan"}
        subTitle={"Challan List"}
        // onAddHandler={() => history.push("/challan/add-challan")}
        onAddHandler={() => history.push("/challan/-sample-add-challan")}
        // onAddHandler={
        //   role === "HUB"
        //     ? () => history.push("/challan/dh-add-challan")
        //     : () => history.push("/challan/-sample-add-challan")
        // }
        buttonText="Challan Create"
        isButton={role === "HUB" || role === "VIEWADMIN" || role==="DEMOADMIN" ? false : true}
      >
        <Row>
          <CardComponent className="user_table_card">
            <Row className="mb-3 table-header-padding">
              <div className="d-flex d-flex-blockCustom justify-content-end">
                <Col
                  className="me-2 custom-bottom-margin responsive-margin"
                  md={2}
                >
                  <div className="search-box d-flex justify-content-end justify-content-start">
                    <SearchInput
                      className="userSearchBar form-control search-width2"
                      onChange={(e) => {
                        const valueData = e.target.value;
                        const numberData = valueData?.split(" - ");
                        if(numberData?.length === 2) {
                          setValue(numberData?.[1])
                        } else {

                          setValue(e.target.value)
                        }
                      }}
                      placeholder="Search Challan ID"
                    />
                  </div>
                </Col>
                {role === "HUB" ? (
                  <span></span>
                ) : (
                  <Col
                    className="me-2 custom-bottom-margin responsive-margin"
                    md={3}
                  >
                    <Select
                      name="hub"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Distribution Hub"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={singleDistribution?.data?.Warehouse}
                      onChange={handleDistribution}
                    />
                  </Col>
                )}

                <Col
                  className="me-2 custom-bottom-margin responsive-margin"
                  md={2}
                >
                  <Input type="select" onChange={handleStatus}>
                    {/* <option defaultValue>Select...</option> */}
                    <option value="">All</option>
                    <option value="Pending">Pending</option>
                    <option value="Received">Received</option>
                    <option value="Confirm">Confirm</option>
                    <option value="damageIssue">Damage</option>
                    <option value="missingIssue">Missing</option>
                    <option value="bufferIssue">Buffer</option>
                  </Input>
                </Col>
                <Col className="page_margin responsive-margin" md={2}>
                  <Input type="select" onChange={handlePageRange}>
                    {/* <option defaultValue>Select...</option> */}
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </Input>
                </Col>
              </div>
            </Row>
            {/* table */}
            <Row>
              <div className="table-responsive">
                {challanDataLoading && value === "" ? (
                  <div className="text-center mt-5 pt-5 mb-5">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <Table className="table table-hover mb-0 table-centered">
                    <thead className="table-light">
                      <tr>
                        <th>No.</th>
                        <th>Delivery Source</th>
                        <th>Hub Name</th>
                        <th>Warehouse/Vendor</th>

                        <th>Challan No.</th>

                        <th>Challan Date</th>
                        <th>Image</th>
                        <th>Recieved Image</th>

                        <th>Status</th>
                        <th style={{ width: "8%" }}>Action</th>
                      </tr>
                    </thead>
                    {(challanData?.data?.challans || []).map((data, key) => {
                      return (
                        <>
                          <tbody>
                            <tr>
                              <td>{key + 1}</td>
                              <td>{data?.deliveryFrom}</td>
                              <td>{data?.distributionHubId?.name}</td>
                              {data?.deliveryFrom === "Warehouse" ? (
                                <td>{data?.centralWarehouseId?.name}</td>
                              ) : (
                                <td>{data?.vendorId?.name}</td>
                              )}
                              <td>CH - {data?.challanNo} {data?.isSettled===true ? "( Settled )": ""}</td>
                              <td>{data?.challanDate?.slice(0, 10)}</td>
                              <td> {data?.image ? <img src={`${process.env.REACT_APP_S3}${data?.image}`} className='attendance-user-image img-fluid attendance-img'  onClick={()=>handleImageData(data)}></img>: <span></span>}</td>
                              <td> {data?.receiveImage? <img src={`${process.env.REACT_APP_S3}${data?.receiveImage}`} className='attendance-user-image img-fluid attendance-img'  onClick={()=>handleRecieveimage(data)}></img>:<span></span>}</td>
                              <td>{data?.status}</td>
                              <td>
                                <div className="d-flex">
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipView}
                                  >
                                    <button
                                      className="btn btn-secondary btn-sm me-2"
                                      style={{ borderRadius: "10px" }}
                                      onClick={() => handleChallanDetails(data)}
                                    >
                                      <i className="bx bx-show mt-1"></i>
                                    </button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipPrint}
                                  >
                                    <button
                                      className="btn btn-outline-secondary btn-sm me-2"
                                      style={{ borderRadius: "10px" }}
                                      // onClick={() => handleChallanDetails(data)}
                                      onClick={() =>
                                        history.push(
                                          `/challan/sample-challan/${data?._id}`
                                        )
                                      }
                                    >
                                      <i className="bx bx-printer mt-1"></i>
                                    </button>
                                  </OverlayTrigger>

                            
                                  {role === "HUB" &&
                                  data?.status === "Pending" ? (
                                    <button
                                      className="btn btn-sm btn-info me-2"
                                      onClick={() =>
                                        history.push(
                                          `/distribution-edit-challan/${data?._id}`
                                        )
                                      }
                                    >
                                      Confirm
                                    </button>
                                  ) : data?.status === "Pending" &&
                                    role !== "VIEWADMIN"  &&  role !== "DEMOADMIN" ? (
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltipEdit}
                                    >
                                      {data?.deliveryFrom === "Vendor" ? (
                                        <button
                                          className="btn btn-outline-success btn-sm"
                                          style={{ borderRadius: "10px" }}
                                          // onClick={() =>
                                          //   handleUpdateChallan(data)
                                          // }
                                          onClick={() =>
                                            history.push(
                                              `/edit-challan/${data?._id}/${data?.vendorId?._id}`
                                            )
                                          }
                                        >
                                          <i className="bx bx-check-square mt-1"></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-outline-success btn-sm me-2"
                                          style={{ borderRadius: "10px" }}
                                          // onClick={() =>
                                          //   handleUpdateChallan(data)
                                          // }
                                          onClick={() =>
                                            history.push(
                                              `/edit-challan/${data?._id}/${data?.centralWarehouseId?._id}`
                                            )
                                          }
                                        >
                                          <i className="bx bx-check-square mt-1"></i>
                                        </button>
                                      )}
                                    </OverlayTrigger>
                                  ) : (
                                    <span></span>
                                  )}
                                  {
                                    role==="CENTRALWAREHOUSE" || role ==="SUPERADMIN" ?
                                    <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipUpdateImage}
                                  >
                                    <button
                                      className="btn btn-outline-info btn-sm me-2"
                                      style={{ borderRadius: "10px" }}
                                      onClick={() => handleUpdateImage(data)}
                                    >
                                      <i className="bx bx-image mt-1"></i>
                                    </button>
                                  </OverlayTrigger> :
                                  <span></span>
                                  }
                                   
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      );
                    })}
                  </Table>
                )}
                <Pagination
                  parentClass={`paginationContainer text-right py-3`}
                  reInitialize="reInitialize"
                  page={totalPageNumber}
                  setCurrentPage={setCurrentPage}
                />
                {/* modal for detail */}
                <Modal
                  size="xl"
                  show={detailShow}
                  onHide={() => setDetailShow(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header
                    closeButton
                    style={{ borderBottom: "none" }}
                  ></Modal.Header>
                  <Modal.Body style={{ padding: "3px 25px" }}>
                    <h4 className="text-center" style={{ color: "#0C1D37" }}>
                      Challan Information
                    </h4>
                    <Row className="mt-5 mb-3 ">
                      <Col md="6">
                        <h6 className="text-black">Challan No.</h6>
                        <span className="">
                          CH - {challanDetails?.data?.challan?.challanNo}
                        </span>
                      </Col>
                      <Col md="6">
                        <h6 className="text-black">Challan Date</h6>
                        <span>
                          {challanDetails?.data?.challan?.challanDate.slice(
                            0,
                            10
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <h6 className="text-black">Distribution Hub</h6>
                        <span>
                          {
                            challanDetails?.data?.challan?.distributionHubId
                              ?.name
                          }
                        </span>
                      </Col>
                      <Col md="6">
                        <h6 className="text-black">Date of Receipt</h6>
                        <span>
                          {challanDetails?.data?.challan?.challanReceivedDate}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <h6 className="text-black">Delivery Source</h6>
                        <span>
                          {challanDetails?.data?.challan?.deliveryFrom}
                        </span>
                      </Col>
                      {challanDetails?.data?.challan?.deliveryFrom ===
                      "Warehouse" ? (
                        <Col md="6">
                          <h6 className="text-black">Warehouse Name</h6>
                          <span>
                            {
                              challanDetails?.data?.challan?.centralWarehouseId
                                ?.name
                            }
                          </span>
                        </Col>
                      ) : (
                        <Col md="6">
                          <h6 className="text-black">Vendor Name</h6>
                          <span>
                            {challanDetails?.data?.challan?.vendorId?.name}
                          </span>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {challanDetails?.data?.challan?.chalancreatedBy ? (
                        <Col md="6" className="mb-3">
                          <h6 className="text-black">Challan Issuer name</h6>
                          <span>
                            {challanDetails?.data?.challan?.chalancreatedBy}
                          </span>
                        </Col>
                      ) : null}
                      {challanDetails?.data?.challan?.chalanCreatorPhone ? (
                        <Col md="6" className="mb-3">
                          <h6 className="text-black">Challan Issuer phone</h6>
                          <span>
                            {challanDetails?.data?.challan?.chalanCreatorPhone}
                          </span>
                        </Col>
                      ) : null}
                      {challanDetails?.data?.challan?.chalanGiverName ? (
                        <Col md="6" className="mb-3">
                          <h6 className="text-black">BP Name</h6>
                          <span>
                            {challanDetails?.data?.challan?.chalanGiverName}
                          </span>
                        </Col>
                      ) : null}
                      {challanDetails?.data?.challan?.chalanGiverPhone ? (
                        <Col md="6" className="mb-3">
                          <h6 className="text-black">BP Phone Number</h6>
                          <span>
                            {challanDetails?.data?.challan?.chalanGiverPhone}
                          </span>
                        </Col>
                      ) : null}
                      {challanDetails?.data?.challan?.warhouseAddress ? (
                        <Col md="6" className="mb-3">
                          <h6 className="text-black">Warehouse Address</h6>
                          <span>
                            {challanDetails?.data?.challan?.warhouseAddress}
                          </span>
                        </Col>
                      ) : null}
                      {challanDetails?.data?.challan?.chalanReceiverName ? (
                        <Col md="6" className="mb-3">
                          <h6 className="text-black">Hub Manager Name</h6>
                          <span>
                            {challanDetails?.data?.challan?.chalanReceiverName}
                          </span>
                        </Col>
                      ) : null}
                      {challanDetails?.data?.challan
                        ?.chalanReceiverPhoneNumber ? (
                        <Col md="6" className="mb-3">
                          <h6 className="text-black">
                            Hub Manager Phone Number
                          </h6>
                          <span>
                            {
                              challanDetails?.data?.challan
                                ?.chalanReceiverPhoneNumber
                            }
                          </span>
                        </Col>
                      ) : null}
                      <Col md="6" className="mb-3">
                        <h6 className="text-black">Note</h6>
                        <span>{challanDetails?.data?.challan?.note}</span>
                      </Col>
                      {/* {challanDetails?.data?.challan.status==="Missing" ? (
                        <Col md="6" className="mb-3">
                          <h6 className="text-black">Settlement Status</h6>
                          {
                            challanDetails?.data?.challan?.settlement === "YES" ?
                            <span>Already done</span>
                            :
                            <span>Not done </span>
                          }
                          
                        </Col>
                      ) : null} */}
                    </Row>
                    <h5 style={{ color: "#1C1D1E" }} className="mt-4 mb-3">
                      Challan Details
                    </h5>
                    <div className="table-responsive">
                      <Table className="table table-hover mb-0 table-centered  mb-5">
                        <thead className="table-light">
                          <tr>
                            <th>No.</th>
                            {/* <th>Category</th>
                            <th>Brand</th> */}
                            <th>Product</th>
                            <th>QTY</th>

                            <th>Received QTY</th>
                            <th>Missing QTY</th>
                            <th>Buffer QTY</th>
                            <th>Damage QTY</th>
                            <th>Claim QTY</th>
                            <th>Settle QTY</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {(damageChallan || []).map((data, key) => {
                          return (
                            <>
                              <tbody>
                                <tr>
                                  <td>{key + 1}</td>
                                  {/* <td>{data?.itemId?.category?.name}</td>
                                  <td>{data?.itemId?.brand?.name}</td> */}
                                  <td>{data?.itemId?.name}</td>
                                  <td>{data?.quantity}</td>

                                  <td>{data?.actualReceive}</td>
                                  {
                                    data?.quantity > data?.actualReceive ?
                                    <td>{data?.quantity - data?.actualReceive}</td>
                                    :
                                    <td>0</td>
                                  }
                                  {
                                    data?.quantity < data?.actualReceive ?
                                    <td>{data?.actualReceive - data?.quantity}</td>
                                    :
                                    <td>0</td>
                                  }
                                  
                                 
                                  {/* {isNaN(
                                      data?.quantity - data?.receivedQty
                                    ) ? (
                                      <td>0</td>
                                    ) : (
                                      <td>
                                        {data?.quantity - data?.receivedQty}
                                      </td>
                                    )} */}
                                  <td>{data?.damageQty}</td>
                                  <td>{data?.claimQty}</td>
                                  {data?.claimStatus  ===false && role!=="HUB"? (
                                    <td>
                                      <Input
                                        type="text"
                                        value={data?.challanDamageData}
                                        style={{
                                          width: "80px",
                                          height: "30px",
                                        }}
                                        onChange={(e) =>
                                          handleSubmitQtyEdit(e, key, data)
                                        }
                                      ></Input>
                                    </td>
                                  ) : (
                                    <span></span>
                                  )}
                                  {data?.claimStatus  ===false && role!=="HUB" ? (
                                    <td>
                                      <button
                                        className="btn btn-secondary btn-sm me-2"
                                        onClick={() => handleShow(data)}
                                      >
                                        Claim
                                      </button>
                                    </td>
                                  ) : (
                                    <span></span>
                                  )}
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                      </Table>
                    </div>
             
                    {
                      challanDetails?.data?.challan?.isSettled === true ?
                      <div>
                            <h5 style={{ color: "#1C1D1E" }} className="mt-4 mb-3">
                     Settlement product
                    </h5>
                    <div className="table-responsive">
                      <Table className="table table-hover mb-0 table-centered  mb-5">
                        <thead className="table-light">
                          <tr>
                            <th>No.</th>
                            
                            <th>Product</th>
                            

                            
                            <th>Settle QTY</th>
                          
                          </tr>
                        </thead>
                        {(challanDetails?.data?.challan?.setteledId?.settledProduct|| []).map((data, key) => {
                          return (
                            <>
                              <tbody>
                                <tr>
                                  <td>{key + 1}</td>
                                  {/* <td>{data?.itemId?.category?.name}</td>
                                  <td>{data?.itemId?.brand?.name}</td> */}
                                  <td>{data?.productId?.name}</td>
                                  <td>{data?.setteleQty}</td>

                          
                              
                              
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                      </Table>
                    </div>
                      </div>
                      : ""
                    }
                 
                  </Modal.Body>
                </Modal>

                

               
              </div>
              {/* table end */}
            </Row>
          </CardComponent>
          <button
            className="btn btn-info w-25 m-auto fw-bold excel_button"
            disabled={loading}
            onClick={handleExcel}
          >
            {loading ? "Downloading..." : "Download Excel"}
          </button>
        </Row>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body className="text-center">
            <div className="mb-4">
              <img src={Question} alt="Image" style={{ width: "76px" }} />
            </div>
            <h5>Are you want to claim the product?</h5>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "none", justifyContent: "center" }}
            className="mb-4"
          >
            <Button
              variant="outline-secondary"
              onClick={handleClose}
              style={{ width: "112px" }}
            >
              Cancel
            </Button>
            <Button
              className="btn button"
              onClick={handleClaimChallan}
              disabled={claimChallanLoading}
            >
              {claimChallanLoading ? "Submitting..." : " Yes, I’m Sure"}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
              size="lg"
              show={imageShow}
              onHide={() => setImageShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header
                closeButton
                style={{ borderBottom: "none" }}
              ></Modal.Header>
              <Modal.Body style={{ padding: "3px 25px" }}>
               

                <img src={`${process.env.REACT_APP_S3}${displayImage}`} className='h-100 w-100'></img>
           
              </Modal.Body>
            </Modal>
            <Modal
              size="lg"
              show={recieveImageShow}
              onHide={() => setRecieveImageShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header
                closeButton
                style={{ borderBottom: "none" }}
              ></Modal.Header>
              <Modal.Body style={{ padding: "3px 25px" }}>
               

                <img src={`${process.env.REACT_APP_S3}${displayRecieveImage}`} className='h-100 w-100'></img>
           
              </Modal.Body>
            </Modal>
            <Modal
                  size="xl"
                  show={updateImageModal}
                  onHide={() => setUpdateImageModal(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header
                    closeButton
                    style={{ borderBottom: "none" }}
                  ></Modal.Header>
                  <Modal.Body style={{ padding: "3px 25px" }}>
                    <h4 className="text-center" style={{ color: "#0C1D37" }}>
                      Update Image
                    </h4>
                    <Row className="mt-5 mb-3 ">
                      <Col md="6">
                        <h6 className="text-black">Challan No.</h6>
                        <span className="">
                          CH - {challanDetails?.data?.challan?.challanNo}
                        </span>
                      </Col>
                      <Col md="6">
                        <h6 className="text-black">Challan Date</h6>
                        <span>
                          {challanDetails?.data?.challan?.challanDate.slice(
                            0,
                            10
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <h6 className="text-black">Distribution Hub</h6>
                        <span>
                          {
                            challanDetails?.data?.challan?.distributionHubId
                              ?.name
                          }
                        </span>
                      </Col>
                      <Col md="6">
                        <h6 className="text-black">Date of Receipt</h6>
                        <span>
                          {challanDetails?.data?.challan?.challanReceivedDate}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <h6 className="text-black">Delivery Source</h6>
                        <span>
                          {challanDetails?.data?.challan?.deliveryFrom}
                        </span>
                      </Col>
                      {challanDetails?.data?.challan?.deliveryFrom ===
                      "Warehouse" ? (
                        <Col md="6">
                          <h6 className="text-black">Warehouse Name</h6>
                          <span>
                            {
                              challanDetails?.data?.challan?.centralWarehouseId
                                ?.name
                            }
                          </span>
                        </Col>
                      ) : (
                        <Col md="6">
                          <h6 className="text-black">Vendor Name</h6>
                          <span>
                            {challanDetails?.data?.challan?.vendorId?.name}
                          </span>
                        </Col>
                      )}
                    </Row>
                    <Form
                  className="needs-validation col-md-12"
                  onSubmit={UpdateChallan.handleSubmit}
                >
                  <div>
                    <h5>Update Image</h5>
                    <Row>
                    <Col md="6 mb-5 mt-3">
                    <FileUpload
                        name="photo"
                        label={"Upload Image"}
                        type="file"
                        isRequired
                        validationType={UpdateChallan}
                      />
                    </Col>
                  
                      <div className="mx-auto">
                      <button className="btn btn-primary mb-3 " type="submit"  disabled={updateChallanImageLoading}>
                      {updateChallanImageLoading ? "Updating.." : "Update Image"}
                      </button>
                      </div>
                  
                    </Row>
               
                  </div>
                  </Form>
                   
                  </Modal.Body>
                </Modal>
      </InnerLayer>
    </React.Fragment>
  );
};
Challan.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
export default withRouter(Challan);
