import {
  ADD_CHALLAN,
  ADD_CHALLAN_FAIL,
  ADD_CHALLAN_SUCCESS,
  CHALLAN_APPROVED,
  CHALLAN_APPROVED_FAIL,
  CHALLAN_APPROVED_SUCCESS,
  CHALLAN_DELETE,
  CHALLAN_DELETE_FAIL,
  CHALLAN_DELETE_SUCCESS,
  GET_CHALLAN,
  GET_CHALLAN_DETAILS,
  GET_CHALLAN_DETAILS_FAIL,
  GET_CHALLAN_DETAILS_SUCCESS,
  GET_CHALLAN_FAIL,
  GET_CHALLAN_STOCK_LIST,
  GET_CHALLAN_STOCK_LIST_FAIL,
  GET_CHALLAN_STOCK_LIST_SUCCESS,
  GET_CHALLAN_SUCCESS,
  STORE_CHALLAN_DATA,
  UPDATE_CHALLAN_DETAILS,
  UPDATE_CHALLAN_STOCK,
  RETURN_CHALLAN,
  RETURN_CHALLAN_SUCCESS,
  RETURN_CHALLAN_FAIL,
  ADD_DH_CHALLAN,
  ADD_DH_CHALLAN_SUCCESS,
  ADD_DH_CHALLAN_FAIL,
  GET_DAMAGE_CHALLAN,
  GET_DAMAGE_CHALLAN_SUCCESS,
  GET_DAMAGE_CHALLAN_FAIL,
  GET_MISSING_CHALLAN,
  GET_MISSING_CHALLAN_SUCCESS,
  GET_MISSING_CHALLAN_FAIL,
  GET_BUFFER_CHALLAN,
  GET_BUFFER_CHALLAN_SUCCESS,
  GET_BUFFER_CHALLAN_FAIL,
  GET_SETTLEMENT_CHALLAN,
  GET_SETTLEMENT_CHALLAN_SUCCESS,
  GET_SETTLEMENT_CHALLAN_FAIL,
  GET_ISSUE_LIST,
  GET_ISSUE_LIST_SUCCESS,
  GET_ISSUE_LIST_FAIL,
  CLAIM_CHALLAN,
  CLAIM_CHALLAN_SUCCESS,
  CLAIM_CHALLAN_FAIL,
  UPDATE_CHALLAN_IMAGE,
  UPDATE_CHALLAN_IMAGE_SUCCESS,
  UPDATE_CHALLAN_IMAGE_FAIL
  
  
} from './actionTypes';

export const getChallan = (
  authtoken,
  pageRange,
  currentPage,
  distribution,
  status,
  value,
  settlement,
  damageStatus,
  missingStatus,
  bufferStatus
) => ({
  type: GET_CHALLAN,
  payload: { authtoken, pageRange, currentPage, distribution, status, value, settlement, damageStatus, missingStatus, bufferStatus },
});

export const getChallanSuccess = (data) => ({
  type: GET_CHALLAN_SUCCESS,
  payload: { data },
});
export const getChallanFail = (error) => ({
  type: GET_CHALLAN_FAIL,
  payload: error,
});
export const getChallanDetails = (authtoken, id) => ({
  type: GET_CHALLAN_DETAILS,
  payload: { authtoken, id },
});

export const getChallanDetailsSuccess = (data) => ({
  type: GET_CHALLAN_DETAILS_SUCCESS,
  payload: { data },
});
export const getChallanDetailsFail = (error) => ({
  type: GET_CHALLAN_DETAILS_FAIL,
  payload: error,
});

//add
export const addChallanData = (data, history, authtoken) => ({
  type: ADD_CHALLAN,
  payload: { data, history, authtoken },
});

export const addChallanDataSuccess = (data) => ({
  type: ADD_CHALLAN_SUCCESS,
  payload: data,
});

export const addChallanDataFail = (error) => ({
  type: ADD_CHALLAN_FAIL,
  payload: error,
});

export const addChallanConfirmation = (data, history, authtoken, id) => ({
  type: CHALLAN_APPROVED,
  payload: { data, history, authtoken, id },
});

export const addChallancConfirmationSuccess = (data) => ({
  type: CHALLAN_APPROVED_SUCCESS,
  payload: data,
});

export const addChallancConfirmationFail = (error) => ({
  type: CHALLAN_APPROVED_FAIL,
  payload: error,
});

export const getChallanStock = (authtoken, warehouseId) => ({
  type: GET_CHALLAN_STOCK_LIST,
  payload: { authtoken, warehouseId },
});

export const getChallanStockSuccess = (data) => ({
  type: GET_CHALLAN_STOCK_LIST_SUCCESS,
  payload: { data },
});
export const getChallanStockFail = (error) => ({
  type: GET_CHALLAN_STOCK_LIST_FAIL,
  payload: error,
});

export const storeChallanData = (name, data) => ({
  type: STORE_CHALLAN_DATA,
  payload: { name, data },
});
export const updateChallanStock = (data) => ({
  type: UPDATE_CHALLAN_STOCK,
  payload: { data },
});

export const updateChallanDetails = (data, options) => ({
  type: UPDATE_CHALLAN_DETAILS,
  payload: { data, options },
});

export const deleteChallan = (data, options) => ({
  type: CHALLAN_DELETE,
  payload: { data, options },
});

export const deleteChallanSuccess = (data) => ({
  type: CHALLAN_DELETE_SUCCESS,
  payload: data,
});

export const deleteChallanFail = (error) => ({
  type: CHALLAN_DELETE_FAIL,
  payload: error,
});
export const returnChallan = (data, history, authtoken) => ({
  type: RETURN_CHALLAN,
  payload: { data, history, authtoken },
});

export const returnChallanSuccess = (data) => ({
  type: RETURN_CHALLAN_SUCCESS,
  payload: data,
});

export const returnChallanFail = (error) => ({
  type: RETURN_CHALLAN_FAIL,
  payload: error,
});

export const addDhChallanData = (data, history, authtoken) => ({
  type: ADD_DH_CHALLAN,
  payload: { data, history, authtoken },
});

export const addDhChallanDataSuccess = (data) => ({
  type: ADD_DH_CHALLAN_SUCCESS,
  payload: data,
});

export const addDhChallanDataFail = (error) => ({
  type: ADD_DH_CHALLAN_FAIL,
  payload: error,
});
export const getDamageChallan = (
  authtoken,
  pageRange,
  currentPage,
  distribution,
  status,
  value
) => ({
  type: GET_DAMAGE_CHALLAN,
  payload: { authtoken, pageRange, currentPage, distribution, status, value },
});

export const getDamageChallanSuccess = (data) => ({
  type: GET_DAMAGE_CHALLAN_SUCCESS,
  payload: { data },
});
export const getDamageChallanFail = (error) => ({
  type: GET_DAMAGE_CHALLAN_FAIL,
  payload: error,
});
export const getMissingChallan = (authtoken, warehouseId) => ({
  type: GET_MISSING_CHALLAN,
  payload: { authtoken, warehouseId },
});

export const getMissingChallanSuccess = (data) => ({
  type: GET_MISSING_CHALLAN_SUCCESS,
  payload: { data },
});
export const getMissingChallanFail = (error) => ({
  type: GET_MISSING_CHALLAN_FAIL,
  payload: error,
});
export const getBufferChallan = (authtoken, warehouseId) => ({
  type: GET_BUFFER_CHALLAN,
  payload: { authtoken, warehouseId },
});

export const getBufferChallanSuccess = (data) => ({
  type: GET_BUFFER_CHALLAN_SUCCESS,
  payload: { data },
});
export const getBufferChallanFail = (error) => ({
  type: GET_BUFFER_CHALLAN_FAIL,
  payload: error,
});

export const getSettlementChallan = (authtoken, warehouseId) => ({
  type: GET_SETTLEMENT_CHALLAN,
  payload: { authtoken, warehouseId },
});

export const getSettlementChallanSuccess = (data) => ({
  type: GET_SETTLEMENT_CHALLAN_SUCCESS,
  payload: { data },
});
export const getSettlementChallanFail = (error) => ({
  type: GET_SETTLEMENT_CHALLAN_FAIL,
  payload: error,
});

export const getIssueList = (authtoken, warehouseId) => ({
  type: GET_ISSUE_LIST,
  payload: { authtoken, warehouseId },
});

export const getIssueListSuccess = (data) => ({
  type: GET_ISSUE_LIST_SUCCESS,
  payload: { data },
});
export const getIssueListFail = (error) => ({
  type: GET_ISSUE_LIST_FAIL,
  payload: error,
});


export const claimChallanData = (data, history, authtoken) => ({
  type: CLAIM_CHALLAN,
  payload: { data, history, authtoken },
});

export const claimChallanDataSuccess = (data) => ({
  type: CLAIM_CHALLAN_SUCCESS,
  payload: data,
});

export const claimChallanDataFail = (error) => ({
  type: CLAIM_CHALLAN_FAIL,
  payload: error,
});
export const updateChallanImageData = (data, history, authtoken, id) => ({
  type: UPDATE_CHALLAN_IMAGE,
  payload: { data, history, authtoken, id },
});

export const updateChallanImageDataSuccess = (data) => ({
  type: UPDATE_CHALLAN_IMAGE_SUCCESS,
  payload: data,
});

export const updateChallanImageDataFail = (error) => ({
  type: UPDATE_CHALLAN_IMAGE_FAIL,
  payload: error,
});

