import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Col, Form, Modal, ModalBody, Row, Table, Label, Spinner } from "reactstrap";
import { post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/toaster";
import useExcelReader from "../../Hooks/useExcelReader";
import { addDistributionHub, getSingleDistributionHub } from "../../store/Distribution Hub/actions";
import { getArea, getRegion, getTerritory } from "../../store/GEO/actions";
import AddFileUpload from "../Common/AddFileUpload";
import CustomInput from "../Common/CustomInput";
import AddCardComponent from "../Layout/AddCardComponent";
import InnerLayer from "../Layout/InnerLayer";
import { addHubSchema } from "../Schemas/AddHub.schema";
import Select from "react-select";
const breadcrumbItems = [
  {
    title: "Hub",
    link: "#",
  },
  {
    title: "Distribution Hub List",
    link: "hub",
  },
  {
    title: "Create Distribution Hub",
    link: "#",
  },
];
const breadcrumbItems2 = [
  {
    title: "Hub",
    link: "#",
  },
  {
    title: "Distribution Hub List",
    link: "hub",
  },
  {
    title: "Update Distribution Hub",
    link: "#",
  },
];
const initialValues = {
  name: "",
  address: "",
  uniqueId: "",
  WarehouseType: "",
  region: "",
  area: "",
  territory: "",
};

const HUbType = [
  {
    name: "Central WareHouse",
    _id: 1,
    value: "Central",
  },
  {
    name: "Distribution Hub",
    _id: 2,
    value: "Hub",
  },
];

const AddHub = ({edit}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams()
  const [selectedFiles, setSelectedFiles] = useState({});
  const [singleHubData, setSingleHubData] = useState(null)
  const [areaByRegion, setAreaByRegion] = useState('')
  const [territoryByArea, setTerritoyrByArea] = useState('')
  const { items, readExcel } = useExcelReader();
  console.log(`🦄 ~ file: Dashboard.js ~ line 21 ~ Dashboard ~ items`, items);
  const [results, setResults] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [open, setOpen] = useState(false);
  const [adding, setAdding] = useState(false);
  const toggle = () => setOpen(!open);

  const { regionData, loading, authtoken, areaData, territoryData, singleDistributionHub, singleDistributionHubLoading,hubLoading } =
    useSelector((state) => ({
      regionData: state.GeoReducer.regionData,
      areaData: state.GeoReducer.areaData,
      territoryData: state.GeoReducer.territoryData,
      singleDistributionHub: state.DistributionReducer.singleDistributionHub,
      singleDistributionHubLoading: state.DistributionReducer.singleDistributionHubLoading,
      hubLoading: state.DistributionReducer.hubLoading,
      authtoken: state.Login.token,
    }));


  const onSubmit = (values) => {
    let obj = {};
    obj.name = values.name;
    obj.area = values.area;
    obj.region = values.region;
    obj.territory = values.territory;
    obj.address = values.address;
    obj.uniqueId = values.uniqueId;
    obj.WarehouseType = values.WarehouseType;

 
    if(edit){
      dispatch(addDistributionHub(obj, history, authtoken, id));
    }
    else{
      dispatch(addDistributionHub(obj, history, authtoken));
    }
    
  };

  console.log("areaData", areaData);
  useEffect(() => {
    dispatch(getRegion(authtoken, 0, 0, ""));
  }, []);
  useEffect(() => {
    if(edit){
      dispatch(getArea(authtoken, 0, 0, "All", ""));
    }
    
  }, []);
  // useEffect(() => {
  //   dispatch(getArea(authtoken, 0, 0, "All", ""));
  // }, []);
  useEffect(() => {
    if(edit){
      dispatch(getTerritory(authtoken, 0, 0, "All", ""));
    }
   
  }, []);
  useEffect(() => {

    if (edit) {
      dispatch(getSingleDistributionHub(authtoken, id))
    }

  }, [])
  useEffect(() => {
    if (edit && singleDistributionHubLoading === false) {
      const data = { ...singleHubData }
      data.name = singleDistributionHub?.data?.Warehouse?.name
      data.uniqueId = singleDistributionHub?.data?.Warehouse?.uniqueId
      data.WarehouseType = singleDistributionHub?.data?.Warehouse?.WarehouseType
      data.region = singleDistributionHub?.data?.Warehouse?.region
      data.area = singleDistributionHub?.data?.Warehouse?.area
      data.territory = singleDistributionHub?.data?.Warehouse?.territory
      data.address = singleDistributionHub?.data?.Warehouse?.address

  


      console.log(data);
      setSingleHubData(data)
    }
  }, [singleDistributionHub])
  const handleSubmit = async () => {
    setAdding(true);
    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      const data = {
        name: element?.name,
        address: element.address,
        uniqueId: element.uniqueId,
        WarehouseType: element?.WarehouseType,
        region: element.region,
        area: element.area,
        territory: element.territory,
      };
      await post('/warehouse/warehouse-excel', data).then(response=>{
        console.log('ddddd');
        setResults((prev) => [...prev, response]);
      }).catch((error)=>{
        console.log('error',error);
        setResults((prev) => [...prev, { status: 'failed' }]);
      }
      );
      if (i === items.length - 1) {
        setIsDone(true);
        setAdding(false);
        toaster("success", "All data upload successful");
      }
    }
  };
  const tableHead = items?.[0] || {};
  const AddHub = useFormik({
    enableReinitialize: true,
    initialValues:singleHubData || initialValues,
    validationSchema: addHubSchema,
    onSubmit,
  });

  const handleRegion = (data) =>{
    setAreaByRegion(data?._id);
    if (!!data) {
      dispatch(getArea(authtoken, 0, 0, data?._id, ""));
    } else {
      dispatch(getArea({}));
    }
  }
  const handleArea = (data) =>{
    // setAreaByRegion(data?._id);
    if (!!data) {
      dispatch(getTerritory(authtoken, 0, 0, data?._id, ""));
    } else {
      dispatch(getTerritory({}));
    }
  }

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={edit ?"Update Hub": "Create Hub"}
        breadcrumbItems={edit?breadcrumbItems2 : breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="9" className="mx-auto">
              {/* add User */}
              <AddCardComponent className="">
                <Form
                  className="needs-validation col-md-12"
                  onSubmit={AddHub.handleSubmit}
                >
                  <Row>
                    <Col md="6 mb-2">
                      <CustomInput
                        name={"name"}
                        type={"text"}
                        label={"Hub Name"}
                        placeholder={"Type name"}
                        validationType={AddHub}
                      />
                    </Col>
                    <Col md="6 mb-2">
                      <CustomInput
                        name={"uniqueId"}
                        type={"text"}
                        label={"Hub ID"}
                        placeholder={"Type Hub ID"}
                        validationType={AddHub}
                      />
                    </Col>

                    <Col md="6 mb-3">
                      <CustomInput
                        name={"WarehouseType"}
                        type={"select"}
                        label={"Select Hub Type"}
                        validationType={AddHub}
                      >
                        <option defaultValue>Select Type..</option>
                        {HUbType?.length > 0 ? (
                          HUbType?.map((data, idx) => (
                            <option value={data.value} key={idx}>
                              {data.name}
                            </option>
                          ))
                        ) : (
                          <span></span>
                        )}
                      </CustomInput>
                    </Col>
                    <Col md="6 mb-3">
                    <Label htmlFor="formrow-firstname-Input">
                        Select Region
                      </Label>
                      <Select
                        name="region"
                        className="mb-2"
                        classNamePrefix="select2-selection"
                        placeholder="Select Region"
                        cacheOptions
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.value}
                        isClearable
                        options={ regionData?.region}
                        value = {regionData?.region?.find(data=> data?._id == AddHub.values.region)}
                        onChange={(e) => {
                        
                          AddHub.setFieldValue("region", e?._id || "");
                          handleRegion(e)
                          
                        }
                        
                      }
                      />
                      {AddHub.errors.region ? (
                        <p className="text-danger">
                          {AddHub.errors.region}
                        </p>
                      ) : null}
                      {/* <CustomInput
                        name={"region"}
                        type={"select"}
                        label={"Select region"}
                        // onChange={handleRegion}
                        validationType={AddHub}
                      >
                        <option defaultValue>Select region...</option>
                        {regionData?.length > 0 ? (
                          regionData?.region?.map((data, idx) => (
                            <option value={data._id} key={idx}
                           
                            >
                              {data.name}
                            </option>
                          ))
                        ) : (
                          <span></span>
                        )}
                      </CustomInput> */}
                    </Col>
                    <Col md="6 mb-3">
                    <Label htmlFor="formrow-firstname-Input">
                        Select Area
                      </Label>
                      <Select
                        name="area"
                        className="mb-2"
                        classNamePrefix="select2-selection"
                        placeholder="Select Area"
                        cacheOptions
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.value}
                      
                        isClearable
                        options={areaData?.data?.area}
                        value = {areaData?.data?.area?.find(data=>data._id === AddHub.values.area)}
                        onChange={(e) => {
                          AddHub.setFieldValue("area", e?._id || "");
                          handleArea(e)
                        }}
                      />
                      {AddHub.errors.area ? (
                        <p className="text-danger">
                          {AddHub.errors.area}
                        </p>
                      ) : null}

                      {/* <CustomInput
                        name={"area"}
                        type={"select"}
                        label={"Select Area"}
                        validationType={AddHub}
                      >
                        <option defaultValue>Select Area...</option>
                        {areaData?.data?.length > 0 ? (
                          areaData?.data?.area?.map((data, idx) => (
                            <option value={data._id} key={idx}>
                              {data.name}
                            </option>
                          ))
                        ) : (
                          <span></span>
                        )}
                      </CustomInput> */}
                    </Col>
                    <Col md="6 mb-3">
                    <Label htmlFor="formrow-firstname-Input">
                        Select Territory
                      </Label>
                      <Select
                        name="territory"
                        className="mb-2"
                        classNamePrefix="select2-selection"
                        placeholder="Select Territory"
                        cacheOptions
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.value}
                        isClearable
                        options={territoryData?.territory}
                        value = {territoryData?.territory?.find(data=>data._id === AddHub.values.territory)}
                        onChange={(e) => {
                          console.log("e", e);
                          AddHub.setFieldValue("territory", e?._id || "");
                        }}
                      />
                      {AddHub.errors.territory ? (
                        <p className="text-danger">
                          {AddHub.errors.territory}
                        </p>
                      ) : null}
                      {/* <CustomInput
                        name={"territory"}
                        type={"select"}
                        label={"Select Territory"}
                        validationType={AddHub}
                      >
                        <option defaultValue>Select Territory...</option>
                        {territoryData?.length > 0 ? (
                          territoryData?.territory?.map((data, idx) => (
                            <option value={data._id} key={idx}>
                              {data.name}
                            </option>
                          ))
                        ) : (
                          <span></span>
                        )}
                      </CustomInput> */}
                    </Col>
                    <Col md="6 mb-2">
                      <CustomInput
                        name={"address"}
                        type={"textarea"}
                        label={"Address"}
                        placeholder={"Type Address"}
                        validationType={AddHub}
                        style={{ height: "80px" }}
                      />
                    </Col>
                  </Row>
                  {hubLoading ? (
                      <div className="d-flex justify-content-end mt-3">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    ) : (
                  <div className="d-flex justify-content-end mt-3">
                    <input
                      type="submit"
                      value={edit?'Update Hub': "Add Hub" }
                      className="btn button"
                    />
                  </div>
                    )}
                </Form>
              </AddCardComponent>
              <AddFileUpload
                setSelectedFiles={setSelectedFiles}
                acceptFile={
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
                handleFile={(file) => {
                  readExcel(file);
                }}
              />
              <Modal isOpen={open} toggle={toggle} size="xl">
                <ModalBody>
                  <div className="table-responsive">
                    <Table className="table table-hover mb-0 table-centered table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th>No.</th>
                          {Object.keys(tableHead)?.map((item, idx) => (
                            <th key={idx}>{item}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {items?.map((item, idx) => (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item?.name}</td>
                            <td>{item?.address}</td>
                            <td>{item?.uniqueId}</td>
                            <td>{item?.WarehouseType}</td>
                            <td>{item?.region}</td>
                            <td>{item?.area}</td>
                            <td>{item?.territory}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </ModalBody>
              </Modal>
              {items?.length > 0 ? (
                <div>
                  <button className="btn btn-primary me-2" onClick={toggle}>
                    Preview
                  </button>
                  <button
                    disabled={adding}
                    className="btn btn-primary "
                    onClick={handleSubmit}
                  >
                    {adding ? "Submitting..." : "Submit"}
                  </button>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  );
};

export default AddHub;
