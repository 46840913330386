export const GET_DISBURSE = 'GET_DISBURSE';
export const GET_DISBURSE_SUCCESS = 'GET_DISBURSE_SUCCESS';
export const GET_DISBURSE_FAIL = 'GET_DISBURSE_FAIL';

export const GET_PARTNER = 'GET_PARTNER';
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
export const GET_PARTNER_FAIL = 'GET_PARTNER_FAIL';

export const ADD_DISBURSE = 'ADD_DISBURSE';
export const ADD_DISBURSE_SUCCESS = 'ADD_DISBURSE_SUCCESS';
export const ADD_DISBURSE_FAIL = 'ADD_DISBURSE_FAIL';

export const GET_DISBURSE_REPORT = "GET_DISBURSE_REPORT";
export const GET_DISBURSE_REPORT_SUCCESS = "GET_DISBURSE_REPORT_SUCCESS";
export const GET_DISBURSE_REPORT_FAIL = "GET_DISBURSE_REPORT_FAIL";



export const STORE_DISBURSE_DATA = 'STORE_DISBURSE_DATA';

export const GET_DISBURSE_TODAY = 'GET_DISBURSE_TODAY';
export const GET_DISBURSE_TODAY_SUCCESS = 'GET_DISBURSE_TODAY_SUCCESS';
export const GET_DISBURSE_TODAY_FAIL = 'GET_DISBURSE_TODAY_FAIL';

export const GET_DISBURSE_BP = 'GET_DISBURSE_BP';
export const GET_DISBURSE_BP_SUCCESS = 'GET_DISBURSE_BP_SUCCESS';
export const GET_DISBURSE_BP_FAIL = 'GET_DISBURSE_BP_FAIL';

export const GET_SINGLE_DISBURSE = 'GET_SINGLE_DISBURSE';
export const GET_SINGLE_DISBURSE_SUCCESS = 'GET_SINGLE_DISBURSE_SUCCESS';
export const GET_SINGLE_DISBURSE_FAIL = 'GET_SINGLE_DISBURSE_FAIL';

export const POST_DISBURSE_DAILY = 'POST_DISBURSE_DAILY';
export const POST_DISBURSE_DAILY_SUCCESS = 'POST_DISBURSE_DAILY_SUCCESS';
export const POST_DISBURSE_DAILY_FAIL = 'POST_DISBURSE_DAILY_FAIL';


export const GET_HUB_BP = 'GET_HUB_BP';
export const GET_HUB_BP_SUCCESS = 'GET_HUB_BP_SUCCESS';
export const GET_HUB_BP_FAIL = 'GET_HUB_BP_FAIL';

export const GET_DISBURSE_HUB_STOCK = 'GET_DISBURSE_HUB_STOCK';
export const GET_DISBURSE_HUB_STOCK_SUCCESS = 'GET_DISBURSE_HUB_STOCK_SUCCESS';
export const GET_DISBURSE_HUB_STOCK_FAIL = 'GET_DISBURSE_HUB_STOCK_FAIL';