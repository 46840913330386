import {
  ADD_DISTRIBUTION_HUB,
  ADD_DISTRIBUTION_HUB_FAIL,
  ADD_DISTRIBUTION_HUB_SUCCESS,
  GET_DISTRIBUTION_HUB,
  GET_DISTRIBUTION_HUB_FAIL,
  GET_DISTRIBUTION_HUB_SUCCESS,
  GET_SINGLE_DISTRIBUTION_HUB,
  GET_SINGLE_DISTRIBUTION_HUB_SUCCESS,
  GET_SINGLE_DISTRIBUTION_HUB_FAIL,
  GET_INV_OPTION,
  GET_INV_OPTION_FAIL,
  GET_INV_OPTION_SUCCESS,
  GET_SINGLE_DISTRIBUTION,
  GET_SINGLE_DISTRIBUTION_FAIL,
  GET_SINGLE_DISTRIBUTION_SUCCESS,
  STORE_DISTRIBUTION_HUB_DATA,
  STORE_HUB_DATA
} from './actionTypes';

export const getDistributionHub = (
  authtoken,
  currentPage,
  pageRange,
  hubType,
  value
) => ({
  type: GET_DISTRIBUTION_HUB,
  payload: { authtoken, currentPage, pageRange, hubType, value },
});

export const getDistributionHubSuccess = (data) => ({
  type: GET_DISTRIBUTION_HUB_SUCCESS,
  payload: { data },
});
export const getDistributionHubFail = (error) => ({
  type: GET_DISTRIBUTION_HUB_FAIL,
  payload: error,
});
export const getSingleDistribution = (
  authtoken,
  currentPage,
  pageRange,
  hubType
) => ({
  type: GET_SINGLE_DISTRIBUTION,
  payload: { authtoken, currentPage, pageRange, hubType },
});

export const getSingleDistributionSuccess = (data) => ({
  type: GET_SINGLE_DISTRIBUTION_SUCCESS,
  payload: { data },
});
export const getSingleDistributionFail = (error) => ({
  type: GET_SINGLE_DISTRIBUTION_FAIL,
  payload: error,
});

export const getSingleDistributionHub = ( authtoken, id) => ({
  type: GET_SINGLE_DISTRIBUTION_HUB,
  payload: {  authtoken, id },
});

export const getSingleDistributionHubSuccess = (data) => ({
  type: GET_SINGLE_DISTRIBUTION_HUB_SUCCESS,
  payload: { data },
});
export const getSingleDistributionHubFail = (error) => ({
  type: GET_SINGLE_DISTRIBUTION_HUB_FAIL,
  payload: error,
});
export const getInvOption = (authtoken) => ({
  type: GET_INV_OPTION,
  payload: { authtoken },
});

export const getInvOptionSuccess = (data) => ({
  type: GET_INV_OPTION_SUCCESS,
  payload: { data },
});
export const getInvOptionFail = (error) => ({
  type: GET_INV_OPTION_FAIL,
  payload: error,
});

//add
export const addDistributionHub = (data, history, authtoken, id) => ({
  type: ADD_DISTRIBUTION_HUB,
  payload: { data, history, authtoken, id },
});

export const addDistributionHubSuccess = (data) => ({
  type: ADD_DISTRIBUTION_HUB_SUCCESS,
  payload: data,
});

export const addDistributionHubFail = (error) => ({
  type: ADD_DISTRIBUTION_HUB_FAIL,
  payload: error,
});
export const storeDistributionHub = (name, data) => ({
  type: STORE_DISTRIBUTION_HUB_DATA,
  payload: { name, data },
});


