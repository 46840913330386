import { combineReducers } from 'redux';
import ChallanReducer from './Challan/reducer';
import CurrentStockReducer from './CurrentStock/reducers';
import DashboardReducer from './Dashboard/reducer';
import DisburseReducer from './Disburse/reducer';
import DistributionReducer from './Distribution Hub/reducers';
import ForgetPassword from './ForgetPassword/reducer';
import GeoReducer from './GEO/reducers';
import InfoReducer from './Info/reducers';
import InventoryReducer from './Inventory/reducers';
import Layout from './layout/reducer';
import Login from './login/reducer';
import loginError from './loginError/reducer';
import ProductReducer from './Product/reducers';
import ReconciliationReducer from './Reconciliation/reducer';
import registration from './registration/reducer';
import StokeInReducer from './StokeIn/reducer';
import UserReducer from './User/reducers';

const rootReducer = combineReducers({
  Login,
  registration,
  UserReducer,
  loginError,
  ForgetPassword,
  GeoReducer,
  InfoReducer,
  DistributionReducer,
  ProductReducer,
  StokeInReducer,
  CurrentStockReducer,
  InventoryReducer,
  ChallanReducer,
  DisburseReducer,
  ReconciliationReducer,
  DashboardReducer,
  Layout,
});

export default rootReducer;
