import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getData, postData } from '../../helpers/api_helper';
import {
  getDisburseBp,
  getDisburseFail,
  getDisburseReportFail,
  getDisburseReportSuccess,
  getDisburseSuccess,
  getPartnerFail,
  getPartnerSuccess,
  storeDisburseData,
  getDisburseHubBpSuccess,
  getDisburseHubBpFail,
  getDisburseHubStockSuccess,
  getDisburseHubStockFail
} from './action.js';
import {
  ADD_DISBURSE,
  GET_DISBURSE,
  GET_DISBURSE_BP,
  GET_DISBURSE_REPORT,
  GET_DISBURSE_TODAY,
  GET_PARTNER,
  GET_HUB_BP,
  GET_SINGLE_DISBURSE,
  POST_DISBURSE_DAILY,
  GET_DISBURSE_HUB_STOCK
} from './actionTypes';

function* fetchDisburse({
  payload: { authtoken, pageRange, currentPage, distribution, status },
}) {
  try {
    const url = `/dh-inventory/get-challan-list?limit=${pageRange}&pageNo=${currentPage}&distributionHubId=${distribution}&status=${status}`;
    const response = yield call(getData, url, authtoken);

    yield put(getDisburseSuccess(response));
  } catch (error) {
    yield put(getDisburseFail(error));
  }
}

function* fetchDisburseReport({
  payload: { authtoken, startDate, bp, distribution, currentPage, pageRange },
}) {
  try {
    const url = `disbursementplan/get-all-disbursementplan?dateId=${startDate}&selectBp=${bp}&hubId=${distribution}&limit=${pageRange}&pageNo=${currentPage}`;
    const response = yield call(getData, url, authtoken);

    yield put(getDisburseReportSuccess(response));
  } catch (error) {
    yield put(getDisburseReportFail(error));
  }
}

function* fetchPartner({ payload: { authtoken, value, currentPage, pageRange } }) {
  try {
    const url = `/partner?limit=${pageRange}&pageNo=${currentPage}&partnerCode=${value}`;
    const response = yield call(getData, url, authtoken);
    yield put(getPartnerSuccess(response));
  } catch (error) {
    yield put(getPartnerFail(error));
  }
}

function* addDisburseData({ payload: { data, history, authtoken } }) {
  try {
    const url = `/disbursementplan`;
    const response = yield call(postData, url, data, authtoken);
    yield put(storeDisburseData('disburseData', response));
    yield put(storeDisburseData('disburseDataLoading', false));
    yield put(storeDisburseData('adding', false));
    toast('Disburse added successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.push('/disburse-plan');
  } catch (error) {
    console.log(error?.response);
    yield put(storeDisburseData('adding', false));
  }
}

function* fetchDisburseToday({
  payload: {
    authtoken,
    options: { dateId, hubId },
  },
}) {
  try {
    const url = `/disbursementplan/get-daily-disbursementplan?dateId=${dateId}&hubId=${hubId}`;
    const response = yield call(getData, url, authtoken);
    yield put(storeDisburseData('disburseToday', response?.disbursePlan));
    yield put(storeDisburseData('disburseDataLoading', false));
  } catch (error) {
    console.log(`🦄 ~ file: saga.js ~ line 77 ~ error`, error.response);
    yield put(storeDisburseData('disburseDataLoading', false));
  }
}

function* fetchDisburseBp({
  payload: {
    authtoken,
    options: { dateId, hubId },
  },
}) {
  try {
    const url = `/disbursementplan/get-daily-bp-disbursementplan?dateId=${dateId}&hubId=${hubId}`;
    const response = yield call(getData, url, authtoken);
    console.log(`🦄 ~ file: saga.js ~ line 72 ~ response`, response);
    yield put(storeDisburseData('disburseBp', response?.disbursePlan));
    yield put(storeDisburseData('disburseDataLoading', false));
  } catch (error) {
    console.log(`🦄 ~ file: saga.js ~ line 77 ~ error`, error.response);
    yield put(storeDisburseData('disburseDataLoading', false));
  }
}

function* fetchSingleDisburse({
  payload: {
    authtoken,
    options: { dateId, selectBp },
  },
}) {
  try {
    const url = `/disbursementplan/get-today-bp-disbursementplan?dateId=${dateId}&selectBp=${selectBp}`;
    const response = yield call(getData, url, authtoken);
    console.log(`🦄 ~ file: saga.js ~ line 72 ~ response`, response);
    yield put(storeDisburseData('singleDisburse', response));
    yield put(storeDisburseData('singleLoading', false));
  } catch (error) {
    console.log(`error`, error.response);
    yield put(storeDisburseData('singleLoading', false));
  }
}

function* postDisburse({
  payload: {
    authtoken,
    options: { value, toggle, option },
  },
}) {
  try {
    const url = `/disbursementplan/create-daily-bp-dayopening`;
    const response = yield call(postData, url, value, authtoken);
    console.log(`72 ~ response`, response);
    yield put(storeDisburseData('adding', false));
    toggle();
    yield put(getDisburseBp(authtoken, option));
  } catch (error) {
    console.log(`77 ~ error`, error.response);
    yield put(storeDisburseData('adding', false));
  }
}
function* fetchDisburseHubBp({
  payload: {authToken, role, userId },
}) {
  try {
    const url = `/users/get-bp-warehouse?role=USER&warehouseId=${userId}`;
    const response = yield call(getData, url, authToken);

    yield put(getDisburseHubBpSuccess(response));
  } catch (error) {
    yield put(getDisburseHubBpFail(error));
  }
}

function* fetchDisburseHubStock({ payload: { authtoken, category, brand, distribution, limit, pageNo } }) {
  try {

    const url = `dh-inventory/get-hub-stock?category=${category}&brand=${brand}&limit=${limit}&pageNo=${pageNo}&warehouse=${distribution}`
    const response = yield call(getData, url, authtoken)
    console.log('fff', response);
    response?.logdata?.forEach((product,index)=>{
      product.quantity = 0
   
    })

    yield put(getDisburseHubStockSuccess(response))
    yield put(storeDisburseData("disburseList", response?.logdata))
  } catch (error) {
    yield put(getDisburseHubStockFail(error))
  }
}

function* DisbarseSaga() {
  yield takeEvery(GET_DISBURSE, fetchDisburse);
  yield takeEvery(ADD_DISBURSE, addDisburseData);
  yield takeEvery(GET_PARTNER, fetchPartner);
  yield takeEvery(GET_DISBURSE_REPORT, fetchDisburseReport);
  yield takeEvery(GET_DISBURSE_TODAY, fetchDisburseToday);
  yield takeEvery(GET_DISBURSE_BP, fetchDisburseBp);
  yield takeEvery(GET_SINGLE_DISBURSE, fetchSingleDisburse);
  yield takeEvery(POST_DISBURSE_DAILY, postDisburse);
  yield takeEvery(GET_HUB_BP, fetchDisburseHubBp);
  yield takeEvery(GET_DISBURSE_HUB_STOCK, fetchDisburseHubStock);
}

export default DisbarseSaga;
