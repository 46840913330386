export const GET_BRAND = "GET_BRAND";
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_BRAND_FAIL = "GET_BRAND_FAIL";

export const GET_SINGLE_BRAND = "GET_SINGLE_BRAND";
export const GET_SINGLE_BRAND_SUCCESS = "GET_SINGLE_BRAND_SUCCESS";
export const GET_SINGLE_BRAND_FAIL = "GET_SINGLE_BRAND_FAIL";

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";

export const GET_SINGLE_CATEGORY = "GET_SINGLE_CATEGORY";
export const GET_SINGLE_CATEGORY_SUCCESS = "GET_SINGLE_CATEGORY_SUCCESS";
export const GET_SINGLE_CATEGORY_FAIL = "GET_SINGLE_CATEGORY_FAIL";
// add issue
export const ADD_BRAND = "ADD_BRAND";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const ADD_BRAND_FAIL = "ADD_BRAND_FAIL";

export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL";

export const UPDATE_BRAND = "UPDATE_BRAND"
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS"
export const UPDATE_BRAND_FAIL = "UPDATE_BRAND_FAIL"



export const ADD_PARTNER = "ADD_PARTNER";
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
export const ADD_PARTNER_FAIL = "ADD_PARTNER_FAIL";

export const GET_SINGLE_PARTNER = "GET_SINGLE_PARTNER";
export const GET_SINGLE_PARTNER_SUCCESS = "GET_SINGLE_PARTNER_SUCCESS";
export const GET_SINGLE_PARTNER_FAIL = "GET_SINGLE_PARTNER_FAIL";

export const STORE_INFO_DATA = "STORE_INFO_DATA";

export const STORE_INFO_LOADING = "STORE_INFO_LOADING";







