import {
  ADD_DISBURSE,
  ADD_DISBURSE_FAIL,
  ADD_DISBURSE_SUCCESS,
  GET_DISBURSE,
  GET_DISBURSE_BP,
  GET_DISBURSE_FAIL,
  GET_DISBURSE_REPORT,
  GET_DISBURSE_REPORT_FAIL,
  GET_DISBURSE_REPORT_SUCCESS,
  GET_DISBURSE_SUCCESS,
  GET_DISBURSE_TODAY,
  GET_PARTNER,
  GET_PARTNER_FAIL,
  GET_PARTNER_SUCCESS,
  GET_SINGLE_DISBURSE,
  POST_DISBURSE_DAILY,
  STORE_DISBURSE_DATA,
  GET_HUB_BP,
  GET_HUB_BP_SUCCESS,
  GET_HUB_BP_FAIL,
  GET_DISBURSE_HUB_STOCK,
  GET_DISBURSE_HUB_STOCK_SUCCESS,
  GET_DISBURSE_HUB_STOCK_FAIL
} from './actionTypes';

export const getDisburse = (
  authtoken,
  pageRange,
  currentPage,
  distribution,
  status
) => ({
  type: GET_DISBURSE,
  payload: { authtoken, pageRange, currentPage, distribution, status },
});

export const getDisburseSuccess = (data) => ({
  type: GET_DISBURSE_SUCCESS,
  payload: { data },
});
export const getDisburseFail = (error) => ({
  type: GET_DISBURSE_FAIL,
  payload: error,
});

export const getPartner = (authtoken, value, currentPage, pageRange) => ({
  type: GET_PARTNER,
  payload: { authtoken, value, currentPage, pageRange },
});

export const getPartnerSuccess = (data) => ({
  type: GET_PARTNER_SUCCESS,
  payload: { data },
});
export const getPartnerFail = (error) => ({
  type: GET_PARTNER_FAIL,
  payload: error,
});

//add
export const addDisburseData = (data, history, authtoken) => ({
  type: ADD_DISBURSE,
  payload: { data, history, authtoken },
});

export const addDisburseDataDataSuccess = (data) => ({
  type: ADD_DISBURSE_SUCCESS,
  payload: data,
});

export const addDisburseDataFail = (error) => ({
  type: ADD_DISBURSE_FAIL,
  payload: error,
});

export const storeDisburseData = (name, data) => ({
  type: STORE_DISBURSE_DATA,
  payload: { name, data },
});

export const getDisburseToday = (authtoken, options) => ({
  type: GET_DISBURSE_TODAY,
  payload: { authtoken, options },
});

export const getDisburseBp = (authtoken, options) => ({
  type: GET_DISBURSE_BP,
  payload: { authtoken, options },
});

export const getSingleDisburse = (authtoken, options) => ({
  type: GET_SINGLE_DISBURSE,
  payload: { authtoken, options },
});

export const postDisburse = (authtoken, options) => ({
  type: POST_DISBURSE_DAILY,
  payload: { authtoken, options },
});
export const getDisburseReport = (
  authtoken,
  startDate,
  bp,
  distribution,
  currentPage,
  pageRange
) => ({
  type: GET_DISBURSE_REPORT,
  payload: { authtoken, startDate, bp, distribution, currentPage, pageRange },
});

export const getDisburseReportSuccess = (data) => ({
  type: GET_DISBURSE_REPORT_SUCCESS,
  payload: { data },
});
export const getDisburseReportFail = (error) => ({
  type: GET_DISBURSE_REPORT_FAIL,
  payload: error,
});
export const getDisburseHubBp = (authToken, role, userId) => ({
  type: GET_HUB_BP,
  payload: { authToken, role, userId },
});

export const getDisburseHubBpSuccess = (data) => ({
  type: GET_HUB_BP_SUCCESS,
  payload: { data },
});
export const getDisburseHubBpFail = (error) => ({
  type: GET_HUB_BP_FAIL,
  payload: error,
});

export const getDisburseHubStock = (authtoken, category, brand, distribution, limit, pageNo) => ({
  type: GET_DISBURSE_HUB_STOCK,
  payload: { authtoken, category, brand, distribution, limit, pageNo },
});

export const getDisburseHubStockSuccess = (data) => ({
  type: GET_DISBURSE_HUB_STOCK_SUCCESS,
  payload: data,
});

export const getDisburseHubStockFail = (error) => ({
  type: GET_DISBURSE_HUB_STOCK_FAIL,
  payload: error,
});
