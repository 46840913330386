export const GET_DISTRIBUTION_HUB = "GET_DISTRIBUTION_HUB";
export const GET_DISTRIBUTION_HUB_SUCCESS = "GET_DISTRIBUTION_HUB_SUCCESS";
export const GET_DISTRIBUTION_HUB_FAIL = "GET_DISTRIBUTION_HUB_FAIL";

export const GET_SINGLE_DISTRIBUTION_HUB = "GET_SINGLE_DISTRIBUTION_HUB";
export const GET_SINGLE_DISTRIBUTION_HUB_SUCCESS = "GET_SINGLE_DISTRIBUTION_HUB_SUCCESS";
export const GET_SINGLE_DISTRIBUTION_HUB_FAIL = "GET_SINGLE_DISTRIBUTION_HUB_FAIL";

export const GET_SINGLE_DISTRIBUTION = "GET_SINGLE_DISTRIBUTION";
export const GET_SINGLE_DISTRIBUTION_SUCCESS = "GET_SINGLE_DISTRIBUTION_SUCCESS";
export const GET_SINGLE_DISTRIBUTION_FAIL = "GET_SINGLE_DISTRIBUTION_FAIL";

export const GET_INV_OPTION = "GET_INV_OPTION";
export const GET_INV_OPTION_SUCCESS = "GET_INV_OPTION_SUCCESS";
export const GET_INV_OPTION_FAIL = "GET_INV_OPTION_FAIL";

// add 
export const ADD_DISTRIBUTION_HUB = "ADD_DISTRIBUTION_HUB";
export const ADD_DISTRIBUTION_HUB_SUCCESS = "ADD_DISTRIBUTION_HUB_SUCCESS";
export const ADD_DISTRIBUTION_HUB_FAIL = "ADD_DISTRIBUTION_HUB_FAIL";

export const STORE_HUB_DATA = "STORE_HUB_DATA";








export const STORE_DISTRIBUTION_HUB_DATA = "STORE_DISTRIBUTION_HUB_DATA";







