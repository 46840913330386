import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Col, Form, Input, Label, Row, Spinner, Table } from 'reactstrap';
import {
  addChallanData,
  getChallanStock,
  storeChallanData,
} from '../../store/Challan/action';
import {
  getDistributionHub,
  getInvOption,
  getSingleDistribution,
} from '../../store/Distribution Hub/actions.js';
import { getBrand } from '../../store/Info/actions';
import { getProduct, storeProductData } from '../../store/Product/actions';
import AddFileUpload from '../Common/AddFileUpload';
import CustomInput from '../Common/CustomInput';
import AddCardComponent from '../Layout/AddCardComponent';
import InnerLayer from '../Layout/InnerLayer';
import { addChallanSchema } from '../Schemas/AddChallan.schema';
const breadcrumbItems = [
  {
    title: 'Challan',
    link: '#',
  },
  {
    title: 'Challan Create',
    link: '#',
  },
];
const initialValues = {
  distributionHubId: '',
  InventoryOperation: '',
  deliveryFrom: '',
  centralWarehouseId: '',
  vendorId: '',
  chalancreatedBy: '',
  chalanCreatorPhone: '',
  chalanGiverName: '',
  chalanGiverPhone: '',
  warhouseAddress: '',
  // note: ""
};
const deliveryFrom = [
  {
    name: 'Warehouse',
    _id: 1,
    value: 'Warehouse',
  },
  {
    name: 'Vendor',
    _id: 2,
    value: 'Vendor',
  },
];
const AddChallan = () => {
  const history = useHistory();
  let dispatch = useDispatch();
  // const [deliveryfrom, setDeliveryFrom] = useState('');
  const [selectedFiles, setSelectedFiles] = useState({});
  console.log(`AddChallan ~ selectedFiles`, selectedFiles);

  const {
    // role,
    products,
    authtoken,
    // productLoading,
    inventoryOperation,
    distributionHubData,
    brandData,
    singleDistribution,
    productList: productData,
    stokeList,
    challanListStock,
    // userId,
    addChallanLoading,
  } = useSelector((state) => ({
    inventoryOperation: state.DistributionReducer.inventoryOperation,
    products: state.ProductReducer.products,
    brandData: state.InfoReducer.brandData,
    singleDistribution: state.DistributionReducer.singleDistribution,
    role: state.Login.userrole,
    productLoading: state.ProductReducer.productLoading,
    authtoken: state.Login.token,
    distributionHubData: state.DistributionReducer.distributionHubData,
    productList: state.ProductReducer.productList,
    stokeList: state.ChallanReducer.stokeList,
    challanListStock: state.ChallanReducer.challanListStock,
    addChallanLoading: state.ChallanReducer.addChallanLoading,
    userId: state.Login.warehouse,
  }));
  useEffect(() => {
    dispatch(getInvOption(authtoken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   dispatch(getProduct(authtoken));
  // }, []);

  // useEffect(() => {
  //   dispatch(getChallanStock(authtoken, "62548fc603db760007f43a86"));
  // }, []);
  useEffect(() => {
    dispatch(getDistributionHub(authtoken, 0, 0, 'Central', ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, 'Hub', ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(getBrand(authtoken, 0, 0, ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = (values) => {
    let sum = 0;
    if (values?.deliveryFrom === 'Warehouse') {
      let objList = {};
      let dynamicProd = [];
      console.log('115', stokeList?.data?.totalProducts);
      stokeList?.data?.totalProducts.map((data) => {
        if (data?.quantity > 0) {
          objList.itemId = data?._id;
          objList.quantity = data?.quantity * 1;
          objList.totalAmount = data?.quantity * 1 * data?.unitPrice;
          objList.unitPrice = data?.unitPrice * 1;

          let dataList = { ...objList };
          dynamicProd.push(dataList);
        }
      });
      const newProduct = dynamicProd.map((data) => ({
        itemId: data?.itemId,
        quantity: data?.quantity,
        totalAmount: data?.totalAmount,
        unitPrice: data?.unitPrice,
      }));
      const { centralWarehouseId, vendorId, deliveryFrom, ...rest } = values;
      let body = {
        deliveryFrom,
        ...rest,
        productList: newProduct,
      };
      newProduct.map((data) => {
        sum = sum + data?.totalAmount;
      });
      if (deliveryFrom === 'Warehouse') {
        body.centralWarehouseId = centralWarehouseId;
      } else {
        body.vendorId = vendorId;
      }
      body.totalAmount = sum;
      console.log('body', body);
      if (body?.productList.length !== 0) {
        dispatch(addChallanData(body, history, authtoken));
      } else {
        toast('Please Select Quantity', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      let objList1 = {};
      let dynamicProd1 = [];
      products?.data?.product.map((data) => {
        if (data?.quantity > 0) {
          objList1.itemId = data?._id;
          objList1.quantity = data?.quantity * 1;
          objList1.totalAmount = data?.quantity * 1 * data?.unitPrice;
          objList1.unitPrice = data?.unitPrice * 1;

          let dataList1 = { ...objList1 };
          dynamicProd1.push(dataList1);
        }
      });
      const newProduct = dynamicProd1.map((data) => ({
        itemId: data?.itemId,
        quantity: data?.quantity * 1,
        totalAmount: data?.totalAmount,
        unitPrice: data?.unitPrice,
        //  sum : sum+ (data?.quantity *1)* 2,
      }));
      const { centralWarehouseId, vendorId, deliveryFrom, ...rest } = values;
      let body = {
        deliveryFrom,
        ...rest,
        productList: newProduct,
      };
      newProduct.map((data) => {
        sum = sum + data?.totalAmount;
      });

      if (deliveryFrom === 'Warehouse') {
        body.centralWarehouseId = centralWarehouseId;
      } else {
        body.vendorId = vendorId;
      }
      body.totalAmount = sum;
      console.log('body', body);
      if (body?.productList.length !== 0) {
        dispatch(addChallanData(body, history, authtoken));
      } else {
        toast('Please Select Quantity', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    // })
  };

  const handleSubmitVendorQtyEdit = (e, idx, data) => {
    const oldValue = [...productData];

    oldValue[idx].quantity = e.target.value;
    oldValue[idx].totalAmount = e.target.value * 1 * oldValue[idx].unitPrice;
    dispatch(storeProductData('productList', oldValue));
  };

  const handleSubmitQtyEdit = (e, idx, data) => {
    const oldValue = [...challanListStock];
    if (e.target.value * 1 <= oldValue[idx].stockData.stock) {
      oldValue[idx].quantity = e.target.value;
      oldValue[idx].totalAmount = e.target.value * 1 * oldValue[idx].unitPrice;
      dispatch(storeChallanData('challanListStock', oldValue));
    }
    if (e.target.value * 1 > oldValue[idx].stockData.stock) {
      toast('Quantity can not be bigger than Stock', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const AddChallan = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addChallanSchema,
    onSubmit,
    // handleDelivery,
  });

  const handleDistribution = (data) => {
    console.log('lll', data);
    if (!!data?._id) {
      dispatch(getChallanStock(authtoken, data?._id));
    } else {
      dispatch(getChallanStock({}));
    }
  };
  const handleVendor = (data) => {
    if (!!data?._id) {
      dispatch(getProduct(authtoken, data?._id));
    } else {
      dispatch(getProduct({}));
    }
  };
  console.log('AddChallan', AddChallan);

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={'Challan'}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: '100vh' }} className="position-relative">
          <Row className="mt-4">
            <Col md="11" className="mx-auto">
              {/* add User */}
              <AddCardComponent className="">
                <Form
                  className="needs-validation col-md-12"
                  onSubmit={AddChallan.handleSubmit}
                >
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md="12 mb-2">
                          {/* <CustomInput
                            name={"distributionHubId"}
                            type={"select"}
                            label={"Select Distribution Hub"}
                            placeholder={"Select Distribution Hub"}
                            validationType={AddChallan}
                          >
                            <option defaultValue>Select Distribution...</option>
                            {singleDistribution?.data?.length > 0 ? (
                              singleDistribution?.data?.Warehouse?.map(
                                (data, idx) => (
                                  <option value={data._id} key={idx}>
                                    {data.name}
                                  </option>
                                )
                              )
                            ) : (
                              <span></span>
                            )}
                          </CustomInput> */}
                          <Label htmlFor="formrow-firstname-Input">
                            Select Distribution Hub
                          </Label>
                          <Select
                            name="distributionHubId"
                            className="mb-2"
                            classNamePrefix="select2-selection"
                            placeholder="Select Distribution Hub"
                            cacheOptions
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.value}
                            isClearable
                            options={singleDistribution?.data?.Warehouse}
                            onChange={(e) => {
                              AddChallan.setFieldValue(
                                'distributionHubId',
                                e?._id || ''
                              );
                            }}
                          />
                          {AddChallan.errors.distributionHubId ? (
                            <p className="text-danger">
                              {AddChallan.errors.distributionHubId}
                            </p>
                          ) : null}
                        </Col>
                        <Col md="12 mb-2">
                          <CustomInput
                            name={'deliveryFrom'}
                            type={'select'}
                            label={'Delivery Source'}
                            validationType={AddChallan}
                            //  onChange={(e)=> handleDelivery(e)}
                          >
                            <option defaultValue>
                              Select Delivery Source...
                            </option>
                            {deliveryFrom?.length > 0 ? (
                              deliveryFrom?.map((data, idx) => (
                                <option value={data?.value} key={idx}>
                                  {data.name}
                                </option>
                              ))
                            ) : (
                              <span></span>
                            )}
                          </CustomInput>
                        </Col>
                        {AddChallan?.values?.deliveryFrom === 'Warehouse' ? (
                          <Col md="12 mb-2">
                            {/* <CustomInput
                              name={"centralWarehouseId"}
                              type={"select"}
                              label={"Select Warehouse"}
                              placeholder={"Select Warehouse"}
                              validationType={AddChallan}
                              handleChange={handleDistribution}
                            >
                              <option defaultValue>Select Warehouse...</option>
                              {distributionHubData?.data?.length > 0 ? (
                                distributionHubData?.data?.Warehouse?.map(
                                  (data, idx) => (
                                    <option value={data._id} key={idx}>
                                      {data.name}
                                    </option>
                                  )
                                )
                              ) : (
                                <span></span>
                              )}
                            </CustomInput> */}
                            <Label htmlFor="formrow-firstname-Input">
                              Select Warehouse
                            </Label>
                            <Select
                              name="centralWarehouseId"
                              className="mb-2"
                              classNamePrefix="select2-selection"
                              placeholder="Select Warehouse"
                              cacheOptions
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.value}
                              isClearable
                              options={distributionHubData?.data?.Warehouse}
                              onChange={(e) => {
                                AddChallan.setFieldValue(
                                  'centralWarehouseId',
                                  e?._id || ''
                                );
                                handleDistribution(e);
                              }}
                            />
                            {AddChallan.errors.centralWarehouseId ? (
                              <p className="text-danger">
                                {AddChallan.errors.centralWarehouseId}
                              </p>
                            ) : null}
                          </Col>
                        ) : (
                          <span></span>
                        )}
                        {AddChallan?.values?.deliveryFrom === 'Vendor' ? (
                          <Col md="12 mb-2">
                            {/* <CustomInput
                              name={"vendorId"}
                              type={"select"}
                              label={"Select Vendor"}
                              placeholder={"Select Vendor"}
                              validationType={AddChallan}
                              handleChange={handleVendor}
                            >
                              <option defaultValue>Select Vendor...</option>
                              {brandData?.data?.length > 0 ? (
                                brandData?.data?.brand?.map((data, idx) => (
                                  <option value={data._id} key={idx}>
                                    {data.name}
                                  </option>
                                ))
                              ) : (
                                <span></span>
                              )}
                            </CustomInput> */}
                            <Label htmlFor="formrow-firstname-Input">
                              Select Vendor
                            </Label>
                            <Select
                              name="vendorId"
                              className="mb-2"
                              classNamePrefix="select2-selection"
                              placeholder="Select Vendor"
                              cacheOptions
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.value}
                              isClearable
                              options={brandData?.data?.brand}
                              onChange={(e) => {
                                AddChallan.setFieldValue(
                                  'vendorId',
                                  e?._id || ''
                                );
                                handleVendor(e);
                              }}
                            />
                            {AddChallan.errors.vendorId ? (
                              <p className="text-danger">
                                {AddChallan.errors.vendorId}
                              </p>
                            ) : null}
                          </Col>
                        ) : (
                          <span></span>
                        )}
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md="12 mb-2">
                          <CustomInput
                            name={'InventoryOperation'}
                            type={'select'}
                            label={'Select Inventory  Option'}
                            validationType={AddChallan}
                          >
                            <option defaultValue>
                              Select Inventory Option...
                            </option>
                            {inventoryOperation?.data?.length > 0 ? (
                              inventoryOperation?.data?.operation?.map(
                                (data, idx) => (
                                  <option value={data._id} key={idx}>
                                    {data?.operationName}
                                  </option>
                                )
                              )
                            ) : (
                              <span></span>
                            )}
                          </CustomInput>
                        </Col>
                        {/* <Col md="12 mb-2">
                          <CustomInput
                            name={"note"}
                            type={"textarea"}
                            label={"Note"}
                            placeholder={"Type..."}
                            validationType={AddChallan}
                            style={{ height: "80px" }}
                          />
                        </Col> */}
                      </Row>
                    </Col>
                    <Row>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={'chalancreatedBy'}
                          type={'text'}
                          label={'Challan Creator Name'}
                          placeholder={'Type Creator Name'}
                          validationType={AddChallan}
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={'chalanCreatorPhone'}
                          type={'text'}
                          label={'Challan Creator Phone'}
                          placeholder={'Type Creator Phone Number'}
                          validationType={AddChallan}
                        />
                      </Col>
                    </Row>
                    <Row>
                    <Col md="6 mb-3">
                      <CustomInput
                        name={"chalanGiverName"}
                        type={"text"}
                        label={"Bp Name"}
                        placeholder={"Type Bp Name"}
                        validationType={AddChallan}
                      />
                    </Col>
                    <Col md="6 mb-3">
                      <CustomInput
                        name={"chalanGiverPhone"}
                        type={"number"}
                        label={"Bp Phone Number"}
                        placeholder={"Type Bp Phone Number"}
                        validationType={AddChallan}
                      />
                    </Col>
                    </Row>
                    <Row>
                    <Col md="6 mb-3">
                      <CustomInput
                        name={"chalanReceiverName"}
                        type={"text"}
                        label={"Hub Manager Name"}
                        placeholder={"Type Hub manager Name"}
                        validationType={AddChallan}
                      />
                    </Col>
                    <Col md="6 mb-3">
                      <CustomInput
                        name={"chalanReceiverPhoneNumber"}
                        type={"number"}
                        label={"Hub Manager Phone Number"}
                        placeholder={"Type Manager Phone Number"}
                        validationType={AddChallan}
                      />
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={'warhouseAddress'}
                          type={'text'}
                          label={'Warehouse Address'}
                          placeholder={'Type Address'}
                          validationType={AddChallan}
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={'note'}
                          type={'textarea'}
                          label={'Note'}
                          placeholder={'Type...'}
                          validationType={AddChallan}
                          style={{ height: '80px' }}
                        />
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <div className="table-responsive">
                      {AddChallan?.values?.deliveryFrom === 'Warehouse' ? (
                        <Table className="table table-hover mb-0 table-centered ">
                          <thead className="table-light">
                            <tr>
                              <th>Sl. No.</th>
                              <th>Category</th>
                              <th>Brand</th>

                              <th>Product</th>
                              <th>Stock</th>

                              <th>QTY</th>
                              <th>Unit Price</th>
                              <th>Total Amount</th>
                            </tr>
                          </thead>

                          <tbody>
                            {(challanListStock || []).map((data, key) => {
                              return (
                                <>
                                  <tr>
                                    <td>{key + 1}</td>
                                    <td>{data?.category?.name}</td>
                                    <td>{data?.brand?.name}</td>

                                    <td>{data?.name}</td>
                                    <td>{data?.stockData?.stock}</td>
                                    <td>
                                      <Input
                                        type="text"
                                        value={data?.quantity}
                                        style={{
                                          width: '64px',
                                          height: '27px',
                                        }}
                                        onChange={(e) =>
                                          handleSubmitQtyEdit(e, key, data)
                                        }
                                      ></Input>
                                    </td>
                                    <td>{data?.unitPrice}</td>
                                    <td>{data?.totalAmount}</td>
                                  </tr>
                                </>
                              );
                            })}
                            {/* <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="text-end">
                                {" "}
                                <h6>Grand Total:</h6>
                              </td>

                              <td>
                                <h6>
                                  {" "}
                                  {challanListStock?.reduce(
                                    (total, item) =>
                                      total + item.totalAmount * 1,
                                    0
                                  )}
                                </h6>
                              </td>
                            </tr> */}
                          </tbody>
                        </Table>
                      ) : (
                        <span></span>
                      )}

                      {AddChallan?.values?.deliveryFrom === 'Vendor' ? (
                        <Table className="table table-hover mb-0 table-centered table-nowrap">
                          <thead className="table-light">
                            <tr>
                              <th>Sl. No.</th>
                              <th>Category</th>
                              <th>Brand</th>

                              <th>Product</th>

                              <th>QTY</th>
                              <th>Unit Price</th>
                              <th>Total Amount</th>
                            </tr>
                          </thead>

                          <tbody>
                            {(productData || []).map((data, key) => {
                              return (
                                <>
                                  <tr>
                                    <td>{key + 1}</td>
                                    <td>{data?.category?.name}</td>
                                    <td>{data?.brand?.name}</td>

                                    <td>{data?.name}</td>

                                    <td>
                                      <Input
                                        type="text"
                                        // value={data?.quantity}
                                        style={{
                                          width: '64px',
                                          height: '27px',
                                        }}
                                        onChange={(e) =>
                                          handleSubmitVendorQtyEdit(
                                            e,
                                            key,
                                            data
                                          )
                                        }
                                      ></Input>
                                    </td>
                                    <td>{data?.unitPrice}</td>
                                    <td>{data?.totalAmount}</td>
                                  </tr>
                                </>
                              );
                            })}
                            {/* <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>

                              <td>
                                <h6>
                                  Grand Total:{" "}
                                  {challanListStock?.reduce(
                                    (total, item) =>
                                      total + item.totalAmount * 1,
                                    0
                                  )}
                                </h6>
                              </td>
                            </tr> */}
                          </tbody>
                        </Table>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    {/* table end */}
                  </Row>
                  {addChallanLoading ? (
                    <div className="d-flex justify-content-end mt-3">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end mt-3">
                      <input
                        type="submit"
                        value={'Submit'}
                        className="btn button "
                      />
                    </div>
                  )}
                </Form>
              </AddCardComponent>
              <AddFileUpload setSelectedFiles={setSelectedFiles} />
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  );
};

export default AddChallan;
