import {
  ADD_DISBURSE,
  GET_DISBURSE_BP,
  GET_DISBURSE_FAIL,
  GET_DISBURSE_SUCCESS,
  GET_DISBURSE_TODAY,
  GET_PARTNER_FAIL,
  GET_PARTNER_SUCCESS,
  GET_SINGLE_DISBURSE,
  POST_DISBURSE_DAILY,
  STORE_DISBURSE_DATA,
  GET_DISBURSE_REPORT,
  GET_HUB_BP_SUCCESS,
  GET_HUB_BP_FAIL,
  GET_DISBURSE_REPORT_SUCCESS,
  GET_DISBURSE_REPORT_FAIL,
  GET_DISBURSE_HUB_STOCK_SUCCESS,
  GET_DISBURSE_HUB_STOCK_FAIL
} from './actionTypes';

const INIT_STATE = {
  disburseReport: [],
  disburseReportLoading: true,
  disburseData: [],
  disburseDataLoading: true,
  partnerList: [],
  partnerListLoading: true,
  disburseToday: [],
  disburseBp: [],
  disburseHubBp: [],
  disburseHubStock: [],
  disburseList: [],
  singleDisburse: {},
  singleLoading: false,
  disburseHubBpLoading: true,
  disburseHubStockLoading: true,
  adding: false,
};
const DisburseReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DISBURSE_SUCCESS:
      return {
        ...state,
        disburseData: action.payload,
        disburseDataLoading: false,
      };
    case GET_DISBURSE_FAIL:
      return {
        ...state,
        error: action.payload,
        disburseDataLoading: false,
      };
      case GET_DISBURSE_REPORT_SUCCESS:
        return {
          ...state,
          disburseReport: action.payload,
          disburseReportLoading: false,
        };
      case GET_DISBURSE_REPORT_FAIL:
        return {
          ...state,
          error: action.payload,
          disburseReportLoading: false,
        };

        case GET_HUB_BP_SUCCESS:
          return {
            ...state,
            disburseHubBp: action.payload,
            disburseHubBpLoading: false,
          };
        case GET_HUB_BP_FAIL:
          return {
            ...state,
            error: action.payload,
            disburseHubBpLoading: false,
          };
    case ADD_DISBURSE:
      return {
        ...state,
        adding: true,
      };
    case GET_PARTNER_SUCCESS:
      return {
        ...state,
        partnerList: action.payload,
        partnerListLoading: false,
      };
    case GET_PARTNER_FAIL:
      return {
        ...state,
        error: action.payload,
        partnerListLoading: false,
      };

    case GET_DISBURSE_TODAY:
      return {
        ...state,
        disburseDataLoading: true,
      };

    case GET_DISBURSE_BP:
      return {
        ...state,
        disburseDataLoading: true,
      };

    case GET_SINGLE_DISBURSE:
      return {
        ...state,
        singleLoading: true,
      };

    case POST_DISBURSE_DAILY:
      return {
        ...state,
        adding: true,
      };
      case GET_DISBURSE_HUB_STOCK_SUCCESS:
          return {
            ...state,
            disburseHubStock: action.payload,
            disburseHubStockLoading: false,
          };
        case GET_DISBURSE_HUB_STOCK_FAIL:
          return {
            ...state,
            error: action.payload,
            disburseHubStockLoading: false,
          };

    case STORE_DISBURSE_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };

    default:
      return state;
  }
};
export default DisburseReducer;
