import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, Row, Spinner, Table } from "reactstrap";
import { Modal, Button } from "react-bootstrap";
import Question from "../../assests/images/question.svg";
import {
  getChallan,
  getChallanDetails,
  getChallanStock,
  storeChallanData,
  returnChallan,
  claimChallanData,
} from "../../store/Challan/action";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
const NotificationChallan = () => {
  let dispatch = useDispatch();
  const history = useHistory();
  const [detailShow, setDetailShow] = useState(false);
  const [show, setShow] = useState(false);
  const [damageChallanID, setDamageChallanID] = useState("");
  const { challanId } = useParams();
  console.log("jjj", challanId);
  const {
    role,
    authtoken,
    challanData,
    singleDistribution,
    challanDetails,
    userId,
    challanDataLoading,
    MissedChallan,
    returnChallanLoading,
    damageChallan,
    claimChallanLoading,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    role: state.Login.userrole,
    challanData: state.ChallanReducer.challanData,
    challanDataLoading: state.ChallanReducer.challanDataLoading,
    singleDistribution: state.DistributionReducer.singleDistribution,
    MissedChallan: state.ChallanReducer.MissedChallan,
    damageChallan: state.ChallanReducer.damageChallan,
    challanDetails: state.ChallanReducer.challanDetails,
    userId: state.Login.warehouse,
    returnChallanLoading: state.ChallanReducer.returnChallanLoading,
    claimChallanLoading: state.ChallanReducer.claimChallanLoading,
  }));
  useEffect(() => {
    dispatch(getChallanDetails(authtoken, challanId));
  }, [challanId]);

  const handleClaimChallan = () => {
    console.log("damageChallan", damageChallan);
    // console.log("productData", productData);
    var objList = {};
    var dynamicProd = [];
    damageChallan.map((data) => {
      if (data?.challanDamageData > 0 && data?._id === damageChallanID) {
        objList.id = data?._id;
        objList.claimQty = data?.challanDamageData * 1;
        objList.claimStatus = true;
        let dataList = { ...objList };
        dynamicProd.push(dataList);
      }
    });
    console.log("dynamicProd", dynamicProd);
    const newProduct = dynamicProd.map((data) => ({
      id: data?.id,
      claimQty: data?.claimQty,
      claimStatus: data?.claimStatus,
    }));
    console.log("newProduct", newProduct[0]);
    dispatch(claimChallanData(newProduct[0], history, authtoken));
    setShow(false);
    setDetailShow(false);
  };
  const handleSubmitQtyEdit = (e, idx, data) => {
    console.log("sd", e.target.value);
    const oldValue = [...damageChallan];
    console.log("oldValue", oldValue);

    if (e.target.value <= oldValue[idx]?.damageQty) {
      oldValue[idx].challanDamageData = e.target.value;
      oldValue[idx].claimQty = e.target.value;

      dispatch(storeChallanData("damageChallan", oldValue));

      console.log("oldValue", oldValue);
    }
    if (e.target.value * 1 > oldValue[idx]?.damageQty) {
      toast("Received QTY can not be bigger than Qty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handleShow = (data) => {
    console.log("data", data);
    setDamageChallanID(data?._id);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  return (
    <>
      <Container>
        <div className="page-content m-auto">
          <h4 className="text-center" style={{ color: "#0C1D37" }}>
            Challan Information
          </h4>
          <Row className="mt-5 mb-3 ">
            <Col md="6">
              <h6 className="text-black">Challan No.</h6>
              <span className="">
                CH - {challanDetails?.data?.challan?.challanNo}
              </span>
            </Col>
            <Col md="6">
              <h6 className="text-black">Challan Date</h6>
              <span>
                {challanDetails?.data?.challan?.challanDate.slice(0, 10)}
              </span>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="6">
              <h6 className="text-black">Distribution Hub</h6>
              <span>
                {challanDetails?.data?.challan?.distributionHubId?.name}
              </span>
            </Col>
            <Col md="6">
              <h6 className="text-black">Date of Receipt</h6>
              <span>{challanDetails?.data?.challan?.challanReceivedDate}</span>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="6">
              <h6 className="text-black">Delivery Source</h6>
              <span>{challanDetails?.data?.challan?.deliveryFrom}</span>
            </Col>
            {challanDetails?.data?.challan?.deliveryFrom === "Warehouse" ? (
              <Col md="6">
                <h6 className="text-black">Warehouse Name</h6>
                <span>
                  {challanDetails?.data?.challan?.centralWarehouseId?.name}
                </span>
              </Col>
            ) : (
              <Col md="6">
                <h6 className="text-black">Vendor Name</h6>
                <span>{challanDetails?.data?.challan?.vendorId?.name}</span>
              </Col>
            )}
          </Row>
          <Row>
            {challanDetails?.data?.challan?.chalancreatedBy ? (
              <Col md="6" className="mb-3">
                <h6 className="text-black">Challan Issuer name</h6>
                <span>{challanDetails?.data?.challan?.chalancreatedBy}</span>
              </Col>
            ) : null}
            {challanDetails?.data?.challan?.chalanCreatorPhone ? (
              <Col md="6" className="mb-3">
                <h6 className="text-black">Challan Issuer phone</h6>
                <span>{challanDetails?.data?.challan?.chalanCreatorPhone}</span>
              </Col>
            ) : null}
            {challanDetails?.data?.challan?.chalanGiverName ? (
              <Col md="6" className="mb-3">
                <h6 className="text-black">BP Name</h6>
                <span>{challanDetails?.data?.challan?.chalanGiverName}</span>
              </Col>
            ) : null}
            {challanDetails?.data?.challan?.chalanGiverPhone ? (
              <Col md="6" className="mb-3">
                <h6 className="text-black">BP Phone Number</h6>
                <span>{challanDetails?.data?.challan?.chalanGiverPhone}</span>
              </Col>
            ) : null}
            {challanDetails?.data?.challan?.warhouseAddress ? (
              <Col md="6" className="mb-3">
                <h6 className="text-black">Warehouse Address</h6>
                <span>{challanDetails?.data?.challan?.warhouseAddress}</span>
              </Col>
            ) : null}
            {challanDetails?.data?.challan?.chalanReceiverName ? (
              <Col md="6" className="mb-3">
                <h6 className="text-black">Hub Manager Name</h6>
                <span>{challanDetails?.data?.challan?.chalanReceiverName}</span>
              </Col>
            ) : null}
            {challanDetails?.data?.challan?.chalanReceiverPhoneNumber ? (
              <Col md="6" className="mb-3">
                <h6 className="text-black">Hub Manager Phone Number</h6>
                <span>
                  {challanDetails?.data?.challan?.chalanReceiverPhoneNumber}
                </span>
              </Col>
            ) : null}
            <Col md="6" className="mb-3">
              <h6 className="text-black">Note</h6>
              <span>{challanDetails?.data?.challan?.note}</span>
            </Col>
            {/* {challanDetails?.data?.challan.status==="Missing" ? (
                       <Col md="6" className="mb-3">
                         <h6 className="text-black">Settlement Status</h6>
                         {
                           challanDetails?.data?.challan?.settlement === "YES" ?
                           <span>Already done</span>
                           :
                           <span>Not done </span>
                         }
                         
                       </Col>
                     ) : null} */}
          </Row>
          <h5 style={{ color: "#1C1D1E" }} className="mt-4 mb-3">
            Challan Details
          </h5>
          <div className="table-responsive">
            <Table className="table table-hover mb-0 table-centered  mb-5">
              <thead className="table-light">
                <tr>
                  <th>No.</th>
                  {/* <th>Category</th>
                           <th>Brand</th> */}
                  <th>Product</th>
                  <th>QTY</th>

                  <th>Received QTY</th>
                  <th>Missing QTY</th>
                  <th>Buffer QTY</th>
                  <th>Damage QTY</th>
                  <th>Claim QTY</th>
                  <th>Settle QTY</th>
                  <th>Action</th>
                </tr>
              </thead>
              {(damageChallan || []).map((data, key) => {
                return (
                  <>
                    <tbody>
                      <tr>
                        <td>{key + 1}</td>
                        {/* <td>{data?.itemId?.category?.name}</td>
                                 <td>{data?.itemId?.brand?.name}</td> */}
                        <td>{data?.itemId?.name}</td>
                        <td>{data?.quantity}</td>

                        <td>{data?.actualReceive}</td>
                        {data?.quantity > data?.actualReceive ? (
                          <td>{data?.quantity - data?.actualReceive}</td>
                        ) : (
                          <td>0</td>
                        )}
                        {data?.quantity < data?.actualReceive ? (
                          <td>{data?.actualReceive - data?.quantity}</td>
                        ) : (
                          <td>0</td>
                        )}

                        {/* {isNaN(
                                     data?.quantity - data?.receivedQty
                                   ) ? (
                                     <td>0</td>
                                   ) : (
                                     <td>
                                       {data?.quantity - data?.receivedQty}
                                     </td>
                                   )} */}
                        <td>{data?.damageQty}</td>
                        <td>{data?.claimQty}</td>
                        {data?.claimStatus === false && role !== "HUB" ? (
                          <td>
                            <Input
                              type="text"
                              value={data?.challanDamageData}
                              style={{
                                width: "80px",
                                height: "30px",
                              }}
                              onChange={(e) =>
                                handleSubmitQtyEdit(e, key, data)
                              }
                            ></Input>
                          </td>
                        ) : (
                          <span></span>
                        )}
                        {data?.claimStatus === false && role !== "HUB" ? (
                          <td>
                            <button
                              className="btn btn-secondary btn-sm me-2"
                              onClick={() => handleShow(data)}
                            >
                              Claim
                            </button>
                          </td>
                        ) : (
                          <span></span>
                        )}
                      </tr>
                    </tbody>
                  </>
                );
              })}
            </Table>
          </div>
          {/* {challanDetails?.data?.challan?.status === "Missing" &&
                   role !== "HUB" &&
                   challanDetails?.data?.challan?.settlement !== "YES" ? (
                     <div className="text-end">
                       <button
                         disabled={returnChallanLoading}
                         className="tab-button4 mb-5"
                         style={{ padding: "10px 15px" }}
                         onClick={handleShow}
                       >
                         {returnChallanLoading ? "Submitting..." : "Confirm"}
                       </button>
                     </div>
                   ) : challanDetails?.data?.challan?.status === "Missing" &&
                     challanDetails?.data?.challan?.settlement === "YES" ? (
                     <div className="text-end">
                       <button
                         disabled
                         className="tab-button4 mb-5 btn-success fw-bold"
                         style={{ padding: "10px 15px" }}
                       >
                         Already Settled
                       </button>
                     </div>
                   ) : (
                     <span></span>
                   )} */}
          {challanDetails?.data?.challan?.isSettled === true ? (
            <div>
              <h5 style={{ color: "#1C1D1E" }} className="mt-4 mb-3">
                Settlement product
              </h5>
              <div className="table-responsive">
                <Table className="table table-hover mb-0 table-centered  mb-5">
                  <thead className="table-light">
                    <tr>
                      <th>No.</th>

                      <th>Product</th>

                      <th>Settle QTY</th>
                    </tr>
                  </thead>
                  {(
                    challanDetails?.data?.challan?.setteledId?.settledProduct ||
                    []
                  ).map((data, key) => {
                    return (
                      <>
                        <tbody>
                          <tr>
                            <td>{key + 1}</td>
                            {/* <td>{data?.itemId?.category?.name}</td>
                                 <td>{data?.itemId?.brand?.name}</td> */}
                            <td>{data?.productId?.name}</td>
                            <td>{data?.setteleQty}</td>
                          </tr>
                        </tbody>
                      </>
                    );
                  })}
                </Table>
              </div>
            </div>
          ) : (
            ""
          )}
                  <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body className="text-center">
            <div className="mb-4">
              <img src={Question} alt="Image" style={{ width: "76px" }} />
            </div>
            <h5>Are you want to claim the product?</h5>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "none", justifyContent: "center" }}
            className="mb-4"
          >
            <Button
              variant="outline-secondary"
              onClick={handleClose}
              style={{ width: "112px" }}
            >
              Cancel
            </Button>
            <Button
              className="btn button"
              onClick={handleClaimChallan}
              disabled={claimChallanLoading}
            >
              {claimChallanLoading ? "Submitting..." : " Yes, I’m Sure"}
            </Button>
          </Modal.Footer>
        </Modal>
        </div>
      </Container>
    </>
  );
};

export default NotificationChallan;
