import {
  ADD_RECONCILIATION,
  ADD_RECONCILIATION_FAIL,
  ADD_RECONCILIATION_SUCCESS,
  GET_RECONCILIATION_FAIL,
  GET_RECONCILIATION_SUCCESS,
  STORE_RECONCILIATION_DATA
} from './actionTypes';
const INIT_STATE = {
  reconciliationData: [],
  reconciliationLoading: true,
  reconciliationReceived: [],
  adding: false,
};

const ReconciliationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RECONCILIATION_SUCCESS:
      return {
        ...state,
        reconciliationData: action.payload,
        reconciliationLoading: false,
      };
    case GET_RECONCILIATION_FAIL:
      return {
        ...state,
        error: action.payload,
        reconciliationLoading: false,
      };
    case ADD_RECONCILIATION:
      return {
        ...state,
        adding: true,
      };
    case ADD_RECONCILIATION_SUCCESS:
      return {
        ...state,
        adding: false,
      };
    case ADD_RECONCILIATION_FAIL:
      return {
        ...state,
        error: action.payload,
        adding: false,
      };

      case STORE_RECONCILIATION_DATA:
        return {
          ...state,
          [action.payload.name]: action.payload.data,
        };

    default:
      return state;
  }
};
export default ReconciliationReducer;
