import {
    GET_CS_CENTRAL_INVENTORY_SUCCESS,
    GET_CS_CENTRAL_INVENTORY_FAIL,
    GET_CS_DISTRIBUTION_INVENTORY_SUCCESS,
    GET_CS_DISTRIBUTION_INVENTORY_FAIL,
    GET_CS_BP_INVENTORY_SUCCESS,
    GET_CS_BP_INVENTORY_FAIL,
 
    } from "./actionTypes";
    const INIT_STATE = {
      csCentralInventory: [],
      csDistributionInventory: [],
      csBpInventory: [],
      csCentralInventoryLoading: true,
      csDistributionInventoryLoading: true,
      csBpInventoryLoading: true,
    };
    const CurrentStockReducer = (state = INIT_STATE, action) => {
      switch (action.type) {
        case GET_CS_CENTRAL_INVENTORY_SUCCESS:
          return {
            ...state,
            csCentralInventory: action.payload,
            csCentralInventoryLoading: false,
          };
        case GET_CS_CENTRAL_INVENTORY_FAIL:
          return {
            ...state,
            error: action.payload,
            csCentralInventoryLoading: false,
          };
        case GET_CS_DISTRIBUTION_INVENTORY_SUCCESS:
          return {
            ...state,
            csDistributionInventory: action.payload,
            csDistributionInventoryLoading: false,
          };
        case GET_CS_DISTRIBUTION_INVENTORY_FAIL:
          return {
            ...state,
            error: action.payload,
            csDistributionInventoryLoading: false,
          };
          case GET_CS_BP_INVENTORY_SUCCESS:
            return {
              ...state,
              csBpInventory: action.payload,
              csBpInventoryLoading: false,
            };
          case GET_CS_BP_INVENTORY_FAIL:
            return {
              ...state,
              error: action.payload,
              csBpInventoryLoading: false,
            };
         
      
         
        default:
          return state;
      }
    };
    export default CurrentStockReducer;
    