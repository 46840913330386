import {
    GET_DISTRIBUTION_HUB_SUCCESS,
    GET_DISTRIBUTION_HUB_FAIL,
    GET_SINGLE_DISTRIBUTION_HUB_SUCCESS,
    GET_SINGLE_DISTRIBUTION_HUB_FAIL,
    GET_INV_OPTION_SUCCESS,
    GET_INV_OPTION_FAIL,
    STORE_DISTRIBUTION_HUB_DATA,
    GET_SINGLE_DISTRIBUTION_SUCCESS,
    GET_SINGLE_DISTRIBUTION_FAIL,
    ADD_DISTRIBUTION_HUB
   
 
  } from "./actionTypes";
  const INIT_STATE = {
    distributionHubData: [],
    inventoryOperation: [],
    singleDistribution: [],
    singleDistributionHub: [],
  
    distributionHubLoading: true,
    inventoryOperationLoading: true,
    singleDistributionLoading: true,
    singleDistributionHubLoading: true,
    hubLoading: false
    
 
  };
  const DistributionReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_DISTRIBUTION_HUB_SUCCESS:
        return {
          ...state,
          distributionHubData: action.payload,
          distributionHubLoading: false,
        };
      case GET_DISTRIBUTION_HUB_FAIL:
        return {
          ...state,
          error: action.payload,
          distributionHubLoading: false,
        };
        case GET_SINGLE_DISTRIBUTION_SUCCESS:
          return {
            ...state,
            singleDistribution: action.payload,
            singleDistributionLoading: false,
          };
        case GET_SINGLE_DISTRIBUTION_FAIL:
          return {
            ...state,
            error: action.payload,
            singleDistributionLoading: false,
          };

          case GET_SINGLE_DISTRIBUTION_HUB_SUCCESS:
            return {
              ...state,
              singleDistributionHub: action.payload,
              singleDistributionHubLoading: false,
            };
          case GET_SINGLE_DISTRIBUTION_HUB_FAIL:
            return {
              ...state,
              error: action.payload,
              singleDistributionHubLoading: false,
            };
        case GET_INV_OPTION_SUCCESS:
          return {
            ...state,
            inventoryOperation: action.payload,
            inventoryOperationLoading: false,
          };
        case GET_INV_OPTION_FAIL:
          return {
            ...state,
            error: action.payload,
            inventoryOperationLoading: false,
          };
          case ADD_DISTRIBUTION_HUB:
            return {
              ...state,
              hubLoading: true,
            };

        case STORE_DISTRIBUTION_HUB_DATA:
            return {
              ...state,
              [action.payload.name]: action.payload.data,
            }
      default:
        return state;
    }
  };
  export default DistributionReducer;
  