export const GET_CENTRAL_WAREHOUSE = "GET_CENTRAL_WAREHOUSE";
export const GET_CENTRAL_WAREHOUSE_SUCCESS = "GET_CENTRAL_WAREHOUSE_SUCCESS";
export const GET_CENTRAL_WAREHOUSE_FAIL = "GET_CENTRAL_WAREHOUSE_FAIL";

export const GET_DISTRIBUTION_HUB_INV = "GET_DISTRIBUTION_HUB_INV";
export const GET_DISTRIBUTION_HUB_INV_SUCCESS = "GET_DISTRIBUTION_HUB_SUCCESS";
export const GET_DISTRIBUTION_HUB_INV_FAIL = "GET_DISTRIBUTION_HUB_FAIL";

export const GET_BP_INVENTORY = "GET_BP_INVENTORY";
export const GET_BP_INVENTORY_SUCCESS = "GET_BP_INVENTORY_SUCCESS";
export const GET_BP_INVENTORY_FAIL = "GET_BP_INVENTORY_FAIL";





export const GET_CW_STOCK_REPORT = "GET_CW_STOCK_REPORT";
export const GET_CW_STOCK_REPORT_SUCCESS = "GET_CW_STOCK_REPORT_SUCCESS";
export const GET_CW_STOCK_REPORT_FAIL = "GET_CW_STOCK_REPORT_FAIL";



export const GET_DH_STOCK_REPORT = "GET_DH_STOCK_REPORT";
export const GET_DH_STOCK_REPORT_SUCCESS = "GET_DH_STOCK_REPORT_SUCCESS";
export const GET_DH_STOCK_REPORT_FAIL = "GET_DH_STOCK_REPORT_FAIL";




