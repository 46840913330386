import PropTypes from "prop-types";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import {
  Badge,
  BreadcrumbItem,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import SearchInput from "../Atoms/SearchInput";
import Pagination from "../Common/Pagination";
import CardComponent from "../Layout/CardComponent";

const InventoryBp = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataRange, setDataRange] = useState(10);
  const [totalPageNumber, setTotalPageNumber] = useState(1);
  //let totalPageNumber = Math.ceil(areaData?.length / dataRange)
  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View
    </Tooltip>
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="d-flex align-items-center justify-content-start mb-4">
                <div>
                  <h4 className="mb-0 font-size-16">BP Inventory</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0 p-0 mt-2">
                      <BreadcrumbItem>
                        <Link to="#">Inventory</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">Inventory List</Link>
                      </BreadcrumbItem>
                    </ol>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <CardComponent className="user_table_card">
              <Row className="mb-3 table-header-padding">
                <div className="d-flex ">
                  <Col className="me-2" md={2}>
                    <Select
                      name="category"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Distribution Hub"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={[1, 2, 3]}
                    />
                  </Col>
                  <Col className="me-2" md={2}>
                    <Select
                      name="category"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Category"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={[1, 2, 3]}
                    />
                  </Col>
                  <Col className="me-2" md={2}>
                    <Select
                      name="brand"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Brand"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={[1, 2, 3]}
                    />
                  </Col>
                  <Col className="me-2" md={2}>
                    <Select
                      name="product"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Product"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={[1, 2, 3]}
                    />
                  </Col>
                  <Col className="" md={2}>
                    <div className="search-box me-4 d-flex justify-content-end">
                      <SearchInput
                        className="userSearchBar form-control"
                        onChange=""
                        placeholder="Type Challan"
                      />
                    </div>
                  </Col>
                  <Col className="" style={{ marginLeft: "50px" }} md={1}>
                    <Input type="select">
                      {/* <option defaultValue>Select...</option> */}
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </Input>
                  </Col>
                </div>
              </Row>
              {/* table */}
              <Row>
                <div className="table-responsive">
                  <Table className="table table-hover mb-0 table-centered table-nowrap">
                    <thead className="table-light">
                      <tr>
                        <th>Sl. No.</th>
                        <th>BP. Name</th>
                        <th>Product Name</th>
                        <th>Brand</th>
                        <th>Category</th>
                        <th>Stock</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>

                        <td>Test</td>
                        <td>Test</td>
                        <td>Test</td>
                        <td>Test</td>
                        <td>Test</td>
                        <td>
                          {" "}
                          <Badge
                            color="warning"
                            style={{ padding: "10px !important" }}
                          >
                            active
                          </Badge>
                        </td>
                        <td>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipView}
                          >
                            <button
                              className="btn btn-secondary btn-sm me-2"
                              style={{ borderRadius: "10px" }}
                            >
                              <i className="bx bx-show mt-1"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Pagination
                    parentClass={`paginationContainer text-right py-3`}
                    reInitialize="reInitialize"
                    page={totalPageNumber}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
                {/* table end */}
              </Row>
            </CardComponent>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

InventoryBp.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(InventoryBp);
