import {
    GET_PRODUCT_STOCK_SUCCESS,
    GET_PRODUCT_STOCK_FAIL,
    DELETE_STOCK_SUCCESS,
    DELETE_STOCK_FAIL
 

  } from './actionTypes'
  const INIT_STATE = {
    productStock: [],
    productStockLoading: true
  
  }
  
  
  const StokeInReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_PRODUCT_STOCK_SUCCESS:
          return {
            ...state,
            productStock: action.payload,
            productStockLoading: false,
          
          }
        case GET_PRODUCT_STOCK_FAIL:
          return {
            ...state,
    
            error: action.payload,
            productStockLoading: false,
    
          }
          case DELETE_STOCK_SUCCESS:
            return {
              ...state,
              productStock: action.payload,
              productStockLoading: false,
            
            }
          case DELETE_STOCK_FAIL:
            return {
              ...state,
      
              error: action.payload,
              productStockLoading: false,
      
            }

    
      default:
        return state
    }
  }
  
  export default StokeInReducer