import { getData, postData, updateData } from "../../helpers/api_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"
import { toaster } from '../../helpers/custom/toaster';

import {
    getDistributionHubSuccess,
    getDistributionHubFail,
    getInvOptionSuccess,
    getInvOptionFail,
    getSingleDistributionSuccess,
    getSingleDistributionFail,
    getSingleDistributionHubSuccess,
    getSingleDistributionHubFail,
 
    storeDistributionHub
} from "./actions"
import {
    ADD_DISTRIBUTION_HUB,
    GET_DISTRIBUTION_HUB,
    GET_INV_OPTION,
    GET_SINGLE_DISTRIBUTION,
    GET_SINGLE_DISTRIBUTION_HUB

} from "./actionTypes"

function* fetchDistributionHub({ payload: { authtoken, currentPage, pageRange, hubType, value } }) {
  
  try {
    const url = `/warehouse/${pageRange}/${currentPage}?warehouseType=${hubType || ""}&name=${value || ""}`
    const response = yield call(getData, url, authtoken)

    yield put(getDistributionHubSuccess(response))
  } catch (error) {
    yield put(getDistributionHubFail(error))
  }
}
function* fetchSingleDistribution({ payload: { authtoken, currentPage, pageRange, hubType } }) {
  
  try {
    const url = `/warehouse/${pageRange}/${currentPage}?warehouseType=${hubType}`
    const response = yield call(getData, url, authtoken)

    yield put(getSingleDistributionSuccess(response))
  } catch (error) {
    yield put(getSingleDistributionFail(error))
  }
}

function* fetchSingleDistributionHub({ payload: {  authtoken, id } }) {
  
  try {
    const url = `/warehouse/${id}`
    const response = yield call(getData, url, authtoken)

    yield put(getSingleDistributionHubSuccess(response))
  } catch (error) {
    yield put(getSingleDistributionHubFail(error))
  }
}

function* fetchInventoryOp({ payload: { authtoken } }) {
  
  try {
    const url = `/inventoryoperation`
    const response = yield call(getData, url, authtoken)

    yield put(getInvOptionSuccess(response))
  } catch (error) {
    yield put(getInvOptionFail(error))
  }
}

  function* addDistributionData({ payload: { data, history, authtoken, id } }) {
    try {
      let response
      if (id) {
        const url = `/warehouse/${id}`
        response = yield call(updateData, url, data, authtoken)
        yield put(storeDistributionHub('hubLoading', false));
        toaster("success", "Warehouse Update successfully!")
      } else {
        const url = "/warehouse"
        response = yield call(postData, url, data, authtoken)
        yield put(storeDistributionHub('hubLoading', false));
        toaster("success", "Warehouse added successfully!")
      }
      yield put(storeDistributionHub("addDistributionHubData", response))
      yield put(storeDistributionHub("addDistributionHubDataLoading", false))

      history.push("/hub")
    } catch (error) {
      console.log(error.response)
    }
  }


function* DistributionSaga() {
    yield takeEvery(GET_DISTRIBUTION_HUB, fetchDistributionHub)
    yield takeEvery(ADD_DISTRIBUTION_HUB, addDistributionData)
    yield takeEvery(GET_INV_OPTION, fetchInventoryOp)
    yield takeEvery(GET_SINGLE_DISTRIBUTION, fetchSingleDistribution)
    yield takeEvery(GET_SINGLE_DISTRIBUTION_HUB, fetchSingleDistributionHub)

 
 
  }
  
  export default DistributionSaga