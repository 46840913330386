import * as Yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const addChallanSchema = Yup.object().shape({
  distributionHubId: Yup.string().required('This value is required'),
  // InventoryOperation: Yup.string().required('This value is required'),
  // photo: Yup.string().required('This value is required'),
  deliveryFrom: Yup.string().required('This value is required'),
  centralWarehouseId: Yup.string().when('deliveryFrom', {
    is: 'Warehouse',
    then: Yup.string().required('This value is required'),
  }),
  vendorId: Yup.string().when('deliveryFrom', {
    is: 'Vendor',
    then: Yup.string().required('This value is required'),
  }),
  chalanCreatorPhone: Yup.string().min(11,"Number minimum 11 character required").notOneOf([Yup.ref("chalanGiverPhone"),("chalanCreatorPhone"),null],"Phone number is not unique").matches(phoneRegExp, 'Phone number is not valid'),
  chalanGiverPhone: Yup.string().min(11,"Number is minimum 11 character required").notOneOf([Yup.ref("chalanCreatorPhone"),("chalanReceiverPhoneNumber"),null],"Phone number is not unique").matches(phoneRegExp, 'Phone number is not valid'),
  chalanReceiverPhoneNumber: Yup.string().min(11,"Number minimum 11 character required").notOneOf([Yup.ref("chalanCreatorPhone"),("chalanGiverPhone"),null],"Phone number is not unique").matches(phoneRegExp, 'Phone number is not valid'),
});

