export const GET_AREA = 'GET_AREA';
export const GET_AREA_SUCCESS = 'GET_AREA_SUCCESS';
export const GET_AREA_FAIL = 'GET_AREA_FAIL';

export const GET_SINGLE_AREA = 'GET_SINGLE_AREA';
export const GET_SINGLE_AREA_SUCCESS = 'GET_SINGLE_AREA_SUCCESS';
export const GET_SINGLE_AREA_FAIL = 'GET_SINGLE_AREA_FAIL';

export const GET_REGION = 'GET_REGION';
export const GET_REGION_SUCCESS = 'GET_REGION_SUCCESS';
export const GET_REGION_FAIL = 'GET_REGION_FAIL';

export const GET_SINGLE_REGION = 'GET_SINGLE_REGION';
export const GET_SINGLE_REGION_SUCCESS = 'GET_SINGLE_REGION_SUCCESS';
export const GET_SINGLE_REGION_FAIL = 'GET_SINGLE_REGION_FAIL';

export const GET_TERRITORY = 'GET_TERRITORY';
export const GET_TERRITORY_SUCCESS = 'GET_TERRITORY_SUCCESS';
export const GET_TERRITORY_FAIL = 'GET_TERRITORY_FAIL';

export const GET_SINGLE_TERRITORY = 'GET_SINGLE_TERRITORY';
export const GET_SINGLE_TERRITORY_SUCCESS = 'GET_SINGLE_TERRITORY_SUCCESS';
export const GET_SINGLE_TERRITORY_FAIL = 'GET_SINGLE_TERRITORY_FAIL';
// add issue
export const ADD_AREA = 'ADD_AREA';
export const ADD_AREA_SUCCESS = 'ADD_AREA_SUCCESS';
export const ADD_AREA_FAIL = 'ADD_AREA_FAIL';

export const ADD_REGION = 'ADD_REGION';
export const ADD_REGION_SUCCESS = 'ADD_REGION_SUCCESS';
export const ADD_REGION_FAIL = 'ADD_REGION_FAIL';

export const ADD_TERRITORY = 'ADD_TERRITORY';
export const ADD_TERRITORY_SUCCESS = 'ADD_TERRITORY_SUCCESS';
export const ADD_TERRITORY_FAIL = 'ADD_TERRITORY_FAIL';

export const UPLOAD_EXCEL_DATA = 'UPLOAD_EXCEL_DATA';
export const UPLOAD_EXCEL_DATA_SUCCESS = 'UPLOAD_EXCEL_DATA_SUCCESS';
export const UPLOAD_EXCEL_DATA_FAIL = 'UPLOAD_EXCEL_DATA_FAIL';

export const STORE_GEO_DATA = 'STORE_GEO_DATA';
