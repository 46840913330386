import {
  ADD_RECONCILIATION,
  ADD_RECONCILIATION_FAIL,
  ADD_RECONCILIATION_SUCCESS,
  GET_RECONCILIATION,
  GET_RECONCILIATION_FAIL,
  GET_RECONCILIATION_SUCCESS,
  STORE_RECONCILIATION_DATA
} from './actionTypes';

export const getReconciliation = (authtoken, startDate, bp) => ({
  type: GET_RECONCILIATION,
  payload: { authtoken, startDate, bp },
});

export const getReconciliationSuccess = (data) => ({
  type: GET_RECONCILIATION_SUCCESS,
  payload: data,
});

export const getReconciliationFail = (error) => ({
  type: GET_RECONCILIATION_FAIL,
  payload: error,
});

export const addReconciliation = (authtoken, value, history) => ({
  type: ADD_RECONCILIATION,
  payload: { authtoken, value, history },
});

export const addReconciliationSuccess = (data) => ({
  type: ADD_RECONCILIATION_SUCCESS,
  payload: data,
});

export const addReconciliationFail = (error) => ({
  type: ADD_RECONCILIATION_FAIL,
  payload: error,
});

export const storeReconciliationData = (name, data) => ({
  type: STORE_RECONCILIATION_DATA,
  payload: { name, data },
});
