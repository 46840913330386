import {
    GET_PRODUCT_STOCK,
    GET_PRODUCT_STOCK_SUCCESS,
    GET_PRODUCT_STOCK_FAIL,
    DELETE_STOCK,
    DELETE_STOCK_SUCCESS,
    DELETE_STOCK_FAIL,
    GET_CHALLAN_STOCK_LIST,
    GET_CHALLAN_STOCK_LIST_SUCCESS,
    GET_CHALLAN_STOCK_LIST_FAIL
  

  } from "./actionTypes";
  
  export const getProductStock = (authtoken, startDate, endDate, wareHouse, currentPage, pageRange ) => ({
    type: GET_PRODUCT_STOCK,
    payload: { authtoken, startDate, endDate, wareHouse, currentPage, pageRange },
  });
  
  export const getProductStockSuccess = (data) => ({
    type: GET_PRODUCT_STOCK_SUCCESS,
    payload: { data },
  });
  export const getProductStockFail = (error) => ({
    type: GET_PRODUCT_STOCK_FAIL,
    payload: error,
  });



  export const deleteStock = (authtoken, id, body ) => ({
    type: DELETE_STOCK,
    payload: { authtoken, id, body },
  });
  
  export const deleteStockSuccess = (data) => ({
    type: DELETE_STOCK_SUCCESS,
    payload: { data },
  });
  export const deleteStockFail = (error) => ({
    type: DELETE_STOCK_FAIL,
    payload: error,
  });