import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import addCircle from "../../assests/images/add-circle.png";
import closeCircle from "../../assests/images/close-circle.png";
import { toaster } from "../../helpers/custom/toaster";
import {
  addChallanData,
  getChallan,
  getChallanDetails,
  getChallanStock,
  storeChallanData,
} from "../../store/Challan/action";
import { getCSDistributionInventory } from "../../store/CurrentStock/actions";
import {
  getDistributionHub,
  getInvOption,
  getSingleDistribution,
} from "../../store/Distribution Hub/actions.js";
import { getBrand } from "../../store/Info/actions";
import { getProduct } from "../../store/Product/actions";
import CustomInput from "../Common/CustomInput";
import FileUpload from "../Common/FileUpload";
import AddCardComponent from "../Layout/AddCardComponent";
import InnerLayer from "../Layout/InnerLayer";
import { addChallanSchema } from "../Schemas/AddChallan.schema";

const breadcrumbItems = [
  {
    title: "Challan",
    link: "#",
  },
  {
    title: "Challan Create",
    link: "#",
  },
];
const initialValues = {
  distributionHubId: "",
  InventoryOperation: "",
  deliveryFrom: "",
  centralWarehouseId: "",
  vendorId: "",
  chalancreatedBy: "Musharof",
  chalanCreatorPhone: "01616787862",
  chalanGiverName: "",
  chalanGiverPhone: "",
  warhouseAddress: "",
  distributionWarehouseIdfrom: "",
  photo: "",
  // note: ""
};
const deliveryFrom = [
  {
    name: "Warehouse",
    _id: 1,
    value: "Warehouse",
  },
  {
    name: "Vendor",
    _id: 2,
    value: "Vendor",
  },
  {
    name: "Hub to Hub",
    _id: 3,
    value: "HubtoHub",
  },
];
const SampleAddChallan = () => {
  const history = useHistory();
  let dispatch = useDispatch();
  const [detailShow, setDetailShow] = useState(false);

  const [imageUp, setImageUp] = useState("");
  const [displayImage, setDisplayImage] = useState("");
  const [settlementDh, setSettlementDh] = useState("");
  // const [deliveryfrom, setDeliveryFrom] = useState('');
  const [selectedFiles, setSelectedFiles] = useState({});
  const [challanNo, setChallanNo] = useState("");
  const [settleIssue, setSettleIssue] = useState([]);
  const [imageShow, setImageShow] = useState(false);
  console.log(`AddChallan ~ selectedFiles`, selectedFiles);
  const [challanInfo, setChallanInfo] = useState({
    itemId: "",
    quantity: "",
    name: "",
  });
  const [singleChallan, setsingleChallan] = useState([]);
  const addedStock = singleChallan.map((data) => data?.itemId);
  console.log("singleChallan", singleChallan);
  const {
    role,
    products,
    authtoken,
    // productLoading,
    inventoryOperation,
    distributionHubData,
    brandData,
    singleDistribution,
    productList: productData,
    stokeList,
    challanListStock,
    // userId,
    addChallanLoading,
    challanData,
    challanDetailsInfo,
    callanRecieved,
    csDistributionInventory,
    csDistributionInventoryLoading,
    challanDetailsLoading,
    challanDataLoading,
  } = useSelector((state) => ({
    inventoryOperation: state.DistributionReducer.inventoryOperation,
    products: state.ProductReducer.products,
    brandData: state.InfoReducer.brandData,
    singleDistribution: state.DistributionReducer.singleDistribution,
    role: state.Login.userrole,
    productLoading: state.ProductReducer.productLoading,
    authtoken: state.Login.token,
    distributionHubData: state.DistributionReducer.distributionHubData,
    productList: state.ProductReducer.productList,
    stokeList: state.ChallanReducer.stokeList,
    challanListStock: state.ChallanReducer.challanListStock,
    addChallanLoading: state.ChallanReducer.addChallanLoading,
    userId: state.Login.warehouse,
    challanData: state.ChallanReducer.challanData,
    challanDataLoading: state.ChallanReducer.challanDataLoading,
    challanDetailsInfo: state.ChallanReducer.challanDetailsInfo,
    callanRecieved: state.ChallanReducer.callanRecieved,
    csDistributionInventory: state.CurrentStockReducer.csDistributionInventory,
    csDistributionInventoryLoading:
      state.CurrentStockReducer.csDistributionInventoryLoading,
    challanDetailsLoading: state.ChallanReducer.challanDetailsLoading,
  }));


  useEffect(() => {
    dispatch(getInvOption(authtoken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getDistributionHub(authtoken, 0, 0, "Central", ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, "Hub", ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(getChallan(authtoken, 1000, 1, settlementDh, "", "", "NO"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settlementDh]);
  useEffect(() => {
    dispatch(getBrand(authtoken, 0, 0, ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values) => {
    let body;
    console.log("settleIssue", settleIssue);
    if (values?.deliveryFrom === "Warehouse") {
      const {
        centralWarehouseId,
        vendorId,
        deliveryFrom,
        InventoryOperation,
        distributionWarehouseIdfrom,
        ...rest
      } = values;

      if (role !== "CENTRALWAREHOUSE") {
        body = {
          deliveryFrom,
          InventoryOperation,
          ...rest,
          productList: singleChallan,
          settlementproductList: settleIssue,
          image: values?.photo,
        };
      } else {
        body = {
          deliveryFrom,
          ...rest,
          productList: singleChallan,
          settlementproductList: settleIssue,
          image: values?.photo,
        };
      }

      if (deliveryFrom === "Warehouse") {
        body.centralWarehouseId = centralWarehouseId;
      } else {
        body.vendorId = vendorId;
      }
      if (settleIssue.length > 0) {
        body.settlement = true;
        body.settlementChallan = challanNo;
      }
      console.log("body", body);
      if (body?.productList.length !== 0) {
        dispatch(addChallanData(body, history, authtoken));
      } else {
        toast("Please Select Quantity", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else if (values?.deliveryFrom === "Vendor") {
      const {
        centralWarehouseId,
        vendorId,
        distributionWarehouseIdfrom,
        deliveryFrom,
        InventoryOperation,
        ...rest
      } = values;
      let body;
      if (role !== "CENTRALWAREHOUSE") {
        body = {
          deliveryFrom,
          InventoryOperation,
          ...rest,
          productList: singleChallan,
          image: values?.photo,
          settlementproductList: settleIssue,
        };
      } else {
        body = {
          deliveryFrom,
          ...rest,
          productList: singleChallan,
          image: values?.photo,
          settlementproductList: settleIssue,
        };
      }

      if (deliveryFrom === "Warehouse") {
        body.centralWarehouseId = centralWarehouseId;
      } else {
        body.vendorId = vendorId;
      }
      if (settleIssue.length > 0) {
        body.settlement = true;
        body.settlementChallan = challanNo;
      }
      console.log("body", body);
      if (body?.productList.length !== 0) {
        dispatch(addChallanData(body, history, authtoken));
      } else {
        toast("Please Select Quantity", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      console.log("hello");
      const {
        centralWarehouseId,
        distributionWarehouseIdfrom,
        vendorId,
        deliveryFrom,
        InventoryOperation,
        ...rest
      } = values;
      let body;
      if (role !== "CENTRALWAREHOUSE") {
        body = {
          deliveryFrom,
          InventoryOperation,
          ...rest,
          productList: singleChallan,
          image: values?.photo,
          settlementproductList: settleIssue,
        };
      } else {
        body = {
          deliveryFrom,
          ...rest,
          productList: singleChallan,
          image: values?.photo,
          settlementproductList: settleIssue,
        };
      }

      body.distributionWarehouseIdfrom = distributionWarehouseIdfrom;
      if (settleIssue.length > 0) {
        body.settlement = true;
        body.settlementChallan = challanNo;
      }
      if (body?.productList.length !== 0) {
        dispatch(addChallanData(body, history, authtoken));
      } else {
        toast("Please Select Quantity", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    // })
  };

  const handleAddProductInput = () => {
    if (challanInfo.itemId === "" || challanInfo.quantity === "") {
      return toaster("warning", "Please fill all the fields");
    }
    if (challanInfo.quantity <= 0) {
      return toaster("warning", "Quantity Can not be negative or zero");
    }
    if (challanInfo.quantity > challanInfo.stock) {
      return toaster("warning", "Quantity can not be bigger than Stock");
    }
    let data = {
      itemId: challanInfo?.itemId,
      quantity: challanInfo?.quantity * 1,
      name: challanInfo?.name,
      stock: challanInfo?.stock,
    };

    setsingleChallan([...singleChallan, data]);
    setChallanInfo({
      itemId: "",
      quantity: "",
    });
  };
  
  const handleRemoveProduct = (data) => {
    const removeProd = singleChallan.filter(
      (prod) => data?.itemId !== prod?.itemId
    );
    console.log("removeProd", removeProd);
    setsingleChallan(removeProd);
  };

  const AddChallan = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: addChallanSchema,
    onSubmit,
    // handleDelivery,
  });

  const handleDistribution = (data) => {
    console.log("lll", data);
    if (!!data?._id) {
      dispatch(getChallanStock(authtoken, data?._id));
    } else {
      dispatch(getChallanStock({}));
    }
  };
  const handleVendor = (data) => {
    if (!!data?._id) {
      dispatch(getProduct(authtoken, data?._id));
    } else {
      dispatch(getProduct({}));
    }
  };
  const handlefromDistribution = (data) => {
    if (!!data?._id) {
      dispatch(
        getCSDistributionInventory(authtoken, "", "", data?._id, 1000, 1)
      );
    } else {
      dispatch(getCSDistributionInventory({}));
    }
  };
  const handleChallanInput = (e) => {
    setChallanInfo({
      ...challanInfo,
      quantity: e.target.value,
    });
  };
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      };
    },
  };
  const handleSettlementDetails = (data) => {
    setDetailShow(true);
    dispatch(storeChallanData("challanDetailsInfo", []));
    dispatch(getChallan(authtoken, 1000, 1, settlementDh, "", "", "NO"));
    dispatch(getChallanDetails(authtoken, data._id));
  };
 
  const handleChallan = (value) => {
    if (value) {
      setChallanNo(value?._id);
      dispatch(getChallanDetails(authtoken, value?._id));
    } else {
      setChallanNo("");
    }
  };
  const handleSubmitQtyEdit = (e, idx, data) => {
    const oldValue = [...challanDetailsInfo];
    console.log("oldValue", oldValue);
    // if (e.target.value * 1 <= oldValue[idx].quantity) {
    // oldValue[idx].receivedQty = e.target.value
    oldValue[idx].setteleQty = e.target.value;

    dispatch(storeChallanData("challanDetailsInfo", oldValue));
   
  };
  const handleSettlementSubmit = () => {
   
    var objList = {};
    var dynamicProd = [];
    challanDetailsInfo.map((data) => {
      if (data?.setteleQty * 1 > 0) {
        objList.id = data?._id;
        objList.productId = data?.itemId?._id;
        objList.productName = data?.itemId?.name;
        objList.quantity = data?.quantity;
        objList.setteleQty = data?.setteleQty * 1;
        let dataList = { ...objList };
        dynamicProd.push(dataList);
      }
    });
    const settlementDataArray = dynamicProd.map((data) => ({
      id: data?.id,
      productId: data?.productId,
      setteleQty: data?.setteleQty,
      productName: data?.productName,
      quantity: data?.quantity,
    }));
    
    setSettleIssue(settlementDataArray);
    setDetailShow(false);
  };
  const handleSettleDistribution = (data) => {
    if (data?._id) {
      setSettlementDh(data?._id);
    } else {
      setSettlementDh("");
    }
  };

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Create Challan"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="11" className="mx-auto">
              {/* add User */}
              <AddCardComponent className="">
                <Form
                  className="needs-validation col-md-12"
                  onSubmit={AddChallan.handleSubmit}
                >
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md="12 mb-2">
                          <Label htmlFor="formrow-firstname-Input">
                            Select Distribution Hub
                          </Label>
                          <Select
                            name="distributionHubId"
                            className="mb-2"
                            classNamePrefix="select2-selection"
                            placeholder="Select Distribution Hub"
                            cacheOptions
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.value}
                            isClearable
                            options={singleDistribution?.data?.Warehouse}
                            onChange={(e) => {
                              AddChallan.setFieldValue(
                                "distributionHubId",
                                e?._id || ""
                              );
                              handleSettleDistribution(e);
                            }}
                          />
                          {AddChallan.errors.distributionHubId ? (
                            <p className="text-danger">
                              {AddChallan.errors.distributionHubId}
                            </p>
                          ) : null}
                        </Col>
                        <Col md="12 mb-2">
                          <CustomInput
                            name={"deliveryFrom"}
                            type={"select"}
                            label={"Delivery Source"}
                            validationType={AddChallan}
                            //  onChange={(e)=> handleDelivery(e)}
                          >
                            <option defaultValue>
                              Select Delivery Source...
                            </option>
                            {deliveryFrom?.length > 0 ? (
                              deliveryFrom?.map((data, idx) => (
                                <option value={data?.value} key={idx}>
                                  {data.name}
                                </option>
                              ))
                            ) : (
                              <span></span>
                            )}
                          </CustomInput>
                        </Col>
                        {AddChallan?.values?.deliveryFrom === "Warehouse" ? (
                          <Col md="12 mb-2">
                            <Label htmlFor="formrow-firstname-Input">
                              Select Warehouse
                            </Label>
                            <Select
                              name="centralWarehouseId"
                              className="mb-2"
                              classNamePrefix="select2-selection"
                              placeholder="Select Warehouse"
                              cacheOptions
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.value}
                              isClearable
                              options={distributionHubData?.data?.Warehouse}
                              onChange={(e) => {
                                AddChallan.setFieldValue(
                                  "centralWarehouseId",
                                  e?._id || ""
                                );
                                handleDistribution(e);
                              }}
                            />
                            {AddChallan.errors.centralWarehouseId ? (
                              <p className="text-danger">
                                {AddChallan.errors.centralWarehouseId}
                              </p>
                            ) : null}
                          </Col>
                        ) : (
                          <span></span>
                        )}
                        {AddChallan?.values?.deliveryFrom === "Vendor" ? (
                          <Col md="12 mb-2">
                            <Label htmlFor="formrow-firstname-Input">
                              Select Vendor
                            </Label>
                            <Select
                              name="vendorId"
                              className="mb-2"
                              classNamePrefix="select2-selection"
                              placeholder="Select Vendor"
                              cacheOptions
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.value}
                              isClearable
                              options={brandData?.data?.brand}
                              onChange={(e) => {
                                AddChallan.setFieldValue(
                                  "vendorId",
                                  e?._id || ""
                                );
                                handleVendor(e);
                              }}
                            />
                            {AddChallan.errors.vendorId ? (
                              <p className="text-danger">
                                {AddChallan.errors.vendorId}
                              </p>
                            ) : null}
                          </Col>
                        ) : (
                          <span></span>
                        )}
                        {AddChallan?.values?.deliveryFrom === "HubtoHub" ? (
                          <Col md="12 mb-2">
                            <Label htmlFor="formrow-firstname-Input">
                              Select from Hub
                            </Label>
                            <Select
                              name="distributionWarehouseIdfrom"
                              className="mb-2"
                              classNamePrefix="select2-selection"
                              placeholder="Select Hub"
                              cacheOptions
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.value}
                              isClearable
                              options={singleDistribution?.data?.Warehouse}
                              onChange={(e) => {
                                AddChallan.setFieldValue(
                                  "distributionWarehouseIdfrom",
                                  e?._id || ""
                                );
                                handlefromDistribution(e);
                              }}
                            />
                            {AddChallan.errors.distributionWarehouseIdfrom ? (
                              <p className="text-danger">
                                {AddChallan.errors.distributionWarehouseIdfrom}
                              </p>
                            ) : null}
                          </Col>
                        ) : (
                          <span></span>
                        )}
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        {role !== "CENTRALWAREHOUSE" ? (
                          <Col md="12 mb-2">
                            <CustomInput
                              name={"InventoryOperation"}
                              type={"select"}
                              label={"Select Inventory  Option"}
                              validationType={AddChallan}
                            >
                              <option defaultValue>
                                Select Inventory Option...
                              </option>
                              {inventoryOperation?.data?.length > 0 ? (
                                inventoryOperation?.data?.operation?.map(
                                  (data, idx) => (
                                    <option value={data._id} key={idx}>
                                      {data?.operationName}
                                    </option>
                                  )
                                )
                              ) : (
                                <span></span>
                              )}
                            </CustomInput>
                          </Col>
                        ) : (
                          <span></span>
                        )}
                        {/* <Col md="12 mb-2">
                          <CustomInput
                            name={"note"}
                            type={"textarea"}
                            label={"Note"}
                            placeholder={"Type..."}
                            validationType={AddChallan}
                            style={{ height: "80px" }}
                          />
                        </Col> */}
                      </Row>
                    </Col>
                    <Row>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalancreatedBy"}
                          type={"text"}
                          label={"Challan Creator Name"}
                          placeholder={"Type Creator Name"}
                          validationType={AddChallan}
                          disabled
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalanCreatorPhone"}
                          type={"text"}
                          label={"Challan Creator Phone"}
                          placeholder={"Type Creator Phone Number"}
                          validationType={AddChallan}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalanGiverName"}
                          type={"text"}
                          label={"Bp Name"}
                          placeholder={"Type Bp Name"}
                          validationType={AddChallan}
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalanGiverPhone"}
                          type={"text"}
                          label={"Bp Phone Number"}
                          placeholder={"Type Bp Phone Number"}
                          validationType={AddChallan}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalanReceiverName"}
                          type={"text"}
                          label={"Hub Manager Name"}
                          placeholder={"Type Hub manager Name"}
                          validationType={AddChallan}
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalanReceiverPhoneNumber"}
                          type={"text"}
                          label={"Hub Manager Phone Number"}
                          placeholder={"Type Manager Phone Number"}
                          validationType={AddChallan}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"warhouseAddress"}
                          type={"text"}
                          label={"Warehouse Address"}
                          placeholder={"Type Address"}
                          validationType={AddChallan}
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"note"}
                          type={"textarea"}
                          label={"Note"}
                          placeholder={"Type..."}
                          validationType={AddChallan}
                          style={{ height: "80px" }}
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <FileUpload
                          name="photo"
                          label={"Upload Image"}
                          type="file"
                          // isRequired
                          validationType={AddChallan}
                        />
                        {/* <Field
                        name="image"
                        label={"Upload Image"}
                        type="file"
                        component={FileUpload}
                        isRequired
                        isFull
                      /> */}
                        {/* <Input
                          name={"photo"}
                          type="file"
                          label={"Image"}
                          onChange={e => handlePhoto(e)}
                          // validationType={AddChallan}
                        /> */}
                      </Col>
                    </Row>
                  </Row>
                  {AddChallan?.values?.deliveryFrom === "Warehouse" ? (
                    <div>
                      <Row>
                        <div
                        // className="table-responsive"
                        // style={{ minHeight: "600px" }}
                        >
                          <h6 className="mt-3 mb-4 fw-bold">
                            Add New Product in challan
                          </h6>
                          <Table className="table table-hover mb-0 table-centered  mb-5">
                            <thead className="table-light">
                              <tr>
                                <th>No.</th>
                                <th>Select Product</th>
                                <th>Input QTY</th>
                                <th>Stock</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="challan-list">
                                <td>1</td>

                                <td
                                  style={{
                                    width: "300px",
                                  }}
                                >
                                  <Select
                                    name=""
                                    // className="mb-2"
                                    classNamePrefix="select2-selection"
                                    placeholder="Select Product"
                                    styles={colourStyles}
                                    cacheOptions
                                    getOptionLabel={(e) => e.name}
                                    getOptionValue={(e) => e.value}
                                    value={
                                      challanListStock?.find(
                                        (data) =>
                                          challanInfo?.itemId === data?._id
                                      ) || {}
                                    }
                                    isClearable
                                    options={challanListStock.filter(
                                      (data) => !addedStock.includes(data?._id)
                                    )}
                                    onChange={(e) => {
                                      setChallanInfo({
                                        ...challanInfo,
                                        itemId: e?._id || "",
                                        name: e?.name,
                                        stock: e?.stockData?.stock,
                                      });
                                    }}
                                  />
                                </td>

                                <td>
                                  <Input
                                    type="number"
                                    style={{
                                      width: "100px",
                                      height: "38px",
                                    }}
                                    value={challanInfo?.quantity}
                                    onChange={(e) => {
                                      handleChallanInput(e);
                                    }}
                                  ></Input>
                                </td>
                                <td>{challanInfo?.stock}</td>
                                <td>
                                  {addChallanLoading ? (
                                    "Adding..."
                                  ) : (
                                    <img
                                      src={addCircle}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      className="img-fluid"
                                      onClick={() =>
                                        addChallanLoading
                                          ? {}
                                          : handleAddProductInput()
                                      }
                                    />
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                      <Row>
                        <div className="table-responsive">
                          <h6 className="mb-3 fw-bold">
                            Added Product in challan
                          </h6>
                          <Table className="table table-hover mb-0 table-centered  mb-5">
                            <thead className="table-light">
                              <tr>
                                <th>No.</th>
                                <th>Product Name</th>

                                <th>QTY</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {false ? (
                                <tr>
                                  <td colSpan="5">
                                    <h6 className="text-center">Loading...</h6>
                                  </td>
                                </tr>
                              ) : (
                                (singleChallan || []).map((data, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>

                                      <td>{data?.name}</td>
                                      <td>{data?.quantity}</td>
                                      <td>
                                        <img
                                          src={closeCircle}
                                          alt=""
                                          style={{ cursor: "pointer" }}
                                          className="img-fluid"
                                          onClick={() =>
                                            handleRemoveProduct(data)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                              <tr>
                                <td></td>

                                <td colSpan="1">
                                  {" "}
                                  <h6 className="text-end">Total Quantity:</h6>
                                </td>

                                <td>
                                  <h6>
                                    {" "}
                                    {singleChallan?.reduce(
                                      (total, item) =>
                                        total + item.quantity * 1,
                                      0
                                    )}
                                  </h6>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          {settleIssue.length > 0 ? (
                            <div className="table-responsive">
                              <h6 className="fw-bold">
                                Settlement Product List
                              </h6>
                              <Table className="table table-hover mb-0 table-centered  mb-5">
                                <thead className="table-light">
                                  <tr>
                                    <th>No.</th>

                                    <th>Product</th>

                                    <th>Received QTY</th>

                                    <th>Settle QTY</th>
                                  </tr>
                                </thead>
                                {(settleIssue || []).map((data, key) => {
                                  return (
                                    <>
                                      <tbody>
                                        <tr>
                                          <td>{key + 1}</td>
                                          <td>{data?.productName}</td>
                                          <td>{data?.quantity}</td>
                                          <td>{data?.setteleQty}</td>
                                        </tr>
                                      </tbody>
                                    </>
                                  );
                                })}
                              </Table>
                            </div>
                          ) : (
                            <span></span>
                          )}

                          <div className="d-flex justify-content-between">
                            <div>
                              <Button onClick={() => handleSettlementDetails()}>
                                Settlement
                              </Button>
                            </div>
                            {addChallanLoading ? (
                              <div className="d-flex justify-content-end mt-3">
                                <Spinner className="ms-2" color="primary" />
                              </div>
                            ) : (
                              <div className="">
                                <input
                                  type="submit"
                                  value={"Add Challan"}
                                  className="btn button "
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Row>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {AddChallan?.values?.deliveryFrom === "Vendor" ? (
                    <div>
                      <Row>
                        <div
                        // className="table-responsive"
                        >
                          <h6 className="mt-3 mb-4 fw-bold">
                            Add New Product in challan
                          </h6>
                          <Table className="table table-hover mb-0 table-centered  mb-5">
                            <thead className="table-light">
                              <tr>
                                <th>No.</th>
                                <th>Product</th>
                                <th>QTY</th>
                                <th>Stock</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="challan-list">
                                <td>1</td>

                                <td
                                  style={{
                                    width: "300px",
                                  }}
                                >
                                  <Select
                                    name=""
                                    // className="mb-2"
                                    classNamePrefix="select2-selection"
                                    placeholder="Select Product"
                                    styles={colourStyles}
                                    cacheOptions
                                    getOptionLabel={(e) => e.name}
                                    getOptionValue={(e) => e.value}
                                    value={
                                      productData?.find(
                                        (data) =>
                                          challanInfo?.itemId === data?._id
                                      ) || {}
                                    }
                                    isClearable
                                    options={productData.filter(
                                      (data) => !addedStock.includes(data?._id)
                                    )}
                                    onChange={(e) => {
                                      setChallanInfo({
                                        ...challanInfo,
                                        itemId: e?._id || "",
                                        name: e?.name,
                                        stock: e?.stockData?.stock,
                                      });
                                    }}
                                  />
                                </td>

                                <td>
                                  <Input
                                    type="number"
                                    style={{
                                      width: "100px",
                                      height: "38px",
                                    }}
                                    value={challanInfo?.quantity}
                                    onChange={(e) => {
                                      handleChallanInput(e);
                                    }}
                                  ></Input>
                                </td>
                                <td>{challanInfo?.stock}</td>
                                <td>
                                  {addChallanLoading ? (
                                    "Adding..."
                                  ) : (
                                    <img
                                      src={addCircle}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      className="img-fluid"
                                      onClick={() =>
                                        addChallanLoading
                                          ? {}
                                          : handleAddProductInput()
                                      }
                                    />
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                      <Row>
                        <div className="table-responsive">
                          <h6 className="mb-3 fw-bold">
                            Added Product in challan
                          </h6>
                          <Table className="table table-hover mb-0 table-centered  mb-5">
                            <thead className="table-light">
                              <tr>
                                <th>No.</th>
                                <th>Category</th>

                                <th>QTY</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {false ? (
                                <tr>
                                  <td colSpan="5">
                                    <h6 className="text-center">Loading...</h6>
                                  </td>
                                </tr>
                              ) : (
                                (singleChallan || []).map((data, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>

                                      <td>{data?.name}</td>
                                      <td>{data?.quantity}</td>
                                      <td>
                                        <img
                                          src={closeCircle}
                                          alt=""
                                          style={{ cursor: "pointer" }}
                                          className="img-fluid"
                                          onClick={() =>
                                            handleRemoveProduct(data)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                              <tr>
                                <td></td>

                                <td colSpan="1">
                                  {" "}
                                  <h6 className="text-end">Total Quantity:</h6>
                                </td>

                                <td>
                                  <h6>
                                    {" "}
                                    {singleChallan?.reduce(
                                      (total, item) =>
                                        total + item.quantity * 1,
                                      0
                                    )}
                                  </h6>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <div className="table-responsive">
                            <h6 className="fw-bold">Settlement Product List</h6>
                            <Table className="table table-hover mb-0 table-centered  mb-5">
                              <thead className="table-light">
                                <tr>
                                  <th>No.</th>

                                  <th>Product</th>

                                  <th>Received QTY</th>
                                  <th>Settle QTY</th>
                                </tr>
                              </thead>
                              {(settleIssue || []).map((data, key) => {
                                return (
                                  <>
                                    <tbody>
                                      <tr>
                                        <td>{key + 1}</td>
                                        <td>{data?.productName}</td>
                                        <td>{data?.quantity}</td>
                                        <td>{data?.setteleQty}</td>
                                      </tr>
                                    </tbody>
                                  </>
                                );
                              })}
                            </Table>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <Button onClick={() => handleSettlementDetails()}>
                                Settlement
                              </Button>
                            </div>
                            {addChallanLoading ? (
                              <div className="d-flex justify-content-end mt-3">
                                <Spinner className="ms-2" color="primary" />
                              </div>
                            ) : (
                              <div className="">
                                <input
                                  type="submit"
                                  value={"Add Challan"}
                                  className="btn button "
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Row>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {AddChallan?.values?.deliveryFrom === "HubtoHub" ? (
                    <div>
                      <Row>
                        <div
                        // className="table-responsive"
                        >
                          <h6 className="mt-3 mb-4 fw-bold">
                            Add New Product in challan
                          </h6>
                          <Table className="table table-hover mb-0 table-centered  mb-5">
                            <thead className="table-light">
                              <tr>
                                <th>No.</th>
                                <th>Product</th>
                                <th>QTY</th>
                                <th>Stock</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="challan-list">
                                <td>1</td>

                                <td
                                  style={{
                                    width: "300px",
                                  }}
                                >
                                  <Select
                                    name=""
                                    // className="mb-2"
                                    classNamePrefix="select2-selection"
                                    placeholder="Select Product"
                                    styles={colourStyles}
                                    cacheOptions
                                    getOptionLabel={(e) => e?.products?.name}
                                    getOptionValue={(e) => e.value}
                                    value={
                                      csDistributionInventory?.logdata?.find(
                                        (data) =>
                                          challanInfo?.itemId === data?.itemId
                                      ) || {}
                                    }
                                    isClearable
                                    options={csDistributionInventory?.logdata?.filter(
                                      (data) =>
                                        !addedStock.includes(data?.itemId)
                                    )}
                                    onChange={(e) => {
                                      setChallanInfo({
                                        ...challanInfo,
                                        itemId: e?.itemId || "",
                                        name: e?.products?.name,
                                        stock: e?.stock,
                                      });
                                    }}
                                  />
                                </td>

                                <td>
                                  <Input
                                    type="number"
                                    style={{
                                      width: "100px",
                                      height: "38px",
                                    }}
                                    value={challanInfo?.quantity}
                                    onChange={(e) => {
                                      handleChallanInput(e);
                                    }}
                                  ></Input>
                                </td>
                                <td>{challanInfo?.stock}</td>
                                <td>
                                  {addChallanLoading ? (
                                    "Adding..."
                                  ) : (
                                    <img
                                      src={addCircle}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      className="img-fluid"
                                      onClick={() =>
                                        addChallanLoading
                                          ? {}
                                          : handleAddProductInput()
                                      }
                                    />
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                      <Row>
                        <div className="table-responsive">
                          <h6 className="mb-3 fw-bold">
                            Added Product in challan
                          </h6>
                          <Table className="table table-hover mb-0 table-centered  mb-5">
                            <thead className="table-light">
                              <tr>
                                <th>No.</th>
                                <th>Product</th>

                                <th>QTY</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {false ? (
                                <tr>
                                  <td colSpan="5">
                                    <h6 className="text-center">Loading...</h6>
                                  </td>
                                </tr>
                              ) : (
                                (singleChallan || []).map((data, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>

                                      <td>{data?.name}</td>
                                      <td>{data?.quantity}</td>
                                      <td>
                                        <img
                                          src={closeCircle}
                                          alt=""
                                          style={{ cursor: "pointer" }}
                                          className="img-fluid"
                                          onClick={() =>
                                            handleRemoveProduct(data)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                              <tr>
                                <td></td>

                                <td colSpan="1">
                                  {" "}
                                  <h6 className="text-end">Total Quantity:</h6>
                                </td>

                                <td>
                                  <h6>
                                    {" "}
                                    {singleChallan?.reduce(
                                      (total, item) =>
                                        total + item.quantity * 1,
                                      0
                                    )}
                                  </h6>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <div className="table-responsive">
                            <h6 className="fw-bold">Settlement Product List</h6>
                            <Table className="table table-hover mb-0 table-centered  mb-5">
                              <thead className="table-light">
                                <tr>
                                  <th>No.</th>

                                  <th>Product</th>

                                  <th>Received QTY</th>
                                  <th>Settle QTY</th>
                                </tr>
                              </thead>
                              {(settleIssue || []).map((data, key) => {
                                return (
                                  <>
                                    <tbody>
                                      <tr>
                                        <td>{key + 1}</td>
                                        <td>{data?.productName}</td>
                                        <td>{data?.quantity}</td>
                                        <td>{data?.setteleQty}</td>
                                      </tr>
                                    </tbody>
                                  </>
                                );
                              })}
                            </Table>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>
                              <Button onClick={() => handleSettlementDetails()}>
                                Settlement
                              </Button>
                            </div>
                            {addChallanLoading ? (
                              <div className="d-flex justify-content-end mt-3">
                                <Spinner className="ms-2" color="primary" />
                              </div>
                            ) : (
                              <div className="">
                                <input
                                  type="submit"
                                  value={"Add Challan"}
                                  className="btn button "
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Row>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </Form>
              </AddCardComponent>
              {/* <AddFileUpload setSelectedFiles={setSelectedFiles} /> */}
            </Col>
          </Row>

          <Modal
            size="xl"
            show={detailShow}
            onHide={() => setDetailShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header
              closeButton
              style={{ borderBottom: "none" }}
            ></Modal.Header>
            <Modal.Body style={{ padding: "3px 25px" }}>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4></h4>
                <h4 className="text-center" style={{ color: "#0C1D37" }}>
                  Settlement
                </h4>
                {challanDataLoading ? (
                  <Spinner />
                ) : (
                  <Select
                    name="brand"
                    classNamePrefix="select2-selection"
                    placeholder="Select challan"
                    cacheOptions
                    getOptionLabel={(e) => e.challanNo}
                    getOptionValue={(e) => e.value}
                    isClearable
                    options={challanData?.data?.challans}
                    onChange={handleChallan}
                  />
                )}
              </div>
              {challanDetailsLoading ? (
               <div className="text-center">
                 <Spinner/>
               </div>
              ) : (
                <div className="table-responsive">
                  <Table className="table table-hover mb-0 table-centered  mb-5">
                    <thead className="table-light">
                      <tr>
                        <th>No.</th>

                        <th>Product</th>

                        <th>Received QTY</th>
                        <th>Missing QTY</th>
                        <th>Damage QTY</th>
                        <th>Settle QTY</th>
                      </tr>
                    </thead>
                    {(challanDetailsInfo || []).map((data, key) => {
                      return (
                        <>
                          <tbody>
                            {data?.missingQtyInfo > 0 || data?.damageQty > 0 ? (
                              <tr>
                                <td>{key + 1}</td>
                                <td>{data?.itemId?.name}</td>
                                <td>{data?.quantity}</td>
                                <td>{data?.missingQtyInfo}</td>
                                <td>{data?.damageQty}</td>
                                <td>
                                  <Input
                                    type="text"
                                    style={{ width: "80px", height: "30px" }}
                                    onChange={(e) =>
                                      handleSubmitQtyEdit(e, key, data)
                                    }
                                  ></Input>
                                </td>
                              </tr>
                            ) : (
                              <span></span>
                            )}
                          </tbody>
                        </>
                      );
                    })}
                  </Table>
                  <div className="text-center mb-3">
                    <Button onClick={handleSettlementSubmit}>Continue</Button>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </InnerLayer>
    </React.Fragment>
  );
};

export default SampleAddChallan;
