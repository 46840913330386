import { getData, postData } from "../../helpers/api_helper"
import { call, put, takeEvery } from "redux-saga/effects"

import {
    getCSCentalInventorySuccess,
    getCSCentalInventoryFail,
    getCSDistributionInventorySuccess,
    getCSDistributionInventoryFail,
    getCSBpInventorySuccess,
    getCSBpInventoryFail,

} from "./actions"
import {
    GET_CS_CENTRAL_INVENTORY,
    GET_CS_DISTRIBUTION_INVENTORY,
    GET_CS_BP_INVENTORY,

} from "./actionTypes"

function* fetchCScentralInventory({ payload: { authtoken, category, brand, warehouse, limit, page } }) {
  try {
 
    const url = `centralstock/get-central-stock?category=${category}&brand=${brand}&limit=${limit}&pageNo=${page}&centralHub=${warehouse}`
    const response = yield call(getData, url, authtoken)

    yield put(getCSCentalInventorySuccess(response))
  } catch (error) {
    yield put(getCSCentalInventoryFail(error))
  }
}
function* fetchCSDistributionInventory({ payload: { authtoken, category, brand, distribution, limit, pageNo } }) {
    try {
      // console.log(role)
      // const newQuery = `?${query}`
      const url = `dh-inventory/get-hub-stock?category=${category || ""}&brand=${brand || ""}&limit=${limit || 1000}&pageNo=${pageNo || ""}&warehouse=${distribution}`
      const response = yield call(getData, url, authtoken)
  
      yield put(getCSDistributionInventorySuccess(response))
    } catch (error) {
      yield put(getCSDistributionInventoryFail(error))
    }
  }

  function* fetchCsBpInventory({ payload: { authtoken} }) {
    try {
      // console.log(role)
      // const newQuery = `?${query}`
      const url = `/org/organizationDashboard/dashboardbannerone`
      const response = yield call(getData, url, authtoken)
  
      yield put(getCSBpInventorySuccess(response))
    } catch (error) {
      yield put(getCSBpInventoryFail(error))
    }
  }




  

function* CurrentStockSaga() {
    yield takeEvery(GET_CS_CENTRAL_INVENTORY, fetchCScentralInventory )
    yield takeEvery(GET_CS_DISTRIBUTION_INVENTORY, fetchCSDistributionInventory )
    yield takeEvery(GET_CS_BP_INVENTORY, fetchCsBpInventory)
   

 
 
  }
  
  export default CurrentStockSaga