import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  BreadcrumbItem,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import Question from "../../assests/images/question.svg";
import { getDisburseHubBp } from "../../store/Disburse/action.js";
import { getSingleDistribution } from "../../store/Distribution Hub/actions.js";
import {
  addReconciliation,
  getReconciliation,
  storeReconciliationData
} from "../../store/Reconciliation/action";
import CardComponent from "../Layout/CardComponent";
import NoTableData from "../Layout/NoTableData";

const BpInventory = ({ history }) => {
  let dispatch = useDispatch();
  const [bp, setBp] = useState("");
  const [distribution, setDistribution] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataRange, setDataRange] = useState(10);
  const [totalPageNumber, setTotalPageNumber] = useState(1);
  //let totalPageNumber = Math.ceil(areaData?.length / dataRange)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const {
    authtoken,
    adding,
    role,
    disburseBp,
    userId,
    singleDistribution,
    reconciliationData,
    reconciliationLoading,
    disburseHubBp,
    disburseHubBpLoading,
    reconciliationReceived
  } = useSelector((state) => ({
    role: state.Login.userrole,
    singleDistribution: state.DistributionReducer.singleDistribution,
    disburseBp: state.DisburseReducer.disburseBp,
    reconciliationData: state.ReconciliationReducer.reconciliationData,
    reconciliationReceived: state.ReconciliationReducer.reconciliationReceived,
    reconciliationLoading: state.ReconciliationReducer.reconciliationLoading,
    disburseHubBp: state.DisburseReducer.disburseHubBp,
    disburseHubBpLoading: state.DisburseReducer.disburseHubBpLoading,
    adding: state.ReconciliationReducer.adding,
    userId: state.Login.warehouse,
    authtoken: state.Login.token,
  }));
  console.log(`reconciliationReceived`, reconciliationReceived);
  // useEffect(() => {
  //   const options = {
  //     dateId: startDate,
  //     hubId: userId,
  //   };
  //   dispatch(getDisburseBp(authtoken, options));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [startDate, userId]);
  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, "Hub", ""));
  }, []);
  useEffect(() => {
    if(role === 'SUPERADMIN' || role ==="ADMIN" ||
    role === 'VIEWADMIN'){
      dispatch(getDisburseHubBp(authtoken, role, distribution));
    }
    else{
      dispatch(getDisburseHubBp(authtoken, role, userId));
    }  
  }, [distribution]);
  useEffect(() => {
    dispatch(getReconciliation(authtoken, startDate, bp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, bp]);

  const handleStartDate = (e) => {
    // console.log('start', e.target.value);
    const value = e.target.value;
    setStartDate(moment(value).format("YYYY-MM-DD"));
  };

  const handleBp = (value) => {
    console.log("value", value);
    if (value) {
      setBp(value?._id);
    } else {
      setBp("");
    }
  };
  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View
    </Tooltip>
  );

  const handleSubmit = () => {
    const listData = reconciliationReceived?.map((data) => ({
      BPUserId: data?.BPUserId?._id,
      // partnerId: data?.partnerId?._id,
      hubId: data?.distributionHubId?._id,
      itemId: data?.itemId?._id,
      returnQty: data?.returnQty *1,
      date: startDate,
    }));
    const body = {
      list: listData,
    };
    console.log(`handleSubmit ~ listData`, listData);
    dispatch(addReconciliation(authtoken, body, history));
    setShow(false);
  };
  const handleDistributionHub = (value) => {
    if (value) {
      setDistribution(value?._id);
    } else {
      setDistribution("");
    }
  };

  const handleSubmitQtyEdit = (e, idx, data) => {
    console.log(e.target.value);
    console.log(idx);
  
    const oldValue = [...reconciliationReceived]
    console.log('oldValue', oldValue);
    if (e.target.value * 1 <= oldValue[idx].dayOpeningQty){
      oldValue[idx].returnQty = e.target.value
    
      dispatch(storeReconciliationData("reconciliationReceived", oldValue))
     
    }

    if (e.target.value * 1 > oldValue[idx].dayOpeningQty){
      toast( 'Return QTY can not be bigger than Day Opening Qty', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
 
 
   


  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="6" className="mb-4" xs="12">
              {/* <div className="d-flex mb-4"> */}
              {/* <div className="me-auto"> */}
              <h4 className="mb-0 font-size-16">Reconciliation</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0 p-0 mt-2">
                  <BreadcrumbItem>
                    <Link to="#">Reconciliation</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">Reconciliation List</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </Col>
            {role === "HUB" ? <Col className="" xs="12" md="2"></Col> : <></>}
            {/* </div> */}
            {/* <Row> */}
            <Col xs="12" md="2" className="mt-2">
              <Input
                name="fromDate"
                type="date"
                className=""
                value={startDate}
                onChange={handleStartDate}
              />{" "}
              {/* <div className="ms-3 me-3 mt-2">To</div> */}
            </Col>
            {role === "HUB" ? (
              <></>
            ) : (
              <Col xs="12" md="2" className="mt-2">
                <Select
                  name="hub"
                  // className="mb-2"
                  classNamePrefix="select2-selection"
                  placeholder="Distribution Hub"
                  cacheOptions
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  options={singleDistribution?.data?.Warehouse}
                  onChange={handleDistributionHub}
                />
              </Col>
            )}
              {disburseHubBpLoading ? (
                    <div className="d-flex justify-content-end mt-3">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  ) : (
            <Col xs="12" md="2" className="mt-2">
              <Select
                name="bp"
                className="mb-2"
                classNamePrefix="select2-selection"
                placeholder="Select BP"
                cacheOptions
                getOptionLabel={(e) => e?.name}
                getOptionValue={(e) => e.value}
                isClearable
                options={disburseHubBp?.data?.bpList}
                onChange={handleBp}
              />
            </Col>
 )}
            {/* </Row> */}
            {/* </div> */}
          </Row>
          <Row>
            <CardComponent className="user_table_card">
              <Row className="mb-3 table-header-padding">
                {/* <div className="d-flex d-flex-blockCustom justify-content-end">
                  <div className="me-3">
                    <button
                      className="btn btn-secondary "
                      style={{ borderRadius: "10px" }}
                    >
                      <i className="bx bx-file  me-2"></i>
                      Download PDF
                    </button>
                  </div>
                  <div className="me-3">
                    {" "}
                    <div className="d-flex align-items-center mt-2">
                      <div className="bullet me-2"></div>
                      <div>Inventory Over</div>
                    </div>
                  </div>
                  <div className="me-3">
                    {" "}
                    <div className="d-flex align-items-center mt-2 custom-bottom-margin">
                      <div className="bullet2 me-2"></div>
                      <div>Inventory almost over</div>
                    </div>
                  </div>

                  <div className="search-box d-flex custom-bottom-margin me-3 search-width2">
                    <SearchInput
                      className="userSearchBar form-control"
                      onChange=""
                      placeholder="Search.."
                    />
                  </div>

                  <div style={{ width: "200px" }} classNamesearch-width2>
                    <Input type="select" onChange="" value="">
                      <option value="10">10</option>
                      <option value="10">50</option>
                      <option value="10">100</option>
                    </Input>
                  </div>
                </div> */}
                <Col md="10"></Col>
                <Col sm="2" className="mb-2">
                  <Input type="select" onChange="" value="">
                    <option value="10">10</option>
                    <option value="10">50</option>
                    <option value="10">100</option>
                  </Input>
                </Col>
              </Row>
              {/* table */}
              <Row>
                <div className="table-responsive">
                  {reconciliationLoading ? (
                    <div className="text-center mt-5 pt-5 mb-5">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <Table className="table table-hover mb-0 table-centered table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th>No.</th>

                          {/* <th>Partner Id</th>
                          <th>Partner Name</th>
                          <th>Category</th>
                          <th>Brand</th> */}
                          <th>Product Name</th>
                          <th>Day Opening</th>
                          <th>Qty Delivery</th>
                          <th>Return Qty</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reconciliationReceived?.length > 0 ? (
                          reconciliationReceived.map(
                            (data, idx) => (
                              <tr key={idx}>
                                <td>{idx + 1}</td>

                                {/* <td>{data?.partnerId?.partnerCode}</td>
                                <td>{data?.partnerId?.outletName}</td>
                                <td></td>
                                <td></td> */}
                                <td>{data?.itemId?.name}</td>
                                <td>{data?.dayOpeningQty}</td>
                                <td>{data?.deliveryQty}</td>
                                {/* {isNaN(
                                  Math.ceil(
                                    data?.dayOpeningQty - data?.deliveryQty
                                  )
                                ) ? (
                                  <td>0</td>
                                ) : (
                                  <td>
                                    {data?.dayOpeningQty - data?.deliveryQty}
                                  </td>
                                )} */}
                              
                                <td>
                              <Input
                                type="text"
                                 value={data?.returnQty}
                                style={{ width: "80px", height: "30px" }}
                                onChange={e => handleSubmitQtyEdit(e, idx, data)}
                              ></Input>
                            </td>
                               
                              </tr>
                            )
                          )
                        ) : (
                          <NoTableData
                            colSpan=""
                            className="d-flex justify-content-center align-items-center"
                            style={{ minHeight: "300px", width: `100%` }}
                          >
                            <span>No data Available</span>
                          </NoTableData>
                        )}
                      </tbody>
                    </Table>
                  )}
                  {/* <Pagination
                    parentClass={`paginationContainer text-right py-3`}
                    reInitialize="reInitialize"
                    page={totalPageNumber}
                    setCurrentPage={setCurrentPage}
                  /> */}
                </div>
                {reconciliationData?.bool || bp === "" || role ==="VIEWADMIN" ? null : (
                  <div className="d-flex justify-content-end my-2">
                    <button
                      disabled={adding}
                      className="btn btn-primary"
                      onClick={handleShow}
                    >
                      {adding ? "Submitting..." : "Day close"}
                    </button>
                  </div>
                )}
                {/* table end */}
              </Row>
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header
                  closeButton
                  style={{ borderBottom: "none" }}
                ></Modal.Header>
                <Modal.Body className="text-center">
                  <div className="mb-4">
                    <img src={Question} alt="Image" style={{ width: "76px" }} />
                  </div>
                  <h5>Are you confirm of closing the day?</h5>
                </Modal.Body>
                <Modal.Footer
                  style={{ borderTop: "none", justifyContent: "center" }}
                  className="mb-4"
                >
                  <Button
                  
                    variant="outline-secondary"
                    onClick={handleClose}
                    style={{ width: "112px" }}
                  >
                    Cancel
                  </Button>
                  <Button className="btn button"   disabled={adding} onClick={handleSubmit}>
                  {adding ? "Submitting..." : "Yes, I’m Sure"}
                  </Button>
                </Modal.Footer>
              </Modal>
            </CardComponent>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

BpInventory.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(BpInventory);
