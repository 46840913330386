import PropTypes from "prop-types";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { BreadcrumbItem, Col, Container, Input, Row, Table } from "reactstrap";
import SearchInput from "../Atoms/SearchInput";
import Pagination from "../Common/Pagination";
import CardComponent from "../Layout/CardComponent";

const BpInventory = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataRange, setDataRange] = useState(10);
  const [totalPageNumber, setTotalPageNumber] = useState(1);
  //let totalPageNumber = Math.ceil(areaData?.length / dataRange)
  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View
    </Tooltip>
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="2" sm="12" className="mb-3">
              <h4 className="mb-0 font-size-16">BP Inventory Report</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0 p-0 mt-2">
                  <BreadcrumbItem>
                    <Link to="#">Inventory</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">Inventory List</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </Col>
            <Col md="10" sm="12" className="mb-3">
              <Row>
                <Col sm="12" md="4" className="mb-3">
                  <div className="d-flex">
                    <Input
                      name="fromDate"
                      type="date"
                      className=""
                      style={{ fontSize: "12px" }}
                    />{" "}
                    <div
                      className="mt-2 me-1 ms-1"
                      style={{ fontSize: "12px" }}
                    >
                      To
                    </div>
                    <Input
                      name="toDate"
                      type="date"
                      style={{ fontSize: "12px" }}
                    />
                  </div>
                </Col>
                <Col
                  className="mb-3"
                  sm="12"
                  md="2"
                  style={{ fontSize: "12px" }}
                >
                  <Select
                    name="bp"
                    className="mb-2"
                    classNamePrefix="select2-selection"
                    placeholder="Select BP"
                    cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    options={[1, 2, 3]}
                  />
                </Col>
                <Col
                  className="mb-3"
                  sm="12"
                  md="2"
                  style={{ fontSize: "11px" }}
                >
                  <Select
                    name="category"
                    className="mb-2"
                    classNamePrefix="select2-selection"
                    placeholder="Select Category"
                    cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    options={[1, 2, 3]}
                  />
                </Col>
                <Col
                  className="mb-3"
                  sm="12"
                  md="2"
                  style={{ fontSize: "12px" }}
                >
                  <Select
                    name="brand"
                    className="mb-2"
                    classNamePrefix="select2-selection"
                    placeholder="Select Brand"
                    cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    options={[1, 2, 3]}
                  />
                </Col>
                <Col
                  sm="12"
                  md="2"
                  className="mb-3"
                  style={{ fontSize: "12px" }}
                >
                  <Select
                    name="product"
                    className="mb-2"
                    classNamePrefix="select2-selection"
                    placeholder="Select Product"
                    cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    options={[1, 2, 3]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <CardComponent className="user_table_card">
            <Row className="mb-3 table-header-padding">
              <Col md="3"></Col>
              <Col md="9">
                <Row>
                  <Col sm="12" md="3" className="text-end mb-2 pdf-text-start">
                    <button
                      className="btn btn-secondary"
                      style={{ borderRadius: "10px" }}
                    >
                      <i className="bx bx-file  me-2"></i>
                      Download PDF
                    </button>
                  </Col>
                  <Col md="2" sm="12" className="mb-2">
                    {" "}
                    <div className="d-flex align-items-center mt-2 text-end">
                      <div className="bullet me-2"></div>
                      <div>Inventory Over</div>
                    </div>
                  </Col>
                  <Col md="3" sm="12" className="mb-2">
                    {" "}
                    <div className="d-flex align-items-center mt-2">
                      <div className="bullet2 me-2"></div>
                      <div>Inventory almost over</div>
                    </div>
                  </Col>
                  <Col md="2" sm="12" className="mb-2">
                    <div className="search-box d-flex justify-content-start">
                      <SearchInput
                        className="userSearchBar form-control"
                        onChange=""
                        placeholder="Search.."
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="2" className="mb-2">
                    <Input type="select" onChange="" value="">
                      <option value="10">10</option>
                      <option value="10">50</option>
                      <option value="10">100</option>
                    </Input>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* table */}
            <Row>
              <div className="table-responsive">
                <Table className="table table-hover mb-0 table-centered table-nowrap">
                  <thead className="table-light">
                    <tr>
                      <th>Sl. No.</th>
                      <th>Warehouse</th>
                      <th>Product Name</th>
                      <th>Brand</th>
                      <th>Category</th>
                      <th>Stock In</th>
                      <th>Stock Out</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Test</td>
                      <td>Test</td>
                      <td>Test</td>
                      <td>Test</td>
                      <td>Test</td>
                      <td>Test</td>
                      <td>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipView}
                        >
                          <button
                            className="btn btn-secondary btn-sm me-2"
                            style={{ borderRadius: "10px" }}
                          >
                            <i className="bx bx-show mt-1"></i>
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Pagination
                  parentClass={`paginationContainer text-right py-3`}
                  reInitialize="reInitialize"
                  page={totalPageNumber}
                  setCurrentPage={setCurrentPage}
                />
              </div>
              {/* table end */}
            </Row>
          </CardComponent>
        </Container>
      </div>
    </React.Fragment>
  );
};

BpInventory.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(BpInventory);
