import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useInfiniteScroll from '../../Hooks/useInfiniteScroll';
import CustomDropDown from '../Common/CustomFilter/DropDown';

const SelectPartner = ({ handlePartnerList, selectedPartner, idx }) => {
  const [reRender] = useState(false);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [sizesOpen, setSizesOpen] = useState(false);

  const { authToken } = useSelector((state) => ({
    role: state.Login.userrole,
    authToken: state.Login.token,
  }));

  const { loading, error, data, errorMessage, lastElementRef, handleSearch } =
    useInfiniteScroll({
      method: 'Get',
      url: `/partner/?limit=15&pageNo=${page}&partnerCode=${query}`,
      body: {},
      token: authToken,
      name: 'partner',
      query,
      page,
      setQuery,
      setPage,
      reRender,
    });

  const handleSelectData = (data) => {
    setQuery("")
    handlePartnerList(data, idx);
    setSizesOpen(!sizesOpen);
  };

  console.log(`AreaFilter ~ loading`, loading, errorMessage);
  return (
    <React.Fragment>
      <CustomDropDown
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setQuery('');
        }}
        open={sizesOpen}
        onChange={handleSearch}
        searchValue={query}
        isSelected={selectedPartner}
        handleClear={() => handlePartnerList('', idx)}
        selectedItem={data?.find((item)=>item._id ===selectedPartner)?.outletName||'Select Partner'}
      >
        <div style={{ width: '100%', height: '100%' }}>
          {data?.map((i, index) => (
            <div
              key={index}
              onClick={() => handleSelectData(i)}
              style={{ cursor: 'pointer' }}
              className={'grey dropDownItem'}
              ref={data.length === index + 1 ? lastElementRef : null}
            >
              <span
                style={{
                  margin: '2px',
                  padding: '4px',
                  display: 'block',
                }}
              >
                {i?.outletName}
              </span>
            </div>
          ))}
          {loading ? (
            <div className={'py-1 text-center'}>Loading...</div>
          ) : null}
          {error ? <div className={'py-1 text-center'}>Error</div> : null}
        </div>
      </CustomDropDown>
    </React.Fragment>
  );
};

export default SelectPartner;
