import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Row, Table } from "reactstrap";
import Question from "../../../assests/images/question.svg";
import {
  getDisburseHubBp,
  getDisburseHubStock,
  getDisburseToday,
  postDisburse,
  storeDisburseData,
  getDisburseBp,
  getSingleDisburse
} from "../../../store/Disburse/action";
import CardComponent from "../../Layout/CardComponent";
import CustomTable from "../../Layout/CustomTable";
import InnerLayer from "../../Layout/InnerLayer";
import NoTableData from "../../Layout/NoTableData";

const tableHead = ["Sl. No.", "Category", "Brand", "Product", "QTY"];
const tableHeadBp = ["Sl. No.", "BP. Name", "Employee ID", "Action"];

const renderTooltipView = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    View
  </Tooltip>
);
const BP = [
  {
    name: "HB 1",
    _id: 1,
    value: "HB 1",
  },
  {
    name: " HB2",
    _id: 2,
    value: "HB2",
  },
];

const StockIn = ({ history }) => {
  let dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(100);
  const [totalPageNumber, setTotalPageNumber] = useState(1);
  const [detailShow, setDetailShow] = useState(false);
  const [singleBp, setSingleBp] = useState("");
  const [tabStatus, setTabStatus] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    role,
    user,
    authToken,
    disburseToday,
    disburseDataLoading,
    disburseBp,
    singleLoading,
    userId,
    singleDisburse,
    adding,
    disburseHubStock,
    disburseList,
    disburseHubBp,
    disburseHubBpLoading,
  } = useSelector((state) => ({
    role: state.Login.userrole,
    authToken: state.Login?.token,
    user: state.Login,
    disburseToday: state.DisburseReducer.disburseToday,
    disburseHubStock: state.DisburseReducer.disburseHubStock,
    disburseBp: state.DisburseReducer.disburseBp,
    disburseHubBp: state.DisburseReducer.disburseHubBp,
    disburseHubBpLoading: state.DisburseReducer.disburseHubBpLoading,
    disburseDataLoading: state.DisburseReducer.disburseDataLoading,
    singleLoading: state.DisburseReducer.singleLoading,
    singleDisburse: state.DisburseReducer.singleDisburse,
    userId: state.Login.warehouse,
    adding: state.DisburseReducer.adding,
    disburseList: state.DisburseReducer.disburseList,
  }));

  useEffect(() => {
    const options = {
      dateId: moment(new Date()).format("YYYY-MM-DD"),
      selectBp: singleBp,
    };
    if (singleBp) {
      dispatch(getDisburseHubStock(authToken, "", "", userId, 2000, 1));
      dispatch(getSingleDisburse(authToken, options));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleBp]);

  useEffect(() => {
    const options = {
      dateId: moment(new Date()).format("YYYY-MM-DD"),
      hubId: user?.warehouse,
    };
    if (tabStatus) {
      dispatch(getDisburseToday(authToken, options));
    } else {
      dispatch(getDisburseHubBp(authToken, role, userId));
    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabStatus]);

  const toggle = () => setDetailShow(!detailShow);

  const handleClick = () => {
    let objList = {}
    let dynamicProd = []
     disburseList.map(data=>{
      
      if(data?.quantity>0){
        objList.dayOpeningQty = data?.quantity
        objList.itemId = data?.products?._id
        let  dataList= {...objList}
        dynamicProd.push(dataList)

      }
      
    })
    const listData = dynamicProd.map((data) => ({
      BPUserId: singleBp,
      hubId: userId,
      itemId: data?.itemId,
      dayOpeningQty: data?.dayOpeningQty *1,
      date: moment(new Date()).format("YYYY-MM-DD"),
    }));
    const value = {
      list: listData,
    };
    const options = {
      toggle,
      value,
      option: {
        dateId: moment(new Date()).format("YYYY-MM-DD"),
        hubId: user?.warehouse,
      },
    };
    if(value.list.length !==0){
       dispatch(postDisburse(authToken, options));
       setShow(false)
    } 
      else{
        toast("Please Select Quantity", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    
 
    // history.push("/reconciliation")
  };
  const handleSubmitQtyEdit = (e, idx, data) => {
    console.log("data", data);
    const oldValue = [...disburseList];
    if (e.target.value * 1 <= oldValue[idx].stock) {
      oldValue[idx].quantity = e.target.value ;

      dispatch(storeDisburseData("disburseList", oldValue));
    }
    if (e.target.value * 1 > oldValue[idx].stock) {
      toast("Quantity can not be bigger than Stock", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <React.Fragment>
      <InnerLayer
        title="Today's Disburse Plan"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Disburse Plan"}
        subTitle={"Today's Disburse Plan"}
        onAddHandler={() => history.push("/add-disburse-plan")}
        buttonText="Create New"
        isButton={role === "SUPERADMIN" ? true : false}
      >
        <Row>
          <CardComponent className="user_table_card">
            <div
              className={
                tabStatus
                  ? "mt-4 mb-4 d-flex justify-content-center d-block"
                  : "mt-4 mb-4 d-flex justify-content-center d-none"
              }
            >
              <button
                className="tab-button custom-font-size"
                onClick={() => setTabStatus(true)}
              >
                Product List
              </button>
              <button
                className="custom-font-size tab-button2"
                onClick={() => setTabStatus(false)}
              >
                BP List
              </button>
            </div>
            <div
              className={
                tabStatus
                  ? "mt-4 mb-4 d-flex justify-content-center d-none"
                  : "mt-4 mb-4 d-flex justify-content-center d-block"
              }
            >
              <button
                className=" tab-button3"
                onClick={() => setTabStatus(true)}
              >
                Product List
              </button>
              <button
                className=" tab-button4"
                onClick={() => setTabStatus(false)}
              >
                BP List
              </button>
            </div>
            <Row>
              {tabStatus ? (
                <CustomTable
                  paginationClass="paginationContainer text-right"
                  data={disburseToday}
                  pageNo={totalPageNumber}
                  tableHead={tableHead}
                  setCurrentPage={setCurrentPage}
                  isPagination
                >
                  {disburseDataLoading ? (
                    <NoTableData
                      colSpan="6"
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "300px", width: `100%` }}
                    >
                      <span>Loading...</span>
                    </NoTableData>
                  ) : disburseToday?.length > 0 ? (
                    disburseToday?.map((data, idx) => (
                      <tr>
                        <th scope="row" style={{ paddingLeft: "25px" }}>
                          1
                        </th>
                        <td>{data?.categories?.name}</td>
                        <td>{data?.brand?.name}</td>
                        <td>{data?.products?.name}</td>
                        <td>{data?.itemQuantity}</td>
                      </tr>
                    ))
                  ) : (
                    <NoTableData
                      colSpan="6"
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "300px", width: `100%` }}
                    >
                      <span>No data Available</span>
                    </NoTableData>
                  )}
                </CustomTable>
              ) : (
                <CustomTable
                  paginationClass="paginationContainer text-right"
                  data={disburseHubBp?.data?.bpList}
                  pageNo={totalPageNumber}
                  tableHead={tableHeadBp}
                  setCurrentPage={setCurrentPage}
                  isPagination
                >
                  {disburseHubBpLoading ? (
                    <NoTableData
                      colSpan="6"
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "300px", width: `100%` }}
                    >
                      <span>Loading...</span>
                    </NoTableData>
                  ) : disburseHubBp?.data?.length > 0 ? (
                    disburseHubBp?.data?.bpList?.map((data, idx) => (
                      <tr>
                        <th scope="row" style={{ paddingLeft: "25px" }}>
                          1
                        </th>
                        <td>{data?.name}</td>
                        <td>{data?.employeeId}</td>
                        <td>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipView}
                          >
                            <button
                              className="btn btn-secondary btn-sm me-2"
                              style={{ borderRadius: "10px" }}
                              onClick={() => {
                                setSingleBp(data?._id);
                                setDetailShow(true);
                              }}
                            >
                              <i className="bx bx-show mt-1"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoTableData
                      colSpan=""
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "300px", width: `100%` }}
                    >
                      <span>No data Available</span>
                    </NoTableData>
                  )}
                </CustomTable>
              )}

              {/* table end */}
              {/* modal for detail */}

              <Modal
                size="xl"
                show={detailShow}
                onHide={() => setDetailShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header
                  closeButton
                  style={{ borderBottom: "none" }}
                ></Modal.Header>
                <Modal.Body style={{ padding: "3px 25px" }}>
                  <h4 className="text-center mb-5" style={{ color: "#0C1D37" }}>
                    Day Opening
                  </h4>

                  {singleLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <div className="table-responsive">
                      <Table className="table table-hover mb-0 table-centered table-nowrap mb-5">
                        <thead className="table-light">
                          <tr>
                            <th>Sl. No.</th>
                            {/* <th>Partner</th>
                          <th>Partner ID</th> */}
                            <th>Category</th>
                            <th>Brand</th>
                            <th>Product</th>
                            <th>Stock</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {disburseHubStock?.length ? (
                            disburseHubStock?.logdata?.map((data, idx) => (
                              <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{data?.category?.name}</td>
                                <td>{data?.brand?.name}</td>
                                <td>{data?.products?.name}</td>
                                <td>{data?.stock}</td>
                                <td>
                                  <Input
                                    type="text"
                                    value = {data?.quantity}
                                    style={{
                                      width: "64px",
                                      height: "27px",
                                    }}
                                    onChange={(e) =>
                                      handleSubmitQtyEdit(e, idx, data)
                                    }
                                  ></Input>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <div>No Data</div>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  {
                    singleDisburse?.bool === true ? <span></span>
                    : 
                    <div className="text-end">
                    <button
                      disabled={adding}
                      className="tab-button4 mb-5"
                      style={{ padding: "10px 15px" }}
                      onClick={handleShow}
                    >
                      {adding ? "Submitting..." : "Confirm day opening"}
                    </button>
                  </div>
                  }
             

                  {/* {
                    singleDisburse?.bool === false ? 
                    <div className="d-flex justify-content-end">
                    {singleDisburse?.disbursePlan?.length ? (
                
                    ) : null}
                  </div> :
                  <span></span>
                  } */}
                </Modal.Body>
              </Modal>
            </Row>
          </CardComponent>
        </Row>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body className="text-center">
            <div className="mb-4">
              <img src={Question} alt="Image" style={{ width: "76px" }} />
            </div>
            <h5>Are you want to disburse Products?</h5>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "none", justifyContent: "center" }}
            className="mb-4"
          >
            <Button

              variant="outline-secondary"
              onClick={handleClose}
              style={{ width: "112px" }}
            >
              Cancel
            </Button>
            <Button className="btn button" onClick={handleClick}   disabled={adding}>
               {adding ? "Submitting..." : " Yes, I’m Sure"}
            </Button>
          </Modal.Footer>
        </Modal>
      </InnerLayer>
    </React.Fragment>
  );
};

StockIn.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(StockIn);
