export const GET_TOP_BANNER = "GET_TOP_BANNER";
export const GET_TOP_BANNER_SUCCESS = "GET_TOP_BANNER_SUCCESS";
export const GET_TOP_BANNER_FAIL = "GET_TOP_BANNER_FAIL";

export const GET_GIFT_DISBURSEMENT = "GET_GIFT_DISBURSEMENT";
export const GET_GIFT_DISBURSEMENT_SUCCESS = "GET_GIFT_DISBURSEMENT_SUCCESS";
export const GET_GIFT_DISBURSEMENT_FAIL = "GET_GIFT_DISBURSEMENT_FAIL";

export const GET_BP_DELIVERY = "GET_BP_DELIVERY";
export const GET_BP_DELIVERY_SUCCESS = "GET_BP_DELIVERY_SUCCESS";
export const GET_BP_DELIVERY_FAIL = "GET_BP_DELIVERY_FAIL";

export const GET_DASHBOARD_CATEGORY = "GET_DASHBOARD_CATEGORY";
export const GET_DASHBOARD_CATEGORY_SUCCESS = "GET_DASHBOARD_CATEGORY_SUCCESS";
export const GET_DASHBOARD_CATEGORY_FAIL = "GET_DASHBOARD_CATEGORY_FAIL";


export const GET_DASHBOARD_BRAND = "GET_DASHBOARD_BRAND";
export const GET_DASHBOARD_BRAND_SUCCESS = "GET_DASHBOARD_BRAND_SUCCESS";
export const GET_DASHBOARD_BRAND_FAIL = "GET_DASHBOARD_BRAND_FAIL";

export const GET_DASHBOARD_PARTNER = "GET_DASHBOARD_PARTNER";
export const GET_DASHBOARD_PARTNER_SUCCESS = "GET_DASHBOARD_PARTNER_SUCCESS";
export const GET_DASHBOARD_PARTNER_FAIL = "GET_DASHBOARD_PARTNER_FAIL";

export const GET_LEAD_TIME = "GET_LEAD_TIME";
export const GET_LEAD_TIME_SUCCESS = "GET_LEAD_TIME_SUCCESS";
export const GET_LEAD_TIME_FAIL = "GET_LEAD_TIME_FAIL";

export const GET_ERROR = "GET_ERROR";
export const GET_ERROR_SUCCESS = "GET_ERROR_SUCCESS";
export const GET_ERROR_FAIL = "GET_ERROR_FAIL";

export const GET_DASHBOARD_STOCK = "GET_DASHBOARD_STOCK";
export const GET_DASHBOARD_STOCK_SUCCESS = "GET_DASHBOARD_STOCK_SUCCESS";
export const GET_DASHBOARD_STOCK_FAIL = "GET_DASHBOARD_STOCK_FAIL";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";


export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL";

export const STORE_DASHBOARD_DATA = "STORE_DASHBOARD_DATA";