import { toast } from "react-toastify"
import { call, delay, put, takeEvery } from "redux-saga/effects"
import { getData, postData, addProduct } from "../../helpers/api_helper"

import {
    getProductStockSuccess,
    getProductStockFail,
    deleteStockSuccess,
    deleteStockFail,
    getProductStock
} from "./action"
import {
    GET_PRODUCT_STOCK,
    DELETE_STOCK

} from "./actionTypes"

function* fetchProductStock({ payload: { authtoken, startDate, endDate, wareHouse, currentPage, pageRange } }) {
    try {
  
      const url = `centralstock/central-log-report?fromDate=${startDate}&toDate=${endDate}&warehouse=${wareHouse}&limit=${pageRange}&pageNo=${currentPage}`
      const response = yield call(getData, url, authtoken )
      yield put(getProductStockSuccess(response))
    } catch (error) {
      yield put(getProductStockFail(error))
    }
  }
  function* deleteStock({ payload: { authtoken, id, body:{
     startDate, endDate, wareHouse, currentPage, pageRange
  } } }) {
    try {
  
      const url = `centralstock/delete-log-stock/${id}`
      const response = yield call(getData, url, authtoken )
      yield put(deleteStockSuccess(response))
      yield put (  getProductStock(
        authtoken,
        startDate,
        endDate,
         wareHouse,
        currentPage,
        pageRange
      ))
    } catch (error) {
      yield put(deleteStockFail(error))
    }
  }







function* StokeInSaga() {
  yield takeEvery(GET_PRODUCT_STOCK, fetchProductStock)
  yield takeEvery(DELETE_STOCK, deleteStock)

 
}

export default StokeInSaga
