import {
    GET_CS_CENTRAL_INVENTORY,
    GET_CS_CENTRAL_INVENTORY_SUCCESS,
    GET_CS_CENTRAL_INVENTORY_FAIL,
    GET_CS_DISTRIBUTION_INVENTORY,
    GET_CS_DISTRIBUTION_INVENTORY_SUCCESS,
    GET_CS_DISTRIBUTION_INVENTORY_FAIL,
    GET_CS_BP_INVENTORY,
    GET_CS_BP_INVENTORY_SUCCESS,
    GET_CS_BP_INVENTORY_FAIL,

  
  
    } from "./actionTypes";
    
    export const getCSCentalInventory = (authtoken, category, brand, warehouse, limit, page) => ({
      type: GET_CS_CENTRAL_INVENTORY,
      payload: { authtoken, category, brand, warehouse, limit, page },
    });
    
    export const getCSCentalInventorySuccess = (data) => ({
      type: GET_CS_CENTRAL_INVENTORY_SUCCESS,
      payload: { data },
    });
    export const getCSCentalInventoryFail = (error) => ({
      type: GET_CS_CENTRAL_INVENTORY_FAIL,
      payload: error,
    });
    export const getCSDistributionInventory = (authtoken, category, brand, distribution, limit, pageNo) => ({
      type: GET_CS_DISTRIBUTION_INVENTORY,
      payload: { authtoken, category, brand, distribution, limit, pageNo },
    });
    
    export const getCSDistributionInventorySuccess = (data) => ({
      type: GET_CS_DISTRIBUTION_INVENTORY_SUCCESS,
      payload: data,
    });
    
    export const getCSDistributionInventoryFail = (error) => ({
      type: GET_CS_DISTRIBUTION_INVENTORY_FAIL,
      payload: error,
    });

    export const getCSBpInventory = (authtoken, id) => ({
        type: GET_CS_BP_INVENTORY,
        payload: { authtoken, id },
      });
      
      export const getCSBpInventorySuccess = (data) => ({
        type: GET_CS_BP_INVENTORY_SUCCESS,
        payload: data,
      });
      
      export const getCSBpInventoryFail = (error) => ({
        type: GET_CS_BP_INVENTORY_FAIL,
        payload: error,
      });

 
    
  
    
 
   
    
    