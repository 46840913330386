import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Badge, Col, Input, Row, Spinner } from "reactstrap";
import * as XLSX from "xlsx";
import { get } from "../../helpers/api_helper";
import { getDistributionHub } from "../../store/Distribution Hub/actions";
import SearchInput from "../Atoms/SearchInput";
import CardComponent from "../Layout/CardComponent";
import CustomTable from "../Layout/CustomTable";
import InnerLayer from "../Layout/InnerLayer";
import NoTableData from "../Layout/NoTableData";
const tableHead = [
  "No.",
  "Hub Name",
  "Hub ID",
  "Hub Type",
  "Address",
  "Region",
  "Area",
  "Territory",
  "Status",
  "Action",
];
const tableHead2 = [
  "No.",
  "Hub Name",
  "Hub ID",
  "Hub Type",
  "Address",
  "Region",
  "Area",
  "Territory",
  "Status",
];
const data = [10, 20, 50, 100];
const renderTooltipEdit = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Edit
  </Tooltip>
);
const renderTooltipView = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    View
  </Tooltip>
);
const HUbType = [
  {
    name: "Central Warehouse",
    _id: 1,
    value: "Central",
  },
  {
    name: "Distribution Hub",
    _id: 2,
    value: "Hub",
  },
];

const Hub = ({ history }) => {
  let dispatch = useDispatch();
  const [hubType, setHubType] = useState("");
  const [wareHouse, setWareHouse] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(10);
  const [value, setValue] = useState("");
  const [loadingExcel, setLoadingExcel] = useState(false);
  const {
    distributionHubData,
    loading,
    authtoken,
    areaData,
    role,
    distributionHubLoading,
  } = useSelector((state) => ({
    distributionHubData: state.DistributionReducer.distributionHubData,
    distributionHubLoading: state.DistributionReducer.distributionHubLoading,
    role: state.Login.userrole,
    authtoken: state.Login.token,
  }));
  console.log("distributionHubData", distributionHubData);

  let totalPageNumber = Math.ceil(
    distributionHubData?.data?.length / pageRange
  );
  useEffect(() => {
    dispatch(
      getDistributionHub(authtoken, currentPage, pageRange, hubType, value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageRange, hubType, value]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value !== "") {
        console.log("value", value);
        dispatch(
          getDistributionHub(authtoken, currentPage, pageRange, hubType, value)
        );
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);
  const handleWareHouse = (value) => {
    console.log("value", value);
    if (value) {
      setWareHouse(value?._id);
    } else {
      setWareHouse("Central");
    }
  };
  const handleHubType = (value) => {
    if (value) {
      setHubType(value?.value);
    } else {
      setHubType("");
    }
  };
  const handlePageRange = (e) => {
    setPageRange(e.target.value);
  };

  const handleExcel = async () => {
    setLoadingExcel(true);
    let hubData = [];
    let totalPage = Math.ceil(distributionHubData?.data?.length / 3);
    for (let i = 0; i < totalPage; i++) {
      console.log("hello");

      await get(
        `/warehouse/${3}/${i + 1}?warehouseType=${hubType || ""}&name=${""}`,
        { headers: { Authorization: `Bearer ${authtoken}` } }
      )
        .then((response) => {
          console.log("response", response);

          response?.Warehouse.forEach((hub, index) => {
            let data = {};
            data.HubName = hub?.name;
            data.HubID = hub?.uniqueId;
            data.Type = hub?.WarehouseType;
            data.Address = hub?.address;
            data.Area = hub?.area?.name;
            data.Region = hub?.region?.name;
            data.Territory = hub?.territory?.name;
            console.log(data);
            hubData.push(data);
          });
        })
        .catch((error) => {
          console.log("kkkk");
          // setResults((prev) => [...prev]);
        });
    }

    downloadxls(hubData);
  };

  const downloadxls = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Hub.xlsx");
    setLoadingExcel(false);
  };

  return (
    <React.Fragment>
      <InnerLayer
        title="Hub List"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Hub"}
        subTitle={"Distribution Hub List"}
        onAddHandler={() => history.push("/hub/add-hub")}
        buttonText="Create New"
        isButton={role === "SUPERADMIN" ? true : false}
      >
        <Row>
          <CardComponent className="user_table_card">
            {/* <TableHeader
              handleSearchFilter={() => {}}
              handleSelectFilter={(e) => {
                setPageRange(e.target.value);
              }}
              data={[10, 20, 50, 100]}
              handleSelectFilter2={() => {}}
              data2={[]}
              selectValue={pageRange}
              title="Area List"
              headerSearchOption="yes"
            /> */}
            <Row className="mb-2 table-header-padding">
              <div className="d-flex d-flex-blockCustom justify-content-end">
                <Col
                  className="me-2 responsive-margin custom-bottom-margin"
                  md={2}
                >
                  <div className="search-box  d-flex justify-content-end justify-content-start">
                    <SearchInput
                      className="userSearchBar form-control search-width2"
                      onChange={(e) => setValue(e.target.value)}
                      placeholder="Search Hub"
                    />
                  </div>
                </Col>
                <Col
                  className="me-2 responsive-margin custom-bottom-margin"
                  md={2}
                >
                  <Select
                    name="Warehouse"
                    className=""
                    classNamePrefix="select2-selection"
                    placeholder="Select Type"
                    // cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    options={HUbType}
                    onChange={handleHubType}
                  />
                </Col>
                <Col className="custom-bottom-margin" md={2}>
                  <Input
                    type="select"
                    // onChange={handleSelectFilter}
                    // value={selectValue}
                    onChange={handlePageRange}
                  >
                    {/* <option defaultValue>Select...</option> */}
                    {data.map((data, idx) => (
                      <option key={idx} value={data}>
                        {data}
                      </option>
                    ))}
                  </Input>
                </Col>
              </div>
            </Row>

            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={distributionHubData?.data?.Warehouse}
                pageNo={totalPageNumber}
                tableHead={role === "SUPERADMIN" ? tableHead : tableHead2}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {distributionHubLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </div>
                  </tr>
                ) : distributionHubData?.data?.length > 0 ? (
                  distributionHubData?.data?.Warehouse.map((data, idx) => (
                    <tr>
                      <th scope="row" style={{ paddingLeft: "25px" }}>
                        {idx + 1}
                      </th>
                      <td>{data?.name}</td>
                      <td>{data?.uniqueId}</td>
                      <td>{data?.WarehouseType}</td>
                      <td>{data?.address}</td>
                      <td>{data?.region?.name}</td>
                      <td>{data?.area?.name}</td>
                      <td>{data?.territory?.name}</td>
                      <td>
                        <Badge
                          color="warning"
                          style={{ padding: "10px !important" }}
                        >
                          Active
                        </Badge>
                      </td>
                      {role === "SUPERADMIN" ? (
                        <td>
                          {/* <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipView}
                        >
                          <button
                            className="btn btn-secondary btn-sm me-2"
                            style={{ borderRadius: "10px" }}
                          >
                            <i className="bx bx-show mt-1"></i>
                          </button>
                        </OverlayTrigger> */}
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipEdit}
                          >
                            <button
                              className="btn btn-outline-danger btn-sm"
                              style={{ borderRadius: "10px" }}
                              onClick={() =>
                                history.push(`/hub/edit-hub/${data?._id}`)
                              }
                            >
                              <i className="bx bx-edit mt-1"></i>
                            </button>
                          </OverlayTrigger>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan=""
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No data Available</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
          <button
            className="btn btn-info w-25 m-auto fw-bold excel_button"
            disabled={loadingExcel}
            onClick={handleExcel}
          >
            {loadingExcel ? "Downloading..." : "Download Excel"}
          </button>
        </Row>
      </InnerLayer>
    </React.Fragment>
  );
};

Hub.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(Hub);
