import {
    GET_CENTRAL_WAREHOUSE_SUCCESS,
    GET_CENTRAL_WAREHOUSE_FAIL,
    GET_DISTRIBUTION_HUB_INV_SUCCESS,
    GET_DISTRIBUTION_HUB_INV_FAIL,
    GET_BP_INVENTORY_SUCCESS,
    GET_BP_INVENTORY_FAIL,
    GET_CW_STOCK_REPORT_SUCCESS,
    GET_CW_STOCK_REPORT_FAIL,
    GET_DH_STOCK_REPORT_SUCCESS,
    GET_DH_STOCK_REPORT_FAIL
  
    } from "./actionTypes";
    const INIT_STATE = {
      wareHouseData: [],
      distributionInventory: [],
      BpInventoryData: [],
      reconciliationData: [],
      cwStockReport : [],
      dhStockReport: [],
      wareHouseDataLoading: true,
      distributionHubDataLoading: true,
      BpInventoryLoading: true,
      reconciliationLoading: true,
      cwStockReportLoading: true,
      dhStockReportLoading: true
      
   
    };
    const InventoryReducer = (state = INIT_STATE, action) => {
      switch (action.type) {
        case GET_CENTRAL_WAREHOUSE_SUCCESS:
          return {
            ...state,
            wareHouseData: action.payload,
            wareHouseDataLoading: false,
          };
        case GET_CENTRAL_WAREHOUSE_FAIL:
          return {
            ...state,
            error: action.payload,
            wareHouseDataLoading: false,
          };
        case GET_DISTRIBUTION_HUB_INV_SUCCESS:
          return {
            ...state,
            distributionInventory: action.payload,
            distributionHubDataLoading: false,
          };
        case GET_DISTRIBUTION_HUB_INV_FAIL:
          return {
            ...state,
            error: action.payload,
            distributionHubDataLoading: false,
          };
          case GET_BP_INVENTORY_SUCCESS:
            return {
              ...state,
              BpInventoryData: action.payload,
              BpInventoryLoading: false,
            };
          case GET_BP_INVENTORY_FAIL:
            return {
              ...state,
              error: action.payload,
              BpInventoryLoading: false,
            };
         
            case GET_CW_STOCK_REPORT_SUCCESS:
              return {
                ...state,
                cwStockReport: action.payload,
                cwStockReportLoading: false,
              };
            case GET_CW_STOCK_REPORT_FAIL:
              return {
                ...state,
                error: action.payload,
                cwStockReportLoading: false,
              };
              case GET_DH_STOCK_REPORT_SUCCESS:
                return {
                  ...state,
                  dhStockReport: action.payload,
                  dhStockReportLoading: false,
                };
              case GET_DH_STOCK_REPORT_FAIL:
                return {
                  ...state,
                  error: action.payload,
                  dhStockReportLoading: false,
                };
         
        default:
          return state;
      }
    };
    export default InventoryReducer;
    