export const GET_CHALLAN = 'GET_CHALLAN';
export const GET_CHALLAN_SUCCESS = 'GET_CHALLAN_SUCCESS';
export const GET_CHALLAN_FAIL = 'GET_CHALLAN_FAIL';

export const GET_CHALLAN_DETAILS = 'GET_CHALLAN_DETAILS';
export const GET_CHALLAN_DETAILS_SUCCESS = 'GET_CHALLAN_DETAILS_SUCCESS';
export const GET_CHALLAN_DETAILS_FAIL = 'GET_CHALLAN_DETAILS_FAIL';

export const GET_CHALLAN_INFO = 'GET_CHALLAN_INFO';
export const GET_CHALLAN_INFO_SUCCESS = 'GET_CHALLAN_INFO_SUCCESS';
export const GET_CHALLAN_INFO_FAIL = 'GET_CHALLAN_INFO_FAIL';

export const GET_CHALLAN_STOCK_LIST = 'GET_CHALLAN_STOCK_LIST';
export const GET_CHALLAN_STOCK_LIST_SUCCESS = 'GET_CHALLAN_STOCK_LIST_SUCCESS';
export const GET_CHALLAN_STOCK_LIST_FAIL = 'GET_CHALLAN_STOCK_LIST_FAIL';

export const CHALLAN_APPROVED = 'CHALLAN_APPROVED';
export const CHALLAN_APPROVED_SUCCESS = 'CHALLAN_APPROVED_SUCCESS';
export const CHALLAN_APPROVED_FAIL = 'CHALLAN_APPROVED_FAIL';

export const ADD_CHALLAN = 'ADD_CHALLAN';
export const ADD_CHALLAN_SUCCESS = 'ADD_CHALLAN_SUCCESS';
export const ADD_CHALLAN_FAIL = 'ADD_CHALLAN_FAIL';

export const STORE_CHALLAN_DATA = 'STORE_CHALLAN_DATA';
export const UPDATE_CHALLAN_STOCK = 'UPDATE_CHALLAN_STOCK';

export const UPDATE_CHALLAN_DETAILS = 'UPDATE_CHALLAN_DETAILS';

export const CHALLAN_DELETE = 'CHALLAN_DELETE';
export const CHALLAN_DELETE_SUCCESS = 'CHALLAN_DELETE_SUCCESS';
export const CHALLAN_DELETE_FAIL = 'CHALLAN_DELETE_FAIL';

export const RETURN_CHALLAN = 'RETURN_CHALLAN';
export const RETURN_CHALLAN_SUCCESS = 'RETURN_CHALLAN_SUCCESS';
export const RETURN_CHALLAN_FAIL = 'RETURN_CHALLAN_FAIL';

export const ADD_DH_CHALLAN = 'ADD_DH_CHALLAN';
export const ADD_DH_CHALLAN_SUCCESS = 'ADD_DH_CHALLAN_SUCCESS';
export const ADD_DH_CHALLAN_FAIL = 'ADD_DH_CHALLAN_FAIL';

export const GET_DAMAGE_CHALLAN = 'GET_DAMAGE_CHALLAN';
export const GET_DAMAGE_CHALLAN_SUCCESS = 'GET_DAMAGE_CHALLAN_SUCCESS';
export const GET_DAMAGE_CHALLAN_FAIL = 'GET_DAMAGE_CHALLAN_FAIL';

export const GET_MISSING_CHALLAN = 'GET_MISSING_CHALLAN';
export const GET_MISSING_CHALLAN_SUCCESS = 'GET_MISSING_CHALLAN_SUCCESS';
export const GET_MISSING_CHALLAN_FAIL = 'GET_MISSING_CHALLAN_FAIL';

export const GET_BUFFER_CHALLAN = 'GET_BUFFER_CHALLAN';
export const GET_BUFFER_CHALLAN_SUCCESS = 'GET_BUFFER_CHALLAN_SUCCESS';
export const GET_BUFFER_CHALLAN_FAIL = 'GET_BUFFER_CHALLAN_FAIL';

export const GET_SETTLEMENT_CHALLAN = 'GET_SETTLEMENT_CHALLAN';
export const GET_SETTLEMENT_CHALLAN_SUCCESS = 'GET_SETTLEMENT_CHALLAN_SUCCESS';
export const GET_SETTLEMENT_CHALLAN_FAIL = 'GET_SETTLEMENT_CHALLAN_FAIL';

export const GET_ISSUE_LIST = 'GET_ISSUE_LIST';
export const GET_ISSUE_LIST_SUCCESS = 'GET_ISSUE_LIST_SUCCESS';
export const GET_ISSUE_LIST_FAIL = 'GET_ISSUE_LIST_FAIL';

export const CLAIM_CHALLAN = 'CLAIM_CHALLAN';
export const CLAIM_CHALLAN_SUCCESS = 'CLAIM_CHALLAN_SUCCESS';
export const CLAIM_CHALLAN_FAIL = 'CLAIM_CHALLAN_FAIL';

export const UPDATE_CHALLAN_IMAGE = 'UPDATE_CHALLAN_IMAGE';
export const UPDATE_CHALLAN_IMAGE_SUCCESS = 'UPDATE_CHALLAN_IMAGE_SUCCESS';
export const UPDATE_CHALLAN_IMAGE_FAIL = 'UPDATE_CHALLAN_IMAGE_FAIL';