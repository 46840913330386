import { useFormik } from "formik";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Input, Row, Table, Spinner, Form } from "reactstrap";
import AddCardComponent from "../Layout/AddCardComponent";
import InnerLayer from "../Layout/InnerLayer";
import { addChallanSchema } from "../Schemas/AddChallan.schema";
import { useDispatch, useSelector } from "react-redux";
import {
  getChallanDetails,
  storeChallanData,
  addChallanConfirmation,
} from "../../store/Challan/action";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Question from "../../assests/images/question.svg";
import { Button, Modal } from "react-bootstrap";
import { image } from "../../helpers/api_helper";
import FileUpload from "../Common/FileUpload";
import { RecieveChallanSchema } from "../Schemas/RecieveChallan.schema";
const breadcrumbItems = [
  {
    title: "Challan",
    link: "#",
  },
  {
    title: "Update Challan",
    link: "#",
  },
];
const initialValues = {
   photo: ""

};
const deliveryFrom = [
  {
    name: "WareHouse",
    _id: 1,
    value: "Warehouse",
  },
  {
    name: "Vendor",
    _id: 2,
    value: "Vendor",
  },
];
const SampleEditChallan = () => {
  const { id } = useParams();

  const history = useHistory();
  let dispatch = useDispatch();
  const [deliveryfrom, setDeliveryFrom] = useState("");
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [imageUp, setImageUp] = useState("");
  const handleClose = () => setShow(false);
  const [recieveData, setRecieveData] = useState(null)
  // const handleShow = () => {
  //   setShow(true)
  // };

  const {
    role,
    authtoken,
    challanDetails,
    userId,
    callanRecieved,
    approveChallanLoading,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    role: state.Login.userrole,
    callanRecieved: state.ChallanReducer.callanRecieved,
    challanDetails: state.ChallanReducer.challanDetails,
    approveChallanLoading: state.ChallanReducer.approveChallanLoading,
    userId: state.Login.warehouse,
  }));
  useEffect(() => {
    dispatch(getChallanDetails(authtoken, id));
  }, []);



  const onSubmit = (values) => {
    setShow(true)
    console.log("values", values);
    let missingValues;
    let errorInfo = [];
    const newProductList = callanRecieved.map((data) => ({
      id: data?._id,
      productId: data?.itemId?._id,
      receivedQty:
        data?.damageQtyData * 1 > 0
          ? data?.receivedQtyData * 1 - data?.damageQtyData * 1
          : data?.receivedQtyData * 1,
      missingQty: data?.missingQty,
      damageQty: data?.damageQtyData * 1 || 0,
      actualReceive: data?.receivedQtyData * 1,
      bufferQty: data?.bufferQty,
      missingStatus: data?.missingStatus || false,
      damageStatus: data?.damageStatus || false,
      bufferStatus: data?.bufferStatus || false,
      settlement:
        data?.missingStatus === false &&
        data?.damageStatus === false &&
        data?.bufferStatus === false
          ? "YES"
          : "NO",

      claimStatus: data?.claimStatus,
    }));

    const body = {
      hubId: challanDetails?.data?.challan?.distributionHubId?._id,
      productList: newProductList,
      // InventoryOperation: challanDetails?.data?.challan?.InventoryOperation,
    };
    console.log("newProductList", newProductList);
    newProductList.map((data) => {
      if (data?.missingQty > 0) {
        body.missingStatus = true;
      }
      if (data?.damageQty > 0) {
        body.damageStatus = true;
      }
      if (data?.bufferQty > 0) {
        body.bufferStatus = true;
      }
      if (data?.settlement === "NO") {
        body.settlement = "NO";
      }
    });
    if (missingValues) {
      body.missing = missingValues;
    }
    body.receiveImage = values?.photo;
    console.log("bbbbb", body);
    setRecieveData(body)
    // dispatch(addChallanConfirmation(body, history, authtoken, id));
  };
  console.log('recieveData', recieveData);

 
 
 
  //   console.log("e.target.files", e.target.files);
  //   try {
  //     const url = `product/upload-image`;
  //     const formData = new FormData();
  //     formData.append("name", e.target.files[0]?.name);
  //     formData.append("photo", e.target.files[0]);
  //     const response = await image(url, formData, authtoken);
  //     console.log("responseImage", response);
  //     setImageUp(response?.file);
  //   } catch (err) {
  //     toast("error", "Upload Image failed");
  //   }
  // };

  console.log('receiveChallan', recieveData);
  const handleConfirm = ()=>{
    dispatch(addChallanConfirmation(recieveData, history, authtoken, id));

  }

  const handleSubmitQtyEdit = (e, idx, data) => {
    const oldValue = [...callanRecieved];
    console.log("oldValue", oldValue);

    oldValue[idx].receivedQtyData = e.target.value;
    if (e.target.value * 1 > oldValue[idx]?.quantity) {
      oldValue[idx].missingQty = 0;
    } else {
      oldValue[idx].missingQty = oldValue[idx]?.quantity - e.target.value * 1;
    }
    if (e.target.value * 1 < oldValue[idx]?.quantity) {
      oldValue[idx].bufferQty = 0;
    } else {
      oldValue[idx].bufferQty = e.target.value * 1 - oldValue[idx]?.quantity;
    }
    console.log("oldValue[idx].missingQty", oldValue[idx].missingQty);
    if (oldValue[idx].missingQty > 0) {
      // oldValue[idx].errorStatus = [...errorList, "missing"];
      oldValue[idx].missingStatus = true;
    } else {
      oldValue[idx].missingStatus = false;
    }

    if (oldValue[idx].bufferQty > 0) {
      oldValue[idx].bufferStatus = true;
    } else {
      oldValue[idx].bufferStatus = false;
    }

    dispatch(storeChallanData("callanRecieved", oldValue));
    console.log("oldValue", oldValue);
  };
  const handleDamageQty = (e, idx, data) => {
    const oldValue = [...callanRecieved];
    console.log("oldValue", oldValue);
    console.log("e.target.value", e.target.value);

    if (e.target.value) {
      oldValue[idx].damageQtyData = e.target.value;
    } else {
      oldValue[idx].damageQtyData = 0;
    }
    if (oldValue[idx].damageQtyData > 0) {
      oldValue[idx].damageStatus = true;
      oldValue[idx].claimStatus = false;
    } else {
      oldValue[idx].damageStatus = false;
    }
    // else {
    //   oldValue[idx].damageStatus = false;
    // }

    dispatch(storeChallanData("callanRecieved", oldValue));
  };
  const handleChange = (e, idx, data) => {
    const oldValue = [...callanRecieved];
    oldValue[idx].IsChecked = e.target.checked;
    if (oldValue[idx]?.IsChecked === false) {
      oldValue[idx].damageQtyData = 0;
    }
    dispatch(storeChallanData("callanRecieved", oldValue));
  };
  
  const receiveChallan = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: RecieveChallanSchema,
    onSubmit,
    // handleDelivery,
  });
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Update Challan"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="9" className="mx-auto">
              {/* add User */}
              <AddCardComponent className="">
                <Row className="mt-4 mb-4 ">
                  <Col md="6">
                    <h6 className="text-black">Challan No.</h6>
                    <span className="">
                      {challanDetails?.data?.challan?.challanNo}
                    </span>
                  </Col>
                  <Col md="6">
                    <h6 className="text-black">Challan Date</h6>
                    {/* <span>10/05/2022</span> */}
                  </Col>
                </Row>
                <Row className="mb-4 ">
                  <Col md="6">
                    <h6 className="text-black">Delivery Source</h6>
                    <span>{challanDetails?.data?.challan?.deliveryFrom}</span>
                  </Col>
                  <Col md="6">
                    <h6 className="text-black">Vendor</h6>
                    <span>Vendor1</span>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md="6">
                    <h6 className="text-black">Central Warehouse</h6>
                    <span>
                      {challanDetails?.data?.challan?.centralWarehouseId?.name}
                    </span>
                  </Col>
                  <Col md="6">
                    <h6 className="text-black">Note</h6>
                    <span>{challanDetails?.data?.challan?.note}</span>
                  </Col>
                </Row>
                <Form
                  className="needs-validation col-md-12"
                  onSubmit={receiveChallan.handleSubmit}
                >
                  <Row>
                    <h6 className="mt-3">Product List</h6>
                    <div className="table-responsive">
                      <Table className="table table-hover mb-0 table-centered table-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th>Sl. No.</th>
                            {/* <th>Category</th>
                          <th>Brand</th> */}
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Receive QTY</th>
                            <th>Damage</th>
                            <th>Missing QTY</th>
                            <th>Buffer QTY</th>
                            <th>Damage QTY</th>
                          </tr>
                        </thead>
                        {(callanRecieved || []).map((data, key) => {
                          return (
                            <>
                              <tbody>
                                <tr>
                                  <td>{key + 1}</td>
                                  {/* <td>{data?.itemId?.category?.name}</td>
                                <td>{data?.itemId?.brand?.name}</td> */}
                                  <td>{data?.itemId?.name}</td>
                                  <td>{data?.quantity}</td>
                                  <td>
                                    <Input
                                      type="text"
                                      value={data?.receivedQtyData}
                                      style={{ width: "80px", height: "30px" }}
                                      onChange={(e) =>
                                        handleSubmitQtyEdit(e, key, data)
                                      }
                                    ></Input>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        type="checkbox"
                                        className="me-2"
                                        style={{
                                          height: "15px",
                                          width: "15px",
                                        }}
                                        onChange={(e) =>
                                          handleChange(e, key, data)
                                        }
                                      />
                                      {data?.IsChecked === true ? (
                                        <Input
                                          type="text"
                                          style={{
                                            width: "80px",
                                            height: "30px",
                                          }}
                                          onChange={(e) =>
                                            handleDamageQty(e, key, data)
                                          }
                                        ></Input>
                                      ) : (
                                        <span></span>
                                      )}
                                    </div>
                                  </td>
                                  <td>{data?.missingQty}</td>
                                  <td>{data?.bufferQty}</td>
                                  <td>{data?.damageQtyData}</td>
                                </tr>
                              </tbody>
                            </>
                          );
                        })}
                      </Table>
                      {/* <Col md="6 mb-3 mt-5">
                      <Input
                        name={"photo"}
                        type="file"
                        label={"Image"}
                        onChange={(e) => handlePhoto(e)}
                        // validationType={AddChallan}
                      />
                    </Col> */}

                      <FileUpload
                        name="photo"
                        label={"Upload Image"}
                        type="file"
                        isRequired
                        validationType={receiveChallan}
                      />
                    </div>
                    {/* table end */}
                  </Row>
                  {approveChallanLoading ? (
                  <div className="d-flex justify-content-end mt-3">
                    <Spinner className="ms-2" color="primary" />
                  </div>
                ) : (
                  <div className="d-flex justify-content-end mt-3">
                    {challanDetails?.data?.challan?.status === "Pending" ? (
                      <button
                        type="submit"
                        // onClick={handleShow}
                        className="btn button "
                      >
                        Recieved
                      </button>
                    ) : (
                      <span></span>
                    )}
                  </div>
                )}
                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header
                      closeButton
                      style={{ borderBottom: "none" }}
                    ></Modal.Header>
                    <Modal.Body className="text-center">
                      <div className="mb-4">
                        <img
                          src={Question}
                          alt="Image"
                          style={{ width: "76px" }}
                        />
                      </div>
                      <h5>Are you want to confirm this challan?</h5>
                    </Modal.Body>
                    <Modal.Footer
                      style={{ borderTop: "none", justifyContent: "center" }}
                      className="mb-4"
                    >
                      <Button
                        variant="outline-secondary"
                        onClick={handleClose}
                        style={{ width: "112px" }}
                      >
                        Cancel
                      </Button>
                      {approveChallanLoading ? (
                        <div className="d-flex justify-content-end mt-3">
                          <Spinner className="ms-2" color="primary" />
                        </div>
                      ) : (
                     

                        <Button className="btn button" onClick={handleConfirm}>
                          Yes, I’m Sure
                        </Button>
                      )}
                    </Modal.Footer>
                  </Modal>
                  <button type="submit" className="d-none"></button>
                </Form>

          
              </AddCardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  );
};

export default SampleEditChallan;
