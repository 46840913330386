import {
    GET_CENTRAL_WAREHOUSE,
    GET_CENTRAL_WAREHOUSE_SUCCESS,
    GET_CENTRAL_WAREHOUSE_FAIL,
    GET_DISTRIBUTION_HUB_INV,
    GET_DISTRIBUTION_HUB_INV_SUCCESS,
    GET_DISTRIBUTION_HUB_INV_FAIL,
    GET_BP_INVENTORY,
    GET_BP_INVENTORY_SUCCESS,
    GET_BP_INVENTORY_FAIL,
    GET_CW_STOCK_REPORT,
    GET_CW_STOCK_REPORT_SUCCESS,
    GET_CW_STOCK_REPORT_FAIL,
    GET_DH_STOCK_REPORT,
    GET_DH_STOCK_REPORT_SUCCESS,
    GET_DH_STOCK_REPORT_FAIL
    

  
    } from "./actionTypes";
    
    export const getCentalWarehouse = (
      authtoken,
      startDate,
      endDate,
      csDistribution,
      pageRange,
      currentPage,
      category,
      brand,
      product
    ) => ({
      type: GET_CENTRAL_WAREHOUSE,
      payload: { authtoken, startDate, endDate, csDistribution, pageRange, currentPage, category, brand, product },
    });
    
    export const getCentalWarehouseSuccess = (data) => ({
      type: GET_CENTRAL_WAREHOUSE_SUCCESS,
      payload: { data },
    });
    export const getCentalWarehouseFail = (error) => ({
      type: GET_CENTRAL_WAREHOUSE_FAIL,
      payload: error,
    });
    export const getDistributionHubInv = (authtoken,startDate, endDate, category, brand, product, distribution, pageRange, currentPage) => ({
      type: GET_DISTRIBUTION_HUB_INV,
      payload: { authtoken,startDate, endDate, category, brand, product, distribution, pageRange, currentPage },
    });
    
    export const getDistributionHubInvSuccess = (data) => ({
      type: GET_DISTRIBUTION_HUB_INV_SUCCESS,
      payload: data,
    });
    
    export const getDistributionHubInvFail = (error) => ({
      type: GET_DISTRIBUTION_HUB_INV_FAIL,
      payload: error,
    });

    export const getBpInventory = (authtoken, id) => ({
        type: GET_BP_INVENTORY,
        payload: { authtoken, id },
      });
      
      export const getBpInventorySuccess = (data) => ({
        type: GET_BP_INVENTORY_SUCCESS,
        payload: data,
      });
      
      export const getBpInventoryFail = (error) => ({
        type: GET_BP_INVENTORY_FAIL,
        payload: error,
      });
      export const getCwStockReport = (data, history, authtoken) => ({
        type: GET_CW_STOCK_REPORT,
        payload: { data, history, authtoken },
      });
      
      export const getCwStockReportSuccess = (data) => ({
        type: GET_CW_STOCK_REPORT_SUCCESS,
        payload: data,
      });
      
      export const getCwStockReportFail = (error) => ({
        type: GET_CW_STOCK_REPORT_FAIL,
        payload: error,
      });

      export const getDhStockReport = (data, history, authtoken) => ({
        type: GET_DH_STOCK_REPORT,
        payload: { data, history, authtoken },
      });
      
      export const getDhStockReportSuccess = (data) => ({
        type: GET_DH_STOCK_REPORT_SUCCESS,
        payload: data,
      });
      
      export const getDhStockReportFail = (error) => ({
        type: GET_DH_STOCK_REPORT_FAIL,
        payload: error,
      });
    
  
    
 
   
    
    