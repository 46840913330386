import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Col, Form, Input, Row, Spinner, Table } from "reactstrap";
import addCircle from "../../assests/images/add-circle.png";
import closeCircle from "../../assests/images/close-circle.png";
import { toaster } from "../../helpers/custom/toaster";
import {
  getDistributionHub,
  getInvOption,
} from "../../store/Distribution Hub/actions";
import {
  addProducStock,
  getFilteringProduct
} from "../../store/Product/actions";
import AddFileUpload from "../Common/AddFileUpload";
import CustomInput from "../Common/CustomInput";
import AddCardComponent from "../Layout/AddCardComponent";
import InnerLayer from "../Layout/InnerLayer";
import { AddStockSchema } from "../Schemas/AddStockSchema";
const breadcrumbItems = [
  {
    title: "Stock In",
    link: "#",
  },
  {
    title: "Create Stock In",
    link: "#",
  },
];
const initialValues = {
 inventoryOpt: "",
  warehouse: "",
};
const HUbType = [
  {
    name: "Central WareHouse",
    _id: 1,
    value: "Central",
  },
  {
    name: "Distribution Hub",
    _id: 2,
    value: "Hub",
  },
];
const AddSampleStock = () => {
  const history = useHistory();
  let dispatch = useDispatch();
  const [centralWareHouse, setcentralWareHouse] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [loadingStocking, setloadingStocking] = useState(false);
  const [stockInfo, setstockInfo] = useState({
    itemId: "",
    quantity: "",
    name: "",
  });
  const [singleStock, setSingleStock] = useState([]);
  const addedStock = singleStock.map((data) => data?.itemId);
  console.log("stockInfo", stockInfo);
  console.log("singleStock", singleStock);
  const {
    distributionHubData,
    loading,
    authtoken,
    products,
    productLoading,
    inventoryOperation,
    productList: productData,
    filteredProductLoading,
    stockLoading,
    role,
    userID,
  } = useSelector((state) => ({
    distributionHubData: state.DistributionReducer.distributionHubData,
    inventoryOperation: state.DistributionReducer.inventoryOperation,
    products: state.ProductReducer.products,
    role: state.Login.userrole,
    filteredProductLoading: state.ProductReducer.filteredProductLoading,
    productList: state.ProductReducer.productList,
    authtoken: state.Login.token,
    stockLoading: state.ProductReducer.stockLoading,
    userID: state.Login.warehouse,
  }));

  console.log("products", products);

  useEffect(() => {
    if (role !== "CENTRALWAREHOUSE") {
      dispatch(getDistributionHub(authtoken, 0, 0, "Central", ""));
    }
  }, []);
  useEffect(() => {
    dispatch(getInvOption(authtoken));
  }, []);
  console.log("inventoryOperation", inventoryOperation);
  const onSubmit = (values) => {
    if (role !== "CENTRALWAREHOUSE") {
      let body = {
        ...values,
        productList: singleStock,
      };
      console.log("body", body);
      if (body.productList.length !== 0) {
        dispatch(addProducStock(body, history, authtoken));
      } else {
        toast("Please Select Quantity", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      let body = {
        // inventoryOpt: values.inventoryOpt,
        productList: singleStock,
        warehouse: userID,
      };
      console.log("body", body);
      if (body.productList.length !== 0) {
         dispatch(addProducStock(body, history, authtoken));
      } else {
        toast("Please Select Quantity", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  console.log("products", productData);
  useEffect(() => {
    dispatch(getFilteringProduct(authtoken, 500, 0, "", "", ""));
  }, []);
  const AddStock = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddStockSchema,
    onSubmit,
  });

  const handleAddProductInput = () => {
    if (stockInfo.itemId === "" || stockInfo.quantity === "") {
      return toaster("warning", "Please fill all the fields");
    }
    if ( stockInfo.quantity <= 0) {
      return toaster("warning", "Stock Can not be negative or zero");
    }
    
    let data = {
      itemId: stockInfo?.itemId,
      stock: stockInfo?.quantity *1,
      name: stockInfo?.name,
      brand: stockInfo?.brand,
    };
    console.log("🚀 ~ file: AddSampleStock.js:168 ~ handleAddProductInput ~ data:", data, stockInfo)

    setSingleStock([...singleStock, data]);
    setstockInfo({
      itemId: "",
      quantity: "",
    });
  };
  console.log("singleStock", singleStock);
  const handleRemoveProduct = (data) => {
    console.log("remove", data);
    const removeProd = singleStock.filter(
      (prod) => data?.itemId !== prod?.itemId
    );
    console.log("removeProd", removeProd);
    setSingleStock(removeProd);
  };
  const handleStockInput = (e) => {
    
    const inputValue = e.target.value
    if (inputValue === '' || /^\d+$/.test(inputValue)) {
      console.log(inputValue)
      setstockInfo({
        ...stockInfo,
        quantity: inputValue ,
      });
    }

         
    // if (e.target.value  <= 0) {
    //   toast("Quantity can not be zero or negative", {
    //     position: "top-right",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else {
    //   setstockInfo({
    //     ...stockInfo,
    //     quantity: e.target.value ,
    //   });
    // }
  };

  const handleFile = uploadData => {
    console.log(uploadData)
  }

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      };
    },
  };

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Stock In"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="11" className="mx-auto">
              {/* add User */}
              <AddCardComponent className="">
                <Form
                  className="needs-validation col-md-12"
                  onSubmit={AddStock.handleSubmit}
                >
                  <Row>
                    {
                      role !== "CENTRALWAREHOUSE" ?
                      <Col md="6 mb-2">
                      <CustomInput
                        name={"inventoryOpt"}
                        type={"select"}
                        label={"Select  Option"}
                        validationType={AddStock}
                      >
                        <option defaultValue>Select Option...</option>
                        {inventoryOperation?.data?.length > 0 ? (
                          inventoryOperation?.data?.operation?.map(
                            (data, idx) => (
                              <option value={data._id} key={idx}>
                                {data?.operationName}
                              </option>
                            )
                          )
                        ) : (
                          <span></span>
                        )}
                      </CustomInput>
                    </Col>: 
                    <span></span>
                    }
                 
                    {role !== "CENTRALWAREHOUSE" ? (
                      <Col md="6 mb-2">
                        <CustomInput
                          name={"warehouse"}
                          type={"select"}
                          label={"Select Central WareHouse"}
                          validationType={AddStock}
                        >
                          <option defaultValue>
                            Select Central WareHouse...
                          </option>
                          {distributionHubData?.data?.length > 0 ? (
                            distributionHubData?.data?.Warehouse?.map(
                              (data, idx) => (
                                <option value={data._id} key={idx}>
                                  {data.name}
                                </option>
                              )
                            )
                          ) : (
                            <span></span>
                          )}
                        </CustomInput>
                      </Col>
                    ) : (
                      <span></span>
                    )}
                  </Row>
                  <Row>
                    <h6 className="mt-3 mb-4 fw-bold">
                      Add New Product in Stock
                    </h6>
                    <div className="">
                      <Table className="table table-hover mb-0 table-centered  mb-3">
                        <thead className="table-light">
                          <tr>
                            <th>No.</th>
                            <th>Product</th>
                            <th>QTY</th>
                            <th>Brand</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            {filteredProductLoading ? (
                              <div className="text-center mt-5 pt-5">
                                <Spinner />
                              </div>
                            ) : (
                              <td
                                style={{
                                  width: "300px",
                                }}
                              >
                                <Select
                                  name=""
                                  className="mb-2"
                                  classNamePrefix="select2-selection"
                                  placeholder="Select Product"
                                  cacheOptions
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.value}
                                  styles={colourStyles}
                                  value={
                                    productData?.find(
                                      (data) => stockInfo?.itemId === data?._id
                                    ) || {}
                                  }
                                  isClearable={productData?.find(
                                    (data) => stockInfo?.itemId === data?._id
                                  )}
                                  options={productData.filter(
                                    (data) => !addedStock.includes(data?._id)
                                  )}
                                  onChange={(e) => {
                                    setstockInfo({
                                      ...stockInfo,
                                      itemId: e?._id || "",
                                      name: e?.name,
                                      brand: e?.brand?.name,
                                    });
                                  }}
                                />
                              </td>
                            )}

                            <td>
                              <Input
                                type="number"
                                style={{
                                  width: "100px",
                                  height: "38px",
                                }}
                                value={stockInfo?.quantity}
                                onChange={(e) => {
                                  handleStockInput(e);
                                }}
                              ></Input>
                              
                            </td>
                            <td>{stockInfo?.brand}</td>
                            <td>
                              {stockLoading ? (
                                "Adding..."
                              ) : (
                                <img
                                  src={addCircle}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  className="img-fluid"
                                  onClick={() =>
                                    stockLoading ? {} : handleAddProductInput()
                                  }
                                />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                  <div className="table-responsive">
                    <h6 className="mb-3 fw-bold">Added Product in stock</h6>
                    <Table className="table table-hover mb-0 table-centered  mb-5">
                      <thead className="table-light">
                        <tr>
                          <th>No.</th>
                          <th>Product</th>
                          <th>Brand</th>
                          <th>QTY</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {false ? (
                          <tr>
                            <td colSpan="5">
                              <h6 className="text-center">Loading...</h6>
                            </td>
                          </tr>
                        ) : (
                          (singleStock || []).map((data, key) => {
                            return (
                              <tr key={key}>
                                <td>{key + 1}</td>

                                <td>{data?.name}</td>
                                <td>{data?.brand}</td>
                                <td>{data?.stock}</td>
                                <td>
                                  <img
                                    src={closeCircle}
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    className="img-fluid"
                                    onClick={() => handleRemoveProduct(data)}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        )}
                        <tr>
                          <td></td>
                          <td></td>
                        

                          <td colSpan="1">
                            {" "}
                            <h6 className="text-end">Total Quantity:</h6>
                          </td>

                          <td>
                            <h6>
                              {" "}
                              {singleStock?.reduce(
                                (total, item) => total + item?.stock * 1,
                                0
                              )}
                            </h6>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  
                    <div className="d-flex justify-content-end mt-3">
                      <input
                        type="submit"
                        disabled={stockLoading}
                        value={stockLoading ? "Submitting" : "Add Stock"}
                        className="btn button "
                      />
                    </div>
                  </div>
                </Form>
              </AddCardComponent>
              <AddFileUpload setSelectedFiles={setSelectedFiles} handleFile={handleFile} />
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  );
};

export default AddSampleStock;
