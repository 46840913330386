import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useInfiniteScroll from '../../Hooks/useInfiniteScroll';
import CustomDropDown from '../Common/CustomFilter/DropDown';

const SelectBp = ({ selectedBp, handleAdd }) => {
  console.log(
    `🦄 ~ file: SelectBp.js ~ line 7 ~ SelectBp ~ selectedBp`,
    selectedBp
  );
  const [reRender] = useState(false);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');

  const [sizesOpen, setSizesOpen] = useState(false);

  const { authToken } = useSelector((state) => ({
    role: state.Login.userrole,
    authToken: state.Login.token,
  }));

  const { loading, error, data, lastElementRef, handleSearch } =
    useInfiniteScroll({
      method: 'Get',
      url: `/users/get-users?currentpage=${
        page + 1
      }&useremployee=${query}&role=USER&pageRange=15`,
      body: {},
      token: authToken,
      name: 'users',
      responseCallback: (data) => {
        console.log('CallBack', data);
      },
      query,
      page,
      setQuery,
      setPage,
      reRender,
    });

  const handleSelectData = (data) => {
    setQuery("")
    handleAdd(data);
    setSizesOpen(!sizesOpen);
  };

  //   console.log(`AreaFilter ~ loading`, loading, errorMessage);

  return (
    <React.Fragment>
      <CustomDropDown
        toggle={() => {
          setSizesOpen(!sizesOpen);
          setQuery('');
        }}
        open={sizesOpen}
        onChange={handleSearch}
        searchValue={query}
        isSelected={selectedBp}
        handleClear={() => handleAdd('')}
        selectedItem={
          data?.find((list) => list?._id === selectedBp)?.name || 'Select Bp'
        }
      >
        <div style={{ width: '100%', height: '100%' }}>
          {data?.map((i, index) => (
            <div
              key={index}
              onClick={() => handleSelectData(i)}
              style={{ cursor: 'pointer' }}
              className={'grey dropDownItem'}
              ref={data.length === index + 1 ? lastElementRef : null}
            >
              <span
                style={{
                  margin: '2px',
                  padding: '4px',
                  display: 'block',
                }}
              >
                {i?.name}
              </span>
            </div>
          ))}
          {loading ? (
            <div className={'py-1 text-center'}>Loading...</div>
          ) : null}
          {error ? <div className={'py-1 text-center'}>Error</div> : null}
        </div>
      </CustomDropDown>
    </React.Fragment>
  );
};

export default SelectBp;
