import React from 'react';
import DemoImage from '../../assests/images/Frame.svg'

const StockDemo = () => {
    return (
        <div className='page-content'>
            <div  className="text-center mt-5" >
                
                <img src={DemoImage} />
                <h4 className='fw-bold mt-3'>Premium Features, Please Contact Us</h4>
                <a target="_blank" href="https://maacsolutions.com/contact" rel="noreferrer"><button className='btn btn-primary mt-2'>Contact Us</button> </a>
                

            </div>
           
        </div>
    );
};

export default StockDemo;