import {
  ADD_CHALLAN,
  ADD_DH_CHALLAN,
  CHALLAN_APPROVED,
  CHALLAN_DELETE,
  CHALLAN_DELETE_FAIL,
  CHALLAN_DELETE_SUCCESS,
  CLAIM_CHALLAN,
  GET_BUFFER_CHALLAN_FAIL,
  GET_BUFFER_CHALLAN_SUCCESS,
  GET_CHALLAN,
  GET_CHALLAN_DETAILS,
  GET_CHALLAN_DETAILS_FAIL,
  GET_CHALLAN_DETAILS_SUCCESS,
  GET_CHALLAN_FAIL,
  GET_CHALLAN_STOCK_LIST_FAIL,
  GET_CHALLAN_STOCK_LIST_SUCCESS,
  GET_CHALLAN_SUCCESS,
  GET_DAMAGE_CHALLAN_FAIL,
  GET_DAMAGE_CHALLAN_SUCCESS,
  GET_ISSUE_LIST_FAIL,
  GET_ISSUE_LIST_SUCCESS,
  GET_MISSING_CHALLAN_FAIL,
  GET_MISSING_CHALLAN_SUCCESS,
  GET_SETTLEMENT_CHALLAN_FAIL,
  GET_SETTLEMENT_CHALLAN_SUCCESS,
  RETURN_CHALLAN,
  STORE_CHALLAN_DATA,
  UPDATE_CHALLAN_DETAILS,
  UPDATE_CHALLAN_IMAGE
} from './actionTypes';
const INIT_STATE = {
  callanRecieved: [],
  challanListStock: [],
  challanData: [],
  challanDetails: [],
  stokeList: [],
  returnChallan:[],
  challanDetailsInfo:[],
  damageChallan: [],
  missingChallan: [],
  bufferChallan: [],
  challanSettlement: [],
  issueList: [],
  damageChallan: [],
  challanDataLoading: true,
  challanDetailsLoading: false,
  stokeListLoading: true,
  addChallanLoading: false,
  approveChallanLoading: false,
  returnChallanLoading: false,
  addDhChallanLoading: false,
  damageChallanLoading: true,
  missingChallanLoading: true,
  bufferChallanLoading: true,
  challanSettlementLoading: true,
  issueListLoading: true,
  claimChallanLoading: false,
  updateChallanImageLoading: false
};
const ChallanReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_CHALLAN:
      return {
        ...state,
        addChallanLoading: true,
      };
      case CLAIM_CHALLAN:
        return {
          ...state,
          claimChallanLoading: true,
        };
        case UPDATE_CHALLAN_IMAGE:
          return {
            ...state,
            updateChallanImageLoading: true,
          };
      case ADD_DH_CHALLAN:
        return {
          ...state,
          addDhChallanLoading: true,
        };
    case UPDATE_CHALLAN_DETAILS:
      return {
        addChallanLoading: true,
      };

    case CHALLAN_APPROVED:
      return {
        ...state,
        approveChallanLoading: true,
      };
      case GET_CHALLAN:
        return {
          ...state,
        
          challanDataLoading: true,
        };

    case GET_CHALLAN_SUCCESS:
      return {
        ...state,
        challanData: action.payload,
        challanDataLoading: false,
      };
    case GET_CHALLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        challanData: {},
        challanDataLoading: false,
      };
      case GET_CHALLAN_DETAILS:
        return {
          ...state,
       
          challanDetailsLoading: true,
        };
    case GET_CHALLAN_DETAILS_SUCCESS:
      return {
        ...state,
        challanDetails: action.payload,
        challanDetailsLoading: false,
      };
    case GET_CHALLAN_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        challanDetailsLoading: false,
      };

    case GET_CHALLAN_STOCK_LIST_SUCCESS:
      return {
        ...state,
        stokeList: action.payload,
        stokeListLoading: false,
      };
    case GET_CHALLAN_STOCK_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        stokeListLoading: false,
      };
    case STORE_CHALLAN_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    case CHALLAN_DELETE:
      return {
        ...state,
        addChallanLoading: true,
      };
    case CHALLAN_DELETE_SUCCESS:
      return {
        ...state,
        addChallanLoading: false,
      };
    case CHALLAN_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
        addChallanLoading: false,
      };
      case RETURN_CHALLAN:
        return {
          ...state,
          returnChallanLoading: true,
        };
  
      // case RETURN_CHALLAN_SUCCESS:
      //   return {
      //     ...state,
      //     returnChallan: action.payload,
      //     returnChallanLoading: false,
      //   };
      // case RETURN_CHALLAN_FAIL:
      //   return {
      //     ...state,
      //     error: action.payload,
      //     returnChallanLoading: false,
      //   };

      case GET_BUFFER_CHALLAN_SUCCESS:
        return {
          ...state,
          bufferChallan: action.payload,
          bufferChallanLoading: false,
        };
      case GET_BUFFER_CHALLAN_FAIL:
        return {
          ...state,
          error: action.payload,
          bufferChallanLoading: false,
        };
        case GET_MISSING_CHALLAN_SUCCESS:
          return {
            ...state,
            missingChallan: action.payload,
            missingChallanLoading: false,
          };
        case GET_MISSING_CHALLAN_FAIL:
          return {
            ...state,
            error: action.payload,
            missingChallanLoading: false,
          };
          case GET_DAMAGE_CHALLAN_SUCCESS:
            return {
              ...state,
              damageChallan: action.payload,
              damageChallanLoading: false,
            };
          case GET_DAMAGE_CHALLAN_FAIL:
            return {
              ...state,
              error: action.payload,
              damageChallanLoading: false,
            };
            case GET_SETTLEMENT_CHALLAN_SUCCESS:
              return {
                ...state,
                challanSettlement: action.payload,
                challanSettlementLoading: false,
              };
            case GET_SETTLEMENT_CHALLAN_FAIL:
              return {
                ...state,
                error: action.payload,
                challanSettlementLoading: false,
              };
              case GET_ISSUE_LIST_SUCCESS:
                return {
                  ...state,
                  issueList: action.payload,
                  issueListLoading: false,
                };
              case GET_ISSUE_LIST_FAIL:
                return {
                  ...state,
                  error: action.payload,
                  issueListLoading: false,
                };
    default:
      return state;
  }
};
export default ChallanReducer;
