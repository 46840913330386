import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Row, Table } from "reactstrap";
import { getDisburseReport, getDisburseHubBp } from "../../../store/Disburse/action";
import { getSingleDistribution } from "../../../store/Distribution Hub/actions";
import { getUserRole } from "../../../store/User/actions";
import CardComponent from "../../Layout/CardComponent";
import CustomTable from "../../Layout/CustomTable";
import InnerLayer from "../../Layout/InnerLayer";
import NoTableData from "../../Layout/NoTableData";
const tableHead = [
  "Sl. No.",
  "BP. Name",
  "Partner",
  "Category",
  "Brand",
  "Product",
  "QTY",
  "Date",
];

const renderTooltipView = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    View
  </Tooltip>
);
const BP = [
  {
    name: "HB 1",
    _id: 1,
    value: "HB 1",
  },
  {
    name: " HB2",
    _id: 2,
    value: "HB2",
  },
];

const StockIn = ({ history }) => {
  let dispatch = useDispatch();
  const [bp, setBp] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(10);
  const [detailShow, setDetailShow] = useState(false);
  const [distribution, setDistribution] = useState("");
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const {
    allUserRole,
    loading,
    authtoken,
    partnerList,
    singleDistribution,
    products,
    adding,
    role,
    disburseReport,
    userId,
    disburseHubBp
  } = useSelector((state) => ({
    allUserRole: state.UserReducer.allUserRole,
    role: state.Login.userrole,
    singleDistribution: state.DistributionReducer.singleDistribution,
    products: state.ProductReducer.products,
    partnerList: state.DisburseReducer.partnerList,
    disburseReport: state.DisburseReducer.disburseReport,
    disburseHubBp: state.DisburseReducer.disburseHubBp,
    adding: state.DisburseReducer.adding,
    userId: state.Login.warehouse,
    authtoken: state.Login.token,
  }));

  useEffect(() => {
    dispatch(getDisburseHubBp(authtoken, role, userId));
  }, []);

  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, "Hub"));
  }, []);
  useEffect(() => {
    dispatch(
      getDisburseReport(
        authtoken,
        startDate,
        bp,
        role === "HUB" ? userId : distribution,
        currentPage,
        pageRange
      )
    );
  }, [startDate, bp, distribution, currentPage, pageRange, userId]);
  let totalPageNumber = Math.ceil(disburseReport?.data?.length / pageRange);
  console.log("disburseReport", disburseReport);
  const handleRole = (value) => {
    console.log("value", value);
    if (value) {
      setBp(value?._id);
    } else {
      setBp("");
    }
  };
  const handleDistributionHub = (value) => {
    if (value) {
      setDistribution(value?._id);
    } else {
      setDistribution("");
    }
  };
  const handleStartDate = (e) => {
    console.log("start", e.target.value);
    const value = e.target.value;
    setStartDate(moment(value).format("YYYY-MM-DD"));
  };
  const handleRange = (e) => {
    setPageRange(e.target.value);
  };

  return (
    <React.Fragment>
      <InnerLayer
        title="Disburse Plan Report"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Disburse Plan"}
        subTitle={"Disburse Plan List"}
        onAddHandler={() => history.push("/add-disburse-plan")}
        buttonText="Create New"
        isButton={role === "SUPERADMIN" ? true : false}
      >
        <Row>
          <CardComponent className="user_table_card">
            <Row className="mb-3 table-header-padding">
              <div className="d-flex justify-content-end">
                <Col md={2} className="mb-2 me-2">
                  <Input
                    name="toDate"
                    type="date"
                    onChange={handleStartDate}
                    value={startDate}
                  />
                </Col>
                <Col md={2}>
                  <Select
                    name="bp"
                    className="mb-2 me-2"
                    classNamePrefix="select2-selection"
                    placeholder="Select BP"
                    cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    options={disburseHubBp?.data?.bpList}
                    onChange={handleRole}
                  />
                </Col>
                <Col md={2} className="me-2">
                  <Input type="select" onChange={(e) => handleRange(e)}>
                    {/* <option defaultValue>Select...</option> */}
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Input>
                </Col>
              </div>
            </Row>
            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={[1, 2, 3]}
                pageNo={totalPageNumber}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {disburseReport?.data?.length > 0 ? (
                  disburseReport?.data?.disbursePlan?.map((data, idx) => (
                    <tr>
                      <th scope="row" style={{ paddingLeft: "25px" }}>
                        {idx + 1}
                      </th>
                      <td>{data?.BPInfo?.name}</td>
                      <td>{data?.partnerInfo?.outletName}</td>
                      <td>{data?.categories?.name}</td>
                      <td>{data?.brand?.name}</td>
                      <td>{data?.products?.name}</td>
                      <td>{data?.itemQuantity}</td>
                      <td>{data?._id?.dateString}</td>
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan=""
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No data Available</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
              {/* modal for detail */}

              <Modal
                size="lg"
                show={detailShow}
                onHide={() => setDetailShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header
                  closeButton
                  style={{ borderBottom: "none" }}
                ></Modal.Header>
                <Modal.Body style={{ padding: "3px 25px" }}>
                  <h4 className="text-center" style={{ color: "#0C1D37" }}>
                    Disburse Plan View
                  </h4>

                  <Row className="mt-5 mb-4 ">
                    <Col md="6">
                      <h6 className="text-black">BP Name</h6>
                      <span className="">Kiaser</span>
                    </Col>
                    <Col md="6">
                      <h6 className="text-black">Date</h6>
                      <span>10/05/2022</span>
                    </Col>
                  </Row>

                  <Row className="mb-5">
                    <Col md="6">
                      <h6 className="text-black">Hub Name</h6>
                      <span>Hb-1</span>
                    </Col>
                  </Row>

                  <h5 style={{ color: "#1C1D1E" }}>Disburse Plan Details</h5>
                  <Table className="table table-hover mb-0 table-centered table-nowrap mb-5">
                    <thead className="table-light">
                      <tr>
                        <th>Sl. No.</th>
                        <th>Shop</th>
                        <th>Product</th>
                        <th>QTY</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>test</td>
                        <td>test</td>
                        <td>23</td>
                      </tr>
                    </tbody>
                  </Table>
                </Modal.Body>
              </Modal>
            </Row>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  );
};

StockIn.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(StockIn);
