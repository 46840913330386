import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import Select from "react-select";
import { Col, Input, Row, Table } from "reactstrap";
import addCircle from "../../assests/images/add-circle.png";
import closeCircle from "../../assests/images/close-circle.png";
import Question from "../../assests/images/question.svg";
import { toaster } from "../../helpers/custom/toaster";
import {
  deleteChallan,
  getChallanDetails,
  getChallanStock,
  updateChallanDetails,
} from "../../store/Challan/action";
import InnerLayer from "../Layout/InnerLayer";
import { getProduct } from "../../store/Product/actions";
const breadcrumbItems = [
  {
    title: "Challan",
    link: "#",
  },
  {
    title: "Update Challan",
    link: "#",
  },
];

// const initialValues = {
//   name: '',
//   region: '',
// };

const AddChallan = () => {
  let dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const {
    params: { id, challanID },
  } = useRouteMatch("/edit-challan/:id/:challanID");
  const [challan, setChallan] = useState({
    itemId: "",
    quantity: "",
    unitPrice: "",
    price: "",
  });
  const {
    authtoken,
    challanDetails,
    challanListStock,
    addChallanLoading,
    challanDetailsLoading,
    products,
  } = useSelector((state) => ({
    challanDetails: state.ChallanReducer.challanDetails,
    authtoken: state.Login.token,
    challanListStock: state.ChallanReducer.challanListStock,
    products: state.ProductReducer.products,
    addChallanLoading: state.ChallanReducer.addChallanLoading,
    challanDetailsLoading: state.ChallanReducer.challanDetailsLoading,
  }));

  console.log("challanDetails", challanDetails);
  console.log("challanListStock", challanListStock);
  console.log("products", products);

  const stockIdList = challanDetails?.data?.challanDetails?.map(
    (data) => data?.itemId?._id
  );
  console.log(`AddChallan ~ stockIdList`, stockIdList);

  // console.log('wareHouseId',wareHouseId);
  useEffect(() => {
    dispatch(getChallanDetails(authtoken, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRemoveProductInput = (data) => {
    setShow(true);
    setSelectedProduct(data);
    // console.log("hello");
  };
  const handleAddProductInput = () => {
    if (challan.itemId === "" || challan.quantity === "") {
      return toaster("warning", "Please fill all the fields");
    }
    let data = {
      challanId: challanDetails?.data?.challan?._id,
      distributionHubId: challanDetails?.data?.challan?.distributionHubId?._id,
      itemId: challan?.itemId,
      quantity: challan?.quantity,
      unitPrice: 500,
      totalAmount: challanDetails?.data?.challan?.totalAmount,
      InventoryOperation: challanDetails?.data?.challan?.InventoryOperation,
      inventoryType: "Stock In",
    };

    if (challanDetails?.data?.challan?.deliveryFrom === "Warehouse") {
      data.centralWarehouseId =
        challanDetails?.data?.challan?.centralWarehouseId?._id;
    }
    // console.log(`handleAddProductInput ~ data`, data);
    dispatch(
      updateChallanDetails(data, { authtoken, id: id, setChallan, challanID })
    );
  };
  useEffect(() => {
    dispatch(getChallanStock(authtoken, challanID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(getProduct(authtoken, challanID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteChallan = (index) => {
    setShow(true);
    const data = {
      deliveryFrom: challanDetails?.data?.challan?.deliveryFrom,
    };
    dispatch(
      deleteChallan(data, {
        authtoken,
        toggle: handleClose,
        id: selectedProduct?._id,
        challanID: id,
        challanId: challanID,
      })
    );
    // console.log(selectedProduct, data);
    // console.log("hello");
  };
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Update Challan"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="9" className="mx-auto">
              {/* add User */}
              <div className="table-responsive">
                <h5 className="mb-3">Existing Product in Challan</h5>
                <Table className="table table-hover mb-0 table-centered  mb-5">
                  <thead className="table-light">
                    <tr>
                      <th>No.</th>
                      <th>Category</th>
                      <th>Brand</th>
                      <th>Product</th>
                      <th>QTY</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {challanDetailsLoading ? (
                      <tr>
                        <td colSpan="5">
                          <h6 className="text-center">Loading...</h6>
                        </td>
                      </tr>
                    ) : (
                      (challanDetails?.data?.challanDetails || []).map(
                        (data, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data?.itemId?.category?.name}</td>
                              <td>{data?.itemId?.brand?.name}</td>
                              <td>{data?.itemId?.name}</td>
                              <td>{data?.quantity}</td>
                              <td>
                                <img
                                  src={closeCircle}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  className="img-fluid"
                                  onClick={() => handleRemoveProductInput(data)}
                                />
                              </td>
                            </tr>
                          );
                        }
                      )
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="table-responsive" style={{ minHeight: "600px" }}>
              <h5 className="mb-3">Add New Product in Challan</h5>
                <Table className="table table-hover mb-0 table-centered  mb-5">
                  <thead className="table-light">
                    <tr>
                      <th>No.</th>
                      <th>Product</th>
                      <th>QTY</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      {challanDetails?.data?.challan?.deliveryFrom === "Warehouse" ?
                         <td>
                     
                         <Select
                           name=""
                           className="mb-2"
                           classNamePrefix="select2-selection"
                           placeholder="Select Product"
                           cacheOptions
                           getOptionLabel={(e) => e.name}
                           getOptionValue={(e) => e.value}
                           // value={challanListStock
                           //   ?.filter(
                           //     (data) =>
                           //       stockIdList?.includes(data?._id) === false
                           //   )
                           //   ?.find((data) => data?.name === challan?.itemId)}
                           isClearable
                           options={challanListStock?.filter(
                             (data) => stockIdList?.includes(data?._id) === false
                           )}
                           onChange={(e) => {
                             setChallan({
                               ...challan,
                               itemId: e?._id || "",
                               unitPrice: e?.unitPrice || "",
                               price: e?.unitPrice || "",
                             });
                           }}
                          
                         />
                       </td> :
                          <td>
                     
                          <Select
                            name=""
                            className="mb-2"
                            classNamePrefix="select2-selection"
                            placeholder="Select Product"
                            cacheOptions
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.value}
                            // value={challanListStock
                            //   ?.filter(
                            //     (data) =>
                            //       stockIdList?.includes(data?._id) === false
                            //   )
                            //   ?.find((data) => data?.name === challan?.itemId)}
                            isClearable
                            options={products?.data?.product?.filter(
                              (data) => stockIdList?.includes(data?._id) === false
                            )}
                            onChange={(e) => {
                              setChallan({
                                ...challan,
                                itemId: e?._id || "",
                                unitPrice: e?.unitPrice || "",
                                price: e?.unitPrice || "",
                              });
                            }}
                            // value = {areaData?.data?.area?.find(data=>data._id === AddHub.values.area)}
                          />
                        </td>
                        
                      }
                   
                      <td>
                        <Input
                          type="number"
                          style={{
                            width: "64px",
                            height: "27px",
                          }}
                          value={challan?.quantity}
                          onChange={(e) => {
                            setChallan({
                              ...challan,
                              quantity: e.target.value,
                              unitPrice: challan?.price * e.target.value * 1,
                            });
                          }}
                        ></Input>
                      </td>
                      <td>
                        {addChallanLoading ? (
                          "Adding..."
                        ) : (
                          <img
                            src={addCircle}
                            alt=""
                            style={{ cursor: "pointer" }}
                            className="img-fluid"
                            onClick={() =>
                              addChallanLoading ? {} : handleAddProductInput()
                            }
                          />
                        )}
                        {/* {challanList.length - 1 === key ? (
                            ) : (
                              <img
                                src={closeCircle}
                                alt=""
                                className="img-fluid"
                                onClick={() => handleRemoveProductInput(key)}
                              />
                            )} */}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body className="text-center">
            <div className="mb-4">
              <img src={Question} alt="Img" style={{ width: "76px" }} />
            </div>
            <h5>Are you want to Delete Challan?</h5>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "none", justifyContent: "center" }}
            className="mb-4"
          >
            <Button
              variant="outline-secondary"
              onClick={handleClose}
              style={{ width: "112px" }}
            >
              Cancel
            </Button>
            <Button
              disbaled={addChallanLoading}
              onClick={handleDeleteChallan}
              className="btn button"
            >
              {addChallanLoading ? "Deleting..." : "Yes, I’m Sure"}
            </Button>
          </Modal.Footer>
        </Modal>
      </InnerLayer>
    </React.Fragment>
  );
};

export default AddChallan;
