import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import {
  Badge,
  BreadcrumbItem,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import * as XLSX from "xlsx";
import { get } from "../../helpers/api_helper";
import { getCSDistributionInventory } from "../../store/CurrentStock/actions";
import { getSingleDistribution } from "../../store/Distribution Hub/actions";
import { getBrand, getCategory } from "../../store/Info/actions";
import { getFilteringProduct } from "../../store/Product/actions";
import Pagination from "../Common/Pagination";
import CardComponent from "../Layout/CardComponent";
const DistributionInventory = ({ history }) => {
  let dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(100);
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [product, setProduct] = useState("");
  const [distribution, setDistribution] = useState("");
  const [loadingExcel, setLoadingExcel] = useState(false);
  const {
    role,
    products,
    authtoken,
    productLoading,
    inventoryOperation,
    distributionHubData,
    brandData,
    categoryData,
    singleDistribution,
    csDistributionInventory,
    csDistributionInventoryLoading,
    userId,
    filteredProduct,
  } = useSelector((state) => ({
    inventoryOperation: state.DistributionReducer.inventoryOperation,
    filteredProduct: state.ProductReducer.filteredProduct,
    brandData: state.InfoReducer.brandData,
    products: state.ProductReducer.products,
    productLoading: state.ProductReducer.productLoading,
    singleDistribution: state.DistributionReducer.singleDistribution,
    role: state.Login.userrole,
    userId: state.Login.warehouse,
    authtoken: state.Login.token,
    distributionHubData: state.DistributionReducer.distributionHubData,
    csDistributionInventory: state.CurrentStockReducer.csDistributionInventory,
    csDistributionInventoryLoading:
      state.CurrentStockReducer.csDistributionInventoryLoading,
    categoryData: state.InfoReducer.categoryData,
  }));
  console.log('csDistributionInventory', csDistributionInventory);
  let totalPageNumber = Math.ceil(
    csDistributionInventory?.logdata?.length / pageRange
  );
  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, "Hub"));
  }, []);
  useEffect(() => {
    dispatch(getBrand(authtoken, 0, 0, ""));
  }, []);
  useEffect(() => {
    dispatch(getCategory(authtoken, 0, 0, ""));
  }, []);
  useEffect(() => {
    dispatch(getFilteringProduct(authtoken, 100, 0, "", "", ""));
  }, []);
  useEffect(() => {
    dispatch(
      getCSDistributionInventory(
        authtoken,
        category,
        brand,
        role === "HUB" ? userId : distribution,
        pageRange,
        currentPage
      )
    );
  }, [category, brand, distribution, pageRange, currentPage]);
  const handleCategory = (value) => {
    if (value) {
      setCategory(value?._id);
    } else {
      setCategory("");
    }
  };
  const handleBrand = (value) => {
    if (value) {
      setBrand(value?._id);
    } else {
      setBrand("");
    }
  };
  const handleProduct = (value) => {
    if (value) {
      setProduct(value?._id);
    } else {
      setProduct("");
    }
  };
  const handleDistributionHub = (value) => {
    if (value) {
      setDistribution(value?._id);
    } else {
      setDistribution("");
    }
  };
  const handleRange = (e) => {
    setPageRange(e.target.value);
  };
  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View
    </Tooltip>
  );

  const handleExcel = async () => {
    setLoadingExcel(true);
    let hubStock = [];
    let totalPage = Math.ceil(csDistributionInventory?.logdata?.length / 5);
    for (let i = 0; i < totalPage; i++) {
      await get(
        `dh-inventory/get-hub-stock?category=${category}&brand=${brand}&limit=${5}&pageNo=${
          i + 1
        }&warehouse=${role === "HUB" ? userId : distribution}`,
        { headers: { Authorization: `Bearer ${authtoken}` } }
      )
        .then((response) => {
          console.log("response", response);

          response?.logdata.forEach((stock, index) => {
            let data = {};
            data.HubName = stock?.warehouse?.name;
            data.product = stock?.products?.name;
            data.brand = stock?.brand?.name;
            data.category = stock?.category?.name;
            data.stock = stock?.stock;
            hubStock.push(data);
          });
        })
        .catch((error) => {
          console.log("error");
        });
    }

    downloadxls(hubStock);
  };

  const downloadxls = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Hub Stock.xlsx");
    setLoadingExcel(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="d-flex align-items-center justify-content-start mb-4">
                <div>
                  <h4 className="mb-0 font-size-16">Distribution Hub Stock</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0 p-0 mt-2">
                      <BreadcrumbItem>
                        <Link to="#">Distribution Hub Stock</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">Distribution Hub Stock List</Link>
                      </BreadcrumbItem>
                    </ol>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <CardComponent className="user_table_card">
              <Row className="mb-3 table-header-padding">
                <div className="d-flex d-flex-blockCustom">
                  {role === "HUB" ? (
                    <span></span>
                  ) : (
                    <Col className="me-2" md={2}>
                      <Select
                        name="hub"
                        className="mb-2"
                        classNamePrefix="select2-selection"
                        placeholder="Select Hub"
                        cacheOptions
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.value}
                        isClearable
                        options={singleDistribution?.data?.Warehouse}
                        onChange={handleDistributionHub}
                      />
                    </Col>
                  )}

                  <Col className="me-2" md={2}>
                    <Select
                      name="category"
                      className="mb-2"
                      // classNamePrefix="select2-selection"
                      placeholder="Select Category"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={categoryData?.category}
                      onChange={handleCategory}
                    />
                  </Col>
                  <Col className="me-2" md={2}>
                    <Select
                      name="brand"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Brand"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={brandData?.data?.brand}
                      onChange={handleBrand}
                    />
                  </Col>
                  <Col className="me-2" md={2}>
                    {/* <Select
                      name="product"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Product"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={filteredProduct?.data?.totalProducts}
                      onChange={handleProduct}
                    /> */}
                  </Col>
                  {/* <Col className="" md={2}>
                    <div className="search-box me-4 d-flex justify-content-end">
                      <SearchInput
                        className="userSearchBar form-control"
                        onChange=""
                        placeholder="Type Challan"
                      />
                    </div>
                  </Col> */}
                  <Col
                    className={
                      role === "HUB"
                        ? "custom_left_hub me-2 custom-left-margin"
                        : "custom_left_admin me-2  custom-left-margin"
                    }
                    md={2}
                  >
                    <Input type="select" onChange={(e) => handleRange(e)}>
                      {/* <option defaultValue>Select...</option> */}
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </Input>
                  </Col>
                </div>
              </Row>
              {/* table */}
              <Row>
                <div className="table-responsive">
                  {csDistributionInventoryLoading ? (
                    <div className="text-center mt-5 pt-5 mb-5">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <Table className="table table-hover mb-0 table-centered table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th>No.</th>
                          <th>Distribution Hub Name</th>
                          <th>Product Name</th>
                          <th>Brand</th>
                          <th>Category</th>
                          <th>Stock</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      {(csDistributionInventory?.logdata || []).map(
                        (data, key) => {
                          return (
                            <>
                              <tbody>
                                <tr>
                                  <td>{key + 1}</td>

                                  <td>{data?.warehouse?.name}</td>
                                  <td>{data?.products?.name}</td>
                                  <td>{data?.brand?.name}</td>
                                  <td>{data?.category?.name}</td>
                                  <td>{data?.stock}</td>
                                  <td>
                                    {" "}
                                    <Badge
                                      color="warning"
                                      style={{ padding: "10px !important" }}
                                    >
                                      active
                                    </Badge>
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        }
                      )}
                    </Table>
                  )}
                  <Pagination
                    parentClass={`paginationContainer text-right py-3`}
                    reInitialize="reInitialize"
                    page={totalPageNumber}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
                {/* table end */}
              </Row>
            </CardComponent>
            <button
              className="btn btn-info w-25 m-auto fw-bold excel_button mb-4"
              disabled={loadingExcel}
              onClick={handleExcel}
            >
              {loadingExcel ? "Downloading..." : "Download Excel"}
            </button>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

DistributionInventory.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(DistributionInventory);
