import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import {
  BreadcrumbItem,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import * as XLSX from "xlsx";
import { get } from "../../helpers/api_helper";
import {
  getDistributionHub,
  getSingleDistribution,
} from "../../store/Distribution Hub/actions";
import { getBrand, getCategory } from "../../store/Info/actions";
import {
  getCentalWarehouse,
  getCwStockReport,
} from "../../store/Inventory/actions";
import { getFilteringProduct } from "../../store/Product/actions";
import Pagination from "../Common/Pagination";
import CardComponent from "../Layout/CardComponent";
import CustomTable from "../Layout/CustomTable";
import NoTableData from "../Layout/NoTableData";
const HUbType = [
  {
    name: "Central WareHouse",
    _id: 1,
    value: "Central",
  },
  {
    name: "Distribution Hub",
    _id: 2,
    value: "Hub",
  },
];
const tableHead2 = ["No.", "Date", "Stock In", "Stock Out"];
const CentralWarehouse = ({ history }) => {
  let dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [product, setProduct] = useState("");
  const [hubType, setHubType] = useState("Central");
  const [wareHouse, setWareHouse] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(100);
  const [csDistribution, setCsDistribution] = useState("");
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingStockReportExcel, setLoadingStockReportExcel] = useState(false);
  const [detailShow, setDetailShow] = useState(false);
  const [warehouseName, setWarehouseName] = useState("");

  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const {
    brandData,
    loading,
    authtoken,
    categoryData,
    products,
    distributionHubData,
    wareHouseData,
    role,
    userId,
    singleDistribution,
    wareHouseDataLoading,
    filteredProduct,
    cwStockReport,
    cwStockReportLoading,
  } = useSelector((state) => ({
    wareHouseData: state.InventoryReducer.wareHouseData,
    cwStockReport: state.InventoryReducer.cwStockReport,
    cwStockReportLoading: state.InventoryReducer.cwStockReportLoading,
    wareHouseDataLoading: state.InventoryReducer.wareHouseDataLoading,
    categoryData: state.InfoReducer.categoryData,
    brandData: state.InfoReducer.brandData,
    csCentralInventory: state.InfoReducer.csCentralInventory,
    filteredProduct: state.ProductReducer.filteredProduct,

    filteredProductLoading: state.ProductReducer.filteredProductLoading,
    products: state.ProductReducer.products,
    distributionHubData: state.DistributionReducer.distributionHubData,
    singleDistribution: state.DistributionReducer.singleDistribution,
    productLoading: state.ProductReducer.productLoading,
    authtoken: state.Login.token,
    role: state.Login.userrole,
    userId: state.Login.warehouse,
  }));
  console.log("csCentralInventory", wareHouseData);
  let totalPageNumber = Math.ceil(wareHouseData?.data?.length / pageRange);
  useEffect(() => {
    dispatch(
      getCentalWarehouse(
        authtoken,
        startDate,
        endDate,
        role === "CENTRALWAREHOUSE" ? userId : wareHouse,
        pageRange,
        currentPage,
        category,
        brand,
        product
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    role,
    category,
    brand,
    pageRange,
    currentPage,
    product,
    wareHouse,
    brand,
    startDate,
    endDate,
    userId,
  ]);
  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, "Central"));
  }, []);
  useEffect(() => {
    dispatch(getBrand(authtoken, 0, 0, ""));
  }, []);
  useEffect(() => {
    dispatch(getCategory(authtoken, 0, 0, ""));
  }, []);
  const handleCategory = (value) => {
    if (value) {
      setCategory(value?._id);
    } else {
      setCategory("");
    }
  };
  const handleBrand = (value) => {
    if (value) {
      setBrand(value._id);
    } else {
      setBrand("");
    }
  };
  const handleProduct = (value) => {
    if (value) {
      setProduct(value._id);
    } else {
      setProduct("");
    }
  };
  useEffect(() => {
    dispatch(getFilteringProduct(authtoken));
  }, []);
  useEffect(() => {
    dispatch(getDistributionHub(authtoken, 0, 0, hubType));
  }, [hubType]);
  const handleWareHouse = (value) => {
    if (value) {
      setWareHouse(value?._id);
    } else {
      setWareHouse("");
    }
  };
  const handleStartDate = (e) => {
    const value = e.target.value;
    setStartDate(moment(value).format("YYYY-MM-DD"));
  };
  const handleEndDate = (e) => {
    const value = e.target.value;
    setEndDate(moment(value).format("YYYY-MM-DD"));
  };
  const handleCentralDistribution = (value) => {
    if (value) {
      setCsDistribution(value?._id);
    } else {
      setCsDistribution("");
    }
  };
  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View
    </Tooltip>
  );
  const handleRange = (e) => {
    setPageRange(e.target.value);
  };
  const handleExcel = async () => {
    setLoadingExcel(true);
    let centralWarehouseReport = [];

    for (let i = 0; i < wareHouseData?.data?.length; i++) {
      await get(
        `centralstock/central-log-report-by-date?fromDate=${startDate}&toDate=${endDate}&warehouse=${
          role === "CENTRALWAREHOUSE" ? userId : wareHouse
        }&limit=${pageRange}&pageNo=${currentPage}&category=${category}&brand=${brand}&product=${product}`,
        { headers: { Authorization: `Bearer ${authtoken}` } }
      )
        .then((response) => {
          console.log("response", response);

          response?.logdata.forEach((cwReport, index) => {
            let data = {};
            data.WareHouse = cwReport?.warehouse?.name;
            data.Category = cwReport?.category?.name;
            data.Brand = cwReport?.brand?.name;
            data.Product = cwReport?.products?.name;
            data.StockIn = cwReport?.stockin;
            data.StockOut = cwReport?.stockout;

            centralWarehouseReport.push(data);
          });
        })
        .catch((error) => {
          console.log("kkkk");
          // setResults((prev) => [...prev]);
        });
    }

    downloadxls(centralWarehouseReport);
  };

  const downloadxls = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Central Warehouse Report.xlsx");
    setLoadingExcel(false);
  };
  const handleStockReportExcel = async () => {
    setLoadingStockReportExcel(true);
    let centralWarehouseStockReport = [];

    cwStockReport?.data.forEach((cwStockReport, index) => {
      let data = {};
      data.date = cwStockReport?.date;
      data.warehouse = warehouseName
      data.name = cwStockReport?.name 
      data.stockIn = cwStockReport?.stockin;
      data.stockOut = cwStockReport?.stockout;
      centralWarehouseStockReport.push(data);
    });

    downloadxls1(centralWarehouseStockReport);
  };

  const downloadxls1 = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Central Warehouse Stock Report.xlsx");
    setLoadingStockReportExcel(false);
  };
  const handleStockReport = (data) => {
    setDetailShow(true);
    console.log("data", data);
    setWarehouseName(data?.warehouse?.name)
    let body = { ids: data?.ids };
    console.log("body", body);
    dispatch(getCwStockReport(body, history, authtoken));
  };
  const hideMenu = () => {
    setDetailShow(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="3" sm="12" className="mb-3">
              <div className="page-title-right">
                <ol className="breadcrumb m-0 p-0 mt-2">
                  <BreadcrumbItem>
                    <Link to="#" style={{ fontWeight: "bold" }}>
                      Inventory{" "}
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">Central Warehouse Report</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </Col>
          </Row>
          <Row>
            {role === "SUPERADMIN" || role === "ADMIN" ? (
              <Col className="mb-3" sm="12" md="2">
                <Select
                  name="bp"
                  className="mb-2"
                  classNamePrefix="select2-selection"
                  placeholder="Select Warehouse"
                  cacheOptions
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  options={singleDistribution?.data?.Warehouse}
                  onChange={handleWareHouse}
                />
              </Col>
            ) : (
              <span></span>
            )}

            <Col className="mb-3" sm="12" md="2">
              <Select
                name="category"
                className="mb-2"
                classNamePrefix="select2-selection"
                placeholder="Select Category"
                cacheOptions
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                isClearable
                options={categoryData?.category}
                onChange={handleCategory}
              />
            </Col>
            <Col className="mb-3" sm="12" md="2">
              <Select
                name="brand"
                className="mb-2"
                classNamePrefix="select2-selection"
                placeholder="Select Brand"
                cacheOptions
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                isClearable
                options={brandData?.data?.brand}
                onChange={handleBrand}
              />
            </Col>
            <Col sm="12" md="2" className="mb-3">
              <Select
                name="product"
                className="mb-2"
                classNamePrefix="select2-selection"
                placeholder="Select Product"
                cacheOptions
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                isClearable
                options={filteredProduct?.data?.totalProducts}
                onChange={handleProduct}
              />
            </Col>
            <Col sm="12" md="4" className="mb-3">
              <div className="d-flex">
                <Input
                  name="fromDate"
                  type="date"
                  className=""
                  value={startDate}
                  onChange={handleStartDate}
                />{" "}
                <div className="mt-2 me-1 ms-1">To</div>
                <Input
                  name="toDate"
                  type="date"
                  onChange={handleEndDate}
                  value={endDate}
                  min={startDate}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <CardComponent className="user_table_card">
              <Row className="mb-3 table-header-padding">
                <Col md="10"></Col>

                {/* <Row>
                    <Col
                      sm="12"
                      md="3"
                      className="text-end mb-2 pdf-text-start"
                    >
                      <button
                        className="btn btn-secondary"
                        style={{ borderRadius: "10px" }}
                      >
                        <i className="bx bx-file  me-2"></i>
                        Download PDF
                      </button>
                    </Col>
                    <Col md="2" sm="12" className="mb-2">
                      {" "}
                      <div className="d-flex align-items-center mt-2 text-end">
                        <div className="bullet me-2"></div>
                        <div>Inventory Over</div>
                      </div>
                    </Col>
                    <Col md="3" sm="12" className="mb-2">
                      {" "}
                      <div className="d-flex align-items-center mt-2">
                        <div className="bullet2 me-2"></div>
                        <div>Inventory almost over</div>
                      </div>
                    </Col>
                    <Col md="2" sm="12" className="mb-2">
                      <div className="search-box d-flex justify-content-start">
                        <SearchInput
                          className="userSearchBar form-control"
                          onChange=""
                          placeholder="Search.."
                        />
                      </div>
                    </Col>
                    
                  </Row> */}
                <Col sm="2" className="mb-2">
                  <Input
                    type="select"
                    onChange={(e) => handleRange(e)}
                  >
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </Input>
                </Col>
              </Row>
              {/* table */}
              <Row>
                <div className="table-responsive">
                  {wareHouseDataLoading ? (
                    <div className="text-center mt-5 pt-5 mb-5">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <Table className="table table-hover mb-0 table-centered table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th>No.</th>
                          <th>Warehouse</th>
                          <th>Category</th>
                          <th>Brand</th>
                          <th>Product Name</th>
                          <th>Stock In</th>
                          <th>Stock Out</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {(wareHouseData?.data?.logdata || []).map((data, key) => {
                        return (
                          <>
                            <tbody>
                              <tr>
                                <td>{key + 1}</td>
                                <td>{data?.warehouse?.name}</td>
                                <td>{data?.category?.name}</td>
                                <td>{data?.brand?.name}</td>
                                <td>{data?.products?.name}</td>
                                <td>{data?.stockin}</td>
                                <td>{data?.stockout}</td>
                                <td>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipView}
                                  >
                                    <button
                                      className="btn btn-secondary btn-sm me-2"
                                      style={{ borderRadius: "10px" }}
                                      onClick={() => handleStockReport(data)}
                                    >
                                      <i className="bx bx-show mt-1"></i>
                                    </button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        );
                      })}
                    </Table>
                  )}
                  <Pagination
                    parentClass={`paginationContainer text-right py-3`}
                    reInitialize="reInitialize"
                    page={wareHouseData?.data?.length}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
                {/* table end */}
              </Row>
              <Modal
                size="lg"
                show={detailShow}
                onHide={() => hideMenu()}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header
                  closeButton
                  style={{ borderBottom: "none" }}
                ></Modal.Header>
                {cwStockReportLoading ? (
                  <div className="text-center mt-5 pt-5">
                    <Spinner />
                  </div>
                ) : (
                  <Modal.Body style={{ padding: "3px 25px" }}>
                    <h4 className="text-center" style={{ color: "#0C1D37" }}>
                      {cwStockReport?.data[0]?.name}
                    </h4>

                    <hr />

                    <div className="table-responsive">
                      <CustomTable
                        paginationClass="paginationContainer text-right"
                        data={cwStockReport?.data}
                        tableHead={tableHead2}
                      >
                        {cwStockReportLoading ? (
                          <tr style={{ width: "100%" }}>
                            <div
                              className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                              style={{ width: "100%" }}
                            ></div>
                          </tr>
                        ) : cwStockReport?.data?.length ? (
                          cwStockReport?.data.map((data, idx) => (
                            <React.Fragment key={idx}>
                              <tr>
                                <th scope="row">{idx + 1}</th>
                                <td>{data?.date}</td>

                                <td>{data?.stockin}</td>
                                <td>{data?.stockout}</td>
                              </tr>
                            </React.Fragment>
                          ))
                        ) : (
                          <NoTableData
                            colSpan="9"
                            className="d-flex justify-content-center align-items-center"
                            style={{ minHeight: "300px", width: `100%` }}
                          >
                            <span>Currently you have no Data. </span>
                          </NoTableData>
                        )}
                      </CustomTable>
                    </div>
                    <div className="text-center mt-4">
                      <button
                        className="btn btn-info w-25 m-auto fw-bold excel_button mb-4"
                        disabled={loadingStockReportExcel}
                        onClick={handleStockReportExcel}
                      >
                        {loadingStockReportExcel
                          ? "Downloading..."
                          : "Download Excel"}
                      </button>
                    </div>
                  </Modal.Body>
                )}
              </Modal>
            </CardComponent>
            <button
              className="btn btn-info w-25 m-auto fw-bold excel_button mb-4"
              disabled={loadingExcel}
              onClick={handleExcel}
            >
              {loadingExcel ? "Downloading..." : "Download Excel"}
            </button>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
CentralWarehouse.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
export default withRouter(CentralWarehouse);
