import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Row, Spinner } from "reactstrap";
import * as XLSX from "xlsx";
import Question from "../../assests/images/question.svg";
import { get } from "../../helpers/api_helper";
import { getDistributionHub } from "../../store/Distribution Hub/actions";
import { deleteStock, getProductStock } from "../../store/StokeIn/action";
import CardComponent from "../Layout/CardComponent";
import CustomTable from "../Layout/CustomTable";
import InnerLayer from "../Layout/InnerLayer";
import NoTableData from "../Layout/NoTableData";
const tableHead = [
  "No.",
  "Central Warehouse",
  "Category",
  "Brand",
  "Product Name",
  "QTY",
  "Date",
  "Action",
];
const renderTooltipEdit = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Delete
  </Tooltip>
);
const renderTooltipView = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    View
  </Tooltip>
);
const HUbType = [
  {
    name: "Central WareHouse",
    _id: 1,
    value: "Central",
  },
  {
    name: "Distribution Hub",
    _id: 2,
    value: "Hub",
  },
];

const StockIn = ({ history }) => {
  let dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(100);
  const [hubType, setHubType] = useState("Central");
  const [wareHouse, setWareHouse] = useState("");
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const {
    distributionHubData,
    loading,
    authtoken,
    productStock,
    role,
    userId,
    productStockLoading,
  } = useSelector((state) => ({
    distributionHubData: state.DistributionReducer.distributionHubData,
    productStock: state.StokeInReducer.productStock,
    productStockLoading: state.StokeInReducer.productStockLoading,
    authtoken: state.Login.token,
    role: state.Login.userrole,
    userId: state.Login.warehouse,
  }));

  const handleHubType = (value) => {
    if (value) {
      setHubType(value?.value);
    } else {
      setHubType("Central");
    }
    // setWareHouse("")
  };
  const handleWareHouse = (value) => {
    console.log("value", value);
    if (value) {
      setWareHouse(value?._id);
    } else {
      setWareHouse("");
    }
  };

  let totalPageNumber = Math.ceil(productStock?.data?.length / pageRange);
  useEffect(() => {
    dispatch(getDistributionHub(authtoken, 0, 0, "Central", ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageRange, hubType]);
  const handleRange = (e) => {
    setPageRange(e.target.value);
  };
  const body = {
    authtoken,
    startDate,
    endDate,
    wareHouse: role === "CENTRALWAREHOUSE" ? userId : wareHouse,
    currentPage,
    pageRange,
  };
  useEffect(() => {
    dispatch(
      getProductStock(
        authtoken,
        startDate,
        endDate,
        role === "CENTRALWAREHOUSE" ? userId : wareHouse,
        currentPage,
        pageRange
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, wareHouse, currentPage, pageRange, userId]);

  const handleStartDate = (e) => {
    console.log("start", e.target.value);
    const value = e.target.value;
    setStartDate(moment(value).format("YYYY-MM-DD"));
  };
  const handleEndDate = (e) => {
    console.log("end", e.target.value);
    const value = e.target.value;
    setEndDate(moment(value).format("YYYY-MM-DD"));
  };
  const handleStoke = (data) => {
    window.alert("Are You want delete this Stoke");
    dispatch(deleteStock(authtoken, data?._id, body));
  };

  const handleExcel = async () => {
    setLoadingExcel(true);
    let stockData = [];
    let totalPage = Math.ceil(productStock?.data?.length / 5);
    for (let i = 0; i < totalPage; i++) {
      await get(
        `centralstock/central-log-report?fromDate=${startDate}&toDate=${endDate}&warehouse=${wareHouse}&limit=${5}&pageNo=${
          i + 1
        }`,
        { headers: { Authorization: `Bearer ${authtoken}` } }
      )
        .then((response) => {
          console.log("response", response);

          response?.logdata.forEach((stock, index) => {
            let data = {};
            data.Date = stock?.date?.slice(0, 10);
            data.CentralWareHouse = stock?.WarehouseId?.name;
            data.Category = stock?.itemId?.category?.name;
            data.Brand = stock?.itemId?.brand?.name;
            data.Product = stock?.itemId?.name;
            data.Quantity = stock?.quantity;

            stockData.push(data);
          });
        })
        .catch((error) => {
          // setResults((prev) => [...prev]);
        });
    }

    downloadxls(stockData);
  };

  const downloadxls = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Stock List.xlsx");
    setLoadingExcel(false);
  };

  //modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const utcToBdt = (date) => {
    const utcTime = new Date(date);
    utcTime.setHours(utcTime.getHours() + 6);

   

    // Get the BDT date as a string (YYYY-MM-DD format)
    const bdtDate = utcTime.toISOString().split('T')[0];
    
    return bdtDate;
  };

  return (
    <React.Fragment>
      <InnerLayer
        title="Stock In List"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Stock In"}
        subTitle={"Stock In List"}
        // onAddHandler={() => history.push("/stock-in/add-stock-in")}
        onAddHandler={() => history.push("/stock-in/add-sample-stock")}
        buttonText="Stock In"
        isButton={role === "VIEWADMIN" ? false : true}
      >
        <Row>
          <CardComponent className="user_table_card">
            <Row className="mb-3 table-header-padding">
              <div className="d-flex d-flex-blockCustom justify-content-end">
                {/* <Col md={2} className="custom-bottom-margin me-2">
                  <div className="d-flex d-flex-blockCustom">
                    <Input
                      name="fromDate"
                      type="date"
                      className=""
                      value={startDate}
                      onChange={handleStartDate}
                    />{" "}
                    <div className="mt-2 custom-center">To</div>
                  </div>
                </Col>
                <Col md={2} className="custom-bottom-margin ms-2">
                  <Input
                    name="toDate"
                    type="date"
                    onChange={handleEndDate}
                    value={endDate}
                  />
                </Col> */}
                <Col sm="12" md="4" className="mb-3 me-2">
                  <div className="d-flex">
                    <Input
                      name="fromDate"
                      type="date"
                      className=""
                      value={startDate}
                      onChange={handleStartDate}
                    />{" "}
                    <div className="mt-2 me-1 ms-1">To</div>
                    <Input
                      name="toDate"
                      type="date"
                      onChange={handleEndDate}
                      value={endDate}
                      min={startDate}
                    />
                  </div>
                </Col>
                {role === "SUPERADMIN" || role === "ADMIN" ? (
                  <Col md={3} className="custom-bottom-margin me-2">
                    <Select
                      name="Warehouse"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Warehouse"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      // value={distributionHubData?.data?.Warehouse?.find((data)=>data?._id === wareHouse)}
                      options={distributionHubData?.data?.Warehouse}
                      onChange={handleWareHouse}
                    />
                  </Col>
                ) : null}
                <Col className="me-2" md={2}>
                  <Input type="select" onChange={(e) => handleRange(e)}>
                    {/* <option defaultValue>Select...</option> */}
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </Input>
                </Col>
                {/* {role === "SUPERADMIN" ? (
                  <Col md={2}>
                    <Select
                      name="Warehouse"
                      className="mb-2 ms-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Type"
                      // cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={HUbType}
                      onChange={handleHubType}
                    />
                  </Col>
                ) : null} */}
              </div>
            </Row>
            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={distributionHubData?.data?.Warehouse}
                pageNo={totalPageNumber}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {productStockLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </div>
                  </tr>
                ) : productStock?.data?.length > 0 ? (
                  productStock?.data?.logdata?.map((data, idx) => (
                    <tr>
                      <th scope="row" style={{ paddingLeft: "25px" }}>
                        {idx + 1}
                      </th>
                      <td>{data?.WarehouseId?.name}</td>
                      <td>{data?.itemId?.category?.name}</td>
                      <td>{data?.itemId?.brand?.name}</td>
                      <td>{data?.itemId?.name}</td>
                      <td>{data?.quantity}</td>
                      <td>{utcToBdt(data?.date)}</td>
                      <td>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipEdit}
                        >
                          <button
                            className="btn btn-outline-danger btn-sm"
                            style={{ borderRadius: "10px" }}
                            onClick={() => handleStoke(data)}
                          >
                            <i className="bx bx-trash mt-1"></i>
                          </button>
                        </OverlayTrigger>
                      </td>
                      {/* <td>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipEdit}
                        >
                          <button
                            className="btn btn-outline-danger btn-sm"
                            style={{ borderRadius: "10px" }}
                            onClick={handleShow}
                          >
                            <i className="bx bx-trash mt-1"></i>
                          </button>
                        </OverlayTrigger>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan={tableHead?.length}
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No data Available</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
            </Row>
          </CardComponent>
          <button
            className="btn btn-info w-25 m-auto fw-bold excel_button"
            disabled={loadingExcel}
            onClick={handleExcel}
          >
            {loadingExcel ? "Downloading..." : "Download Excel"}
          </button>
        </Row>
        {/* modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body className="text-center">
            <div className="mb-4">
              <img src={Question} alt="Image" style={{ width: "76px" }} />
            </div>
            <h5>Are you sure?</h5>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "none", justifyContent: "center" }}
            className="mb-4"
          >
            <Button
              variant="outline-secondary"
              onClick={handleClose}
              style={{ width: "112px" }}
            >
              Cancel
            </Button>
            <Button className="btn button" onClick={handleClose}>
              Yes, I’m Sure
            </Button>
          </Modal.Footer>
        </Modal>
      </InnerLayer>
    </React.Fragment>
  );
};

StockIn.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(StockIn);
