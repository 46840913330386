export const GET_CS_CENTRAL_INVENTORY = "GET_CS_CENTRAL_INVENTORY";
export const GET_CS_CENTRAL_INVENTORY_SUCCESS = "GET_CS_CENTRAL_INVENTORY_SUCCESS";
export const GET_CS_CENTRAL_INVENTORY_FAIL = "GET_CS_CENTRAL_INVENTORY_FAIL";

export const GET_CS_DISTRIBUTION_INVENTORY = "GET_CS_DISTRIBUTION_INVENTORY";
export const GET_CS_DISTRIBUTION_INVENTORY_SUCCESS = "GET_CS_DISTRIBUTION_INVENTORY_SUCCESS";
export const GET_CS_DISTRIBUTION_INVENTORY_FAIL = "GET_CS_DISTRIBUTION_INVENTORY_FAIL";

export const GET_CS_BP_INVENTORY = "GET_CS_BP_INVENTORY";
export const GET_CS_BP_INVENTORY_SUCCESS = "GET_CS_BP_INVENTORY_SUCCESS";
export const GET_CS_BP_INVENTORY_FAIL = "GET_CS_BP_INVENTORY_FAIL";















