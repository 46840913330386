import React, { createRef } from "react";
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import PrintChallan from "./PrintChallan";

const SampleChallan = () => {
  let componentRef = createRef();
  return (
    <>
      <ReactToPrint
        trigger={() => (
          <div className="text-end mt-4 page-content" style={{ marginRight: "65px" }}>
            <Button className="btn button ">
              {" "}
              <i className="bx bx-printer mt-1 me-2"></i>Print this out!
            </Button>
          </div>
        )}
        content={() => componentRef.current}
      />
    
      <div ref={componentRef}>
        <PrintChallan />
      </div>
    </>
  );
};

export default SampleChallan;
