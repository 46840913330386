import { Redirect } from 'react-router-dom';
import Login from '../Components/Authentication/Login';
import AddChallan from '../Components/Challan/AddChallan';
import AddDhChallan from '../Components/Challan/AddDhChallan';
import Challan from '../Components/Challan/Challan';
// import DistEditChallan from '../Components/Challan/DistEditChallan';
import EditChallan from '../Components/Challan/EditChallan';
import NotificationChallan from '../Components/Challan/NotificationChallan';
import PrintChallan from '../Components/Challan/PrintChallan';
import SampleAddChallan from '../Components/Challan/SampleAddChallan';
import SampleChallan from '../Components/Challan/SampleChallan';
import SampleEditChallan from '../Components/Challan/SampleEditChallan';
import CentralInventory from '../Components/CurrentStock/CentralInventory';
import DistributionInventory from '../Components/CurrentStock/DistributionInventory';
import InventoryBp from '../Components/CurrentStock/InventoryBp';
import Dashboard from '../Components/Dashboard/Dashboard';
import CentralHubDemo from '../Components/Demo/CentralHubDemo';
import CWInvDemo from '../Components/Demo/CWInvDemo';
import Demo from '../Components/Demo/Demo';
import DHInvDemo from '../Components/Demo/DHInvDemo';
import HubStockDemo from '../Components/Demo/HubStockDemo';
import StockDemo from '../Components/Demo/StockDemo';
import StockReportDemo from '../Components/Demo/StockReportDemo';
import UserDemo from '../Components/Demo/UserDemo';
import AddDisbursePlan from '../Components/DisbursePlan/AddDisbursePlan';
import DisbursePlan from '../Components/DisbursePlan/DisbursePlan';
import DistDisbursePlan from '../Components/Distribution/DisbursePlan/DistDisbursePlan';
import TodayDisbursePlan from '../Components/Distribution/DisbursePlan/TodayDisbursePlan';
import AddArea from '../Components/Geo info/Area/AddArea';
import Area from '../Components/Geo info/Area/Area';
import AddRegion from '../Components/Geo info/Region/AddRegion';
import Region from '../Components/Geo info/Region/Region';
import AddTerritory from '../Components/Geo info/Territory/AddTerritory';
import Territory from '../Components/Geo info/Territory/Territory';
import AddHub from '../Components/Hub/AddHub';
import Hub from '../Components/Hub/Hub';
import AddBrand from '../Components/Info/Brand/AddBrand';
import Brand from '../Components/Info/Brand/Brand';
import AddCategory from '../Components/Info/Category/AddCategory';
import Category from '../Components/Info/Category/Category';
import AddPartner from '../Components/Info/Partner/AddPartner';
import Partner from '../Components/Info/Partner/Partner';
import BpInventory from '../Components/InventoryReport/BpInventory';
import CentralWarehouse from '../Components/InventoryReport/CentralWarehouse';
import DistributionHub from '../Components/InventoryReport/DistributionHub';
import AddProduct from '../Components/Product/AddProduct';
import Product from '../Components/Product/Product';
import Reconciliation from '../Components/Reconciliation/Reconciliation';
import AddSampleStock from '../Components/StockIn/AddSampleStock';
import AddStockIn from '../Components/StockIn/AddStockIn';
import StockIn from '../Components/StockIn/StockIn';
import AddUser from '../Components/User/AddUser';
import User from '../Components/User/User';
const openRoute = [
  // { path: "/logout", component: Logout },
  { path: '/login', component: Login },

  // { path: "/register", component: Register },
];

const protectedRoute = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/admin-dashboard" />,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'HUB', 'ADMIN', "VIEWADMIN", "DEMOADMIN"],
  },
  {
    path: '/admin-dashboard',
    component: Dashboard,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'HUB', 'ADMIN', "VIEWADMIN", "DEMOADMIN"],
  },

  {
    path: '/region',
    component: Region,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN", "DEMOADMIN"],
  },
  {
    path: '/region/add-region',
    component: AddRegion,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/region/update-region/:id',
    component: () => <AddRegion edit />,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/area',
    component: Area,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN", "DEMOADMIN"],
  },
  {
    path: '/area/add-area',
    component: AddArea,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/area/update-area/:id',
    component: () => <AddArea edit />,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/territory',
    component: Territory,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN", "DEMOADMIN"],
  },
  {
    path: '/territory/add-territory',
    component: AddTerritory,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/territory/update-territory/:id',
    component: () => <AddTerritory edit />,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/brand',
    component: Brand,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN" ,"DEMOADMIN"],
  },
  {
    path: '/brand/add-brand',
    component: AddBrand,
    exact: true,
    roles: ['SUPERADMIN'],
  },

  {
    path: '/brand/update-brand/:id',
    component: () => <AddBrand edit />,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/category',
    component: Category,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/category/update-category/:id',
    component: () => <AddCategory edit />,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/category/add-category',
    component: AddCategory,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/partner',
    component: Partner,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN"],
  },
  {
    path: '/partner/add-partner',
    component: AddPartner,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/partner/update-partner/:id',
    component: () => <AddPartner edit />,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/hub',
    component: Hub,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/hub/add-hub',
    component: AddHub,
    exact: true,
    roles: ['SUPERADMIN'],
  },

  {
    path: '/hub/edit-hub/:id',
    component: () => <AddHub edit />,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/user',
    component: User,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/user/add-user',
    component: AddUser,
    exact: true,
    roles: ['SUPERADMIN'],
  },

  {
    path: '/user/edit-user/:id',
    component: () => <AddUser edit />,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/product',
    component: Product,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'HUB', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/product/update-product/:id',
    component: () => <AddProduct edit />,
    exact: true,
    roles: ['SUPERADMIN', "CENTRALWAREHOUSE"],
  },
  {
    path: '/product/add-product',
    component: AddProduct,
    exact: true,
    roles: ['SUPERADMIN'],
  },
  {
    path: '/central-warehouse',
    component: CentralWarehouse,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/distribution-hub',
    component: DistributionHub,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'HUB', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/bp-inventory',
    component: BpInventory,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'HUB', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/central-inventory',
    component: CentralInventory,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/distribution-inventory',
    component: DistributionInventory,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'HUB', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/reconciliation',
    component: Reconciliation,
    exact: true,
    roles: ['HUB', 'SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/inventory-bp',
    component: InventoryBp,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'HUB', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/stock-in',
    component: StockIn,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/stock-in/add-stock-in',
    component: AddStockIn,
    exact: true,
    roles: ['SUPERADMIN', 'ADMIN', 'CENTRALWAREHOUSE'],
  },
  {
    path: '/stock-in/add-sample-stock',
    component: AddSampleStock,
    exact: true,
    roles: ['SUPERADMIN', 'ADMIN', 'CENTRALWAREHOUSE'],
  },
  {
    path: '/challan',
    component: Challan,
    exact: true,
    roles: ['SUPERADMIN', 'HUB', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/challan/add-challan',
    component: AddChallan,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN'],
  },
  {
    path: '/challan/-sample-add-challan',
    component: SampleAddChallan,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN'],
  },
  {
    path: '/challan/dh-add-challan',
    component: AddDhChallan,
    exact: true,
    roles: ['HUB'],
  },
  {
    path: '/view-challan',
    component: PrintChallan,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/edit-challan/:id/:challanID',
    component: EditChallan,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN'],
  },
  {
    path: '/distribution-edit-challan/:id',
    component: SampleEditChallan,
    exact: true,
    roles: ['HUB', 'CENTRALWAREHOUSE'],
  },
  {
    path: '/notification-challan/:challanId',
    component: NotificationChallan,
    exact: true,
    roles: [ 'CENTRALWAREHOUSE'],
  },
  {
    path: '/disburse-plan',
    component: DisbursePlan,
    exact: true,
    roles: ['SUPERADMIN', 'ADMIN', "VIEWADMIN",  "DEMOADMIN"],
  },
  {
    path: '/disburse-plan/add-disburse-plan',
    component: AddDisbursePlan,
    exact: true,
    roles: ['SUPERADMIN', 'ADMIN'],
  },
  {
    path: '/today-disburse-plan',
    component: TodayDisbursePlan,
    exact: true,
    roles: ['HUB'],
  },
  {
    path: '/distribution-disburse-plan',
    component: DistDisbursePlan,
    exact: true,
    roles: ['HUB'],
  },
  {
    path: '/challan/sample-challan/:id',
    component: SampleChallan,
    exact: true,
    roles: ['SUPERADMIN', 'CENTRALWAREHOUSE', 'ADMIN', 'HUB'],
  },
  {
    path: '/demo',
    component: Demo,
    exact: true,
    roles: ['DEMOADMIN'],
  },
  {
    path: '/userDemo',
    component: UserDemo,
    exact: true,
    roles: ['DEMOADMIN'],
  },
  {
    path: '/stockDemo',
    component: StockDemo,
    exact: true,
    roles: ['DEMOADMIN'],
  },
  {
    path: '/chDemo',
    component: CentralHubDemo,
    exact: true,
    roles: ['DEMOADMIN'],
  },
  {
    path: '/hubStockDemo',
    component: HubStockDemo,
    exact: true,
    roles: ['DEMOADMIN'],
  },
  {
    path: '/cwInvDemo',
    component: CWInvDemo,
    exact: true,
    roles: ['DEMOADMIN'],
  },
  {
    path: '/dhInvDemo',
    component: DHInvDemo,
    exact: true,
    roles: ['DEMOADMIN'],
  },
  {
    path: '/stockReportDemo',
    component: StockReportDemo,
    exact: true,
    roles: ['DEMOADMIN'],
  },
];

export { openRoute, protectedRoute };

