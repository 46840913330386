import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import {
  BreadcrumbItem,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import * as XLSX from "xlsx";
import { get } from "../../helpers/api_helper";
import { getSingleDistribution } from "../../store/Distribution Hub/actions";
import { getBrand, getCategory } from "../../store/Info/actions";
import {
  getDhStockReport,
  getDistributionHubInv,
} from "../../store/Inventory/actions";
import { getProduct } from "../../store/Product/actions";
import Pagination from "../Common/Pagination";
import CardComponent from "../Layout/CardComponent";
import CustomTable from "../Layout/CustomTable";
import NoTableData from "../Layout/NoTableData";
const tableHead2 = ["No.", "Date", "Stock In", "Stock Out"];
const DistributionHub = ({ history }) => {
  let dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(100);
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [product, setProduct] = useState("");
  const [distribution, setDistribution] = useState("");
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [detailShow, setDetailShow] = useState(false);
  const [warehouseName, setWarehouseName] = useState("");
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [loadingStockReportExcel, setLoadingStockReportExcel] = useState(false);
  const {
    role,
    products,
    authtoken,
    productLoading,
    brandData,
    categoryData,
    singleDistribution,
    userId,
    distributionInventory,
    distributionHubDataLoading,
    filteredProduct,
    dhStockReport,
    dhStockReportLoading,
  } = useSelector((state) => ({
    inventoryOperation: state.DistributionReducer.inventoryOperation,
    brandData: state.InfoReducer.brandData,
    products: state.ProductReducer.products,
    productLoading: state.ProductReducer.productLoading,
    filteredProduct: state.ProductReducer.filteredProduct,

    filteredProductLoading: state.ProductReducer.filteredProductLoading,
    singleDistribution: state.DistributionReducer.singleDistribution,
    role: state.Login.userrole,
    authtoken: state.Login.token,
    userId: state.Login.warehouse,
    categoryData: state.InfoReducer.categoryData,
    distributionInventory: state.InventoryReducer.distributionInventory,
    distributionHubDataLoading:
      state.InventoryReducer.distributionHubDataLoading,
    dhStockReport: state.InventoryReducer.dhStockReport,
    dhStockReportLoading: state.InventoryReducer.dhStockReportLoading,
  }));
  console.log("distributionInventory", distributionInventory);
  let totalPageNumber = Math.ceil(
    distributionInventory?.logdata?.length / pageRange
  );
  console.log('totalPageNumber', totalPageNumber);
  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, "Hub"));
  }, []);
  useEffect(() => {
    dispatch(getBrand(authtoken, 0, 0, ""));
  }, []);
  useEffect(() => {
    dispatch(getCategory(authtoken, 0, 0, ""));
  }, []);

  useEffect(() => {
    dispatch(getProduct(authtoken));
  }, []);
  useEffect(() => {
    dispatch(
      getDistributionHubInv(
        authtoken,
        startDate,
        endDate,
        category,
        brand,
        product,
        role === "HUB" ? userId : distribution,

        pageRange,
        currentPage
      )
    );
  }, [
    category,
    brand,
    product,
    userId,
    distribution,
    pageRange,
    currentPage,
    startDate,
    endDate,
  ]);
  const handleDistributionHub = (value) => {
    if (value) {
      setDistribution(value?._id);
    } else {
      setDistribution("");
    }
  };
  const handleCategory = (value) => {
    if (value) {
      setCategory(value?._id);
    } else {
      setCategory("");
    }
  };
  const handleBrand = (value) => {
    if (value) {
      setBrand(value?._id);
    } else {
      setBrand("");
    }
  };
  const handleProduct = (value) => {
    if (value) {
      setProduct(value?._id);
    } else {
      setProduct("");
    }
  };
  const handleStartDate = (e) => {
    console.log("start", e.target.value);
    const value = e.target.value;
    setStartDate(moment(value).format("YYYY-MM-DD"));
  };
  const handleEndDate = (e) => {
    console.log("end", e.target.value);
    const value = e.target.value;
    setEndDate(moment(value).format("YYYY-MM-DD"));
  };
  const handleRange = (e) => {
    setPageRange(e.target.value);
  };
  const handleExcel = async () => {
    setLoadingExcel(true);
    let distributionInventoryData = [];

    for (let i = 0; i < distributionInventory.length; i++) {
      console.log("hello");

      await get(
        `dh-inventory/get-hub-log-report?fromDate=${startDate}&toDate=${endDate}&warehouse=${
          role === "HUB" ? userId : distribution
        }&limit=${pageRange}&pageNo=${
          i + 1
        }&category=${category}&brand=${brand}&product=${product}`,
        { headers: { Authorization: `Bearer ${authtoken}` } }
      )
        .then((response) => {
          console.log("response", response);

          response?.logdata.forEach((dhReport, index) => {
            let data = {};
            data.WareHouse = dhReport?.warehouse?.name;
            data.Category = dhReport?.category?.name;
            data.Brand = dhReport?.brand?.name;
            data.Product = dhReport?.products?.name;
            data.StockIn = dhReport?.stockin;
            data.StockOut = dhReport?.stockout;

            distributionInventoryData.push(data);
          });
        })
        .catch((error) => {
          console.log("kkkk");
          // setResults((prev) => [...prev]);
        });
    }

    downloadxls(distributionInventoryData);
  };

  const downloadxls = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Hub Inventory Report.xlsx");
    setLoadingExcel(false);
  };

  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View
    </Tooltip>
  );
  const handleStockReport = (data) => {
    setDetailShow(true);
    console.log("data", data);
    setWarehouseName(data?.warehouse?.name);
    let body = { ids: data?.ids };
    console.log("body", body);
    dispatch(getDhStockReport(body, history, authtoken));
  };
  const hideMenu = () => {
    setDetailShow(false);
  };
  const handleStockReportExcel = async () => {
    setLoadingStockReportExcel(true);
    let dhStockReportList = [];

    dhStockReport?.data.forEach((dhStockReport, index) => {
      let data = {};
      data.date = dhStockReport?.date;
      data.hub = warehouseName
      data.name = dhStockReport?.name;
      data.stockIn = dhStockReport?.stockin;
      data.stockOut = dhStockReport?.stockout;

      dhStockReportList.push(data);
    });

    downloadxls1(dhStockReportList);
  };

  const downloadxls1 = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Distribution hub Stock Report.xlsx");
    setLoadingStockReportExcel(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="3" sm="12" className="mb-3">
              <div className="page-title-right">
                <ol className="breadcrumb m-0 p-0 mt-2">
                  <BreadcrumbItem>
                    <Link to="#" style={{ fontWeight: "bold" }}>
                      Inventory
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">Distribution Hub </Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </Col>
          </Row>
          <Row>
            {role === "HUB" ? (
              <span></span>
            ) : (
              <Col className="mb-3" sm="12" md="2">
                <Select
                  name="bp"
                  className="mb-2"
                  classNamePrefix="select2-selection"
                  placeholder="Select Hub"
                  cacheOptions
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.value}
                  isClearable
                  options={singleDistribution?.data?.Warehouse}
                  onChange={handleDistributionHub}
                />
              </Col>
            )}

            <Col className="mb-3" sm="12" md="2">
              <Select
                name="category"
                className="mb-2"
                classNamePrefix="select2-selection"
                placeholder="Select Category"
                cacheOptions
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                isClearable
                options={categoryData?.category}
                onChange={handleCategory}
              />
            </Col>
            <Col className="mb-3" sm="12" md="2">
              <Select
                name="brand"
                className="mb-2"
                classNamePrefix="select2-selection"
                placeholder="Select Brand"
                cacheOptions
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                isClearable
                options={brandData?.data?.brand}
                onChange={handleBrand}
              />
            </Col>
            <Col sm="12" md="2" className="mb-3">
              <Select
                name="product"
                className="mb-2"
                classNamePrefix="select2-selection"
                placeholder="Select Product"
                cacheOptions
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.value}
                isClearable
                options={filteredProduct?.data?.totalProducts}
                onChange={handleProduct}
              />
            </Col>
            <Col sm="12" md="4" className="mb-3">
              <div className="d-flex">
                <Input
                  name="fromDate"
                  type="date"
                  className=""
                  value={startDate}
                  onChange={handleStartDate}
                />{" "}
                <div className="mt-2 me-1 ms-1">To</div>
                <Input
                  name="toDate"
                  type="date"
                  value={endDate}
                  onChange={handleEndDate}
                  min={startDate}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <CardComponent className="user_table_card">
              <Row className="mb-3 table-header-padding">
                {/* <div className="d-flex d-flex-blockCustom justify-content-end">
                  <div className="me-3">
                    <button
                      className="btn btn-secondary "
                      style={{ borderRadius: "10px" }}
                    >
                      <i className="bx bx-file  me-2"></i>
                      Download PDF
                    </button>
                  </div>
                  <div className="me-3">
                    {" "}
                    <div className="d-flex align-items-center mt-2">
                      <div className="bullet me-2"></div>
                      <div>Inventory Over</div>
                    </div>
                  </div>
                  <div className="me-3">
                    {" "}
                    <div className="d-flex align-items-center mt-2 custom-bottom-margin">
                      <div className="bullet2 me-2"></div>
                      <div>Inventory almost over</div>
                    </div>
                  </div>

                  <div className="search-box d-flex custom-bottom-margin me-3 search-width2">
                    <SearchInput
                      className="userSearchBar form-control"
                      onChange=""
                      placeholder="Search.."
                    />
                  </div>

                  <div style={{ width: "200px" }} classNamesearch-width2>
                    <Input
                      type="select"
                      onChange={(e) => handleRange(e)}
                      value=""
                    >
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </Input>
                  </div>
                </div> */}
                <Col md="10"></Col>
                <Col sm="2" className="mb-2">
                  <Input
                    type="select"
               
                    onChange={(e) => handleRange(e)}
                  >
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </Input>
                </Col>
              </Row>

              <Row>
                <div className="table-responsive">
                  {distributionHubDataLoading ? (
                    <div className="text-center mt-5 pt-5 mb-5">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <Table className="table table-hover mb-0 table-centered table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th>No.</th>
                          <th>Hub</th>

                          <th>Category</th>
                          <th>Brand</th>
                          <th>Product Name</th>
                          <th>Stock In</th>
                          <th>Stock Out</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {(distributionInventory?.logdata || []).map(
                        (data, key) => {
                          return (
                            <>
                              <tbody>
                                <tr>
                                  <td>{key + 1}</td>
                                  <td>{data?.warehouse?.name}</td>

                                  <td>{data?.category?.name}</td>
                                  <td>{data?.brand?.name}</td>
                                  <td>{data?.products?.name}</td>
                                  <td>{data?.stockin}</td>
                                  <td>{data?.stockout}</td>
                                  <td>
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltipView}
                                    >
                                      <button
                                        className="btn btn-secondary btn-sm me-2"
                                        style={{ borderRadius: "10px" }}
                                        onClick={() => handleStockReport(data)}
                                      >
                                        <i className="bx bx-show mt-1"></i>
                                      </button>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        }
                      )}
                    </Table>
                  )}
                  <Pagination
                    parentClass={`paginationContainer text-right py-3`}
                    reInitialize="reInitialize"
                    page={distributionInventory?.length}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
                {/* table end */}
              </Row>
              <Modal
                size="lg"
                show={detailShow}
                onHide={() => hideMenu()}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header
                  closeButton
                  style={{ borderBottom: "none" }}
                ></Modal.Header>
                {dhStockReportLoading ? (
                  <div className="text-center mt-5 pt-5">
                    <Spinner />
                  </div>
                ) : (
                  <Modal.Body style={{ padding: "3px 25px" }}>
                    <h4 className="text-center" style={{ color: "#0C1D37" }}>
                      {dhStockReport?.data[0]?.name}
                    </h4>

                    <hr />

                    <div className="table-responsive">
                      <CustomTable
                        paginationClass="paginationContainer text-right"
                        data={dhStockReport?.data}
                        tableHead={tableHead2}
                      >
                        {dhStockReportLoading ? (
                          <tr style={{ width: "100%" }}>
                            <div
                              className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                              style={{ width: "100%" }}
                            ></div>
                          </tr>
                        ) : dhStockReport?.data?.length ? (
                          dhStockReport?.data.map((data, idx) => (
                            <React.Fragment key={idx}>
                              <tr>
                                <th scope="row">{idx + 1}</th>
                                <td>{data?.date}</td>

                                <td>{data?.stockin}</td>
                                <td>{data?.stockout}</td>
                              </tr>
                            </React.Fragment>
                          ))
                        ) : (
                          <NoTableData
                            colSpan="9"
                            className="d-flex justify-content-center align-items-center"
                            style={{ minHeight: "300px", width: `100%` }}
                          >
                            <span>Currently you have no Data. </span>
                          </NoTableData>
                        )}
                      </CustomTable>
                    </div>
                    <div className="text-center mt-4">
                      <button
                        className="btn btn-info w-25 m-auto fw-bold excel_button mb-4"
                        disabled={loadingStockReportExcel}
                        onClick={handleStockReportExcel}
                      >
                        {loadingStockReportExcel
                          ? "Downloading..."
                          : "Download Excel"}
                      </button>
                    </div>
                  </Modal.Body>
                )}
              </Modal>
            </CardComponent>
            <button
              className="btn btn-info w-25 m-auto fw-bold excel_button mb-4"
              disabled={loadingExcel}
              onClick={handleExcel}
            >
              {loadingExcel ? "Downloading..." : "Download Excel"}
            </button>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

DistributionHub.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(DistributionHub);
