import { call, put, takeEvery } from 'redux-saga/effects';
import { getData, postData } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/toaster';
import {
  addReconciliationFail,
  addReconciliationSuccess,
  getReconciliationFail,
  getReconciliationSuccess,
  storeReconciliationData
} from './action';
import { ADD_RECONCILIATION, GET_RECONCILIATION } from './actionTypes';

function* fetchReconcilitaionData({ payload: { authtoken, startDate, bp } }) {
  try {
    const url = `/disbursementplan/get-date-wise-reconciliation?dateId=${startDate}&BPUserId=${bp}`;
    const response = yield call(getData, url, authtoken);
    response?.reconciliation?.forEach((data) => {
      if (data?.dayOpeningQty >= data?.deliveryQty) {
        // data.returnQty = data?.dayOpeningQty - data?.deliveryQty;
        data.returnQty = 0
      } else {
        data.returnQty = 0;
      }
    });
    yield put(storeReconciliationData('reconciliationReceived', response?.reconciliation));
    yield put(getReconciliationSuccess(response));
  } catch (error) {
    yield put(getReconciliationFail(error));
  }
}

function* addReconcilitaionData({ payload: { authtoken, value, history } }) {
  try {
    const url = `/disbursementplan/post-date-wise-reconciliation`;
    const response = yield call(postData, url, value, authtoken);
    toaster('success', 'Add reconciliation success');
    yield put(addReconciliationSuccess(response));
    history.push(`/`);
  } catch (error) {
    yield put(addReconciliationFail(error));
    toaster('error', 'Add reconciliation failed');
  }
}

function* ReconciliationSaga() {
  yield takeEvery(GET_RECONCILIATION, fetchReconcilitaionData);
  yield takeEvery(ADD_RECONCILIATION, addReconcilitaionData);
}

export default ReconciliationSaga;
