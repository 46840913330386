import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Form, Input, Row, Spinner, Table } from "reactstrap";
import { toast } from "react-toastify";
import {
  getDistributionHub,
  getInvOption,
} from "../../store/Distribution Hub/actions";
import {
  addProducStock,
  getFilteringProduct,
  storeProductData,
} from "../../store/Product/actions";
import AddFileUpload from "../Common/AddFileUpload";
import CustomInput from "../Common/CustomInput";
import AddCardComponent from "../Layout/AddCardComponent";
import InnerLayer from "../Layout/InnerLayer";
import { AddStockSchema } from "../Schemas/AddStockSchema";
const breadcrumbItems = [
  {
    title: "Stock In",
    link: "#",
  },
  {
    title: "Create Stock In",
    link: "#",
  },
];
const initialValues = {
  inventoryOpt: "",
  warehouse: "",
};
const HUbType = [
  {
    name: "Central WareHouse",
    _id: 1,
    value: "Central",
  },
  {
    name: "Distribution Hub",
    _id: 2,
    value: "Hub",
  },
];
const AddStockIn = () => {
  const history = useHistory();
  let dispatch = useDispatch();
  const [centralWareHouse, setcentralWareHouse] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [loadingStocking, setloadingStocking] = useState(false);

  const {
    distributionHubData,
    loading,
    authtoken,
    products,
    productLoading,
    inventoryOperation,
    productList: productData,
    filteredProductLoading,
    stockLoading,
    role,
    userID
  } = useSelector((state) => ({
    distributionHubData: state.DistributionReducer.distributionHubData,
    inventoryOperation: state.DistributionReducer.inventoryOperation,
    products: state.ProductReducer.products,
    role: state.Login.userrole,
    filteredProductLoading: state.ProductReducer.filteredProductLoading,
    productList: state.ProductReducer.productList,
    authtoken: state.Login.token,
    stockLoading: state.ProductReducer.stockLoading,
    userID: state.Login.warehouse
  }));

  console.log("products", products);

  useEffect(() => {
    if (role !== "CENTRALWAREHOUSE") {
      dispatch(getDistributionHub(authtoken, 0, 0, "Central", ""));
    }
  }, []);
  useEffect(() => {
    dispatch(getInvOption(authtoken));
  }, []);
  console.log("inventoryOperation", inventoryOperation);
  const onSubmit = (values) => {
    console.log("productData", productData);
    var objList = {}
    var dynamicProd = []
    productData.map(data=>{
      
      if(data?.stock>0){
        objList.itemId = data?._id
        objList.stock = data?.stock
        let  dataList= {...objList}
        dynamicProd.push(dataList)

      }
      
    })
    console.log('dynamicProd',dynamicProd);
    const newProduct = dynamicProd.map((data) => ({
      itemId: data?.itemId,
      stock: data?.stock * 1,
    }));


    if(role!=="CENTRALWAREHOUSE")
    {
      let body = {
        ...values,
        productList: newProduct,
      };
      console.log("body", body);
      if(body.productList.length!==0){
        dispatch(addProducStock(body, history, authtoken))
      }
      else{
        toast("Please Select Quantity", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      } 
    }
    else{
      let body = {
        inventoryOpt: values.inventoryOpt,
        productList: newProduct,
        warehouse: userID
      };
      console.log("body", body);
      if(body.productList.length!==0){
        dispatch(addProducStock(body, history, authtoken))
      }
      else{
        toast("Please Select Quantity", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
    // const body = {
    //   ...values,
    //   productList: newProduct,
    // };
    // console.log("body", body);

    // dispatch(addProducStock(body, history, authtoken));

    // })
  };
  console.log("products", products, productData);
  useEffect(() => {
    dispatch(getFilteringProduct(authtoken, 500, 0, "", "", ""));
  }, []);
  const AddStock = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddStockSchema,
    onSubmit,
  });
  const handleCentralHouse = (value) => {
    console.log("value", value);
    setcentralWareHouse(value._id);
  };
  const handleProduct = (data) => {
    console.log("data", data);
  };
  const handleSubmitQtyEdit = (e, idx, data) => {
    console.log("data", data);
    const oldValue = [...productData];

    oldValue[idx].stock = e.target.value * 1;
    oldValue[idx].totalAmount = e.target.value * 1 * oldValue[idx].unitPrice;
    dispatch(storeProductData("productList", oldValue));
  };

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Stock In"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="11" className="mx-auto">
              {/* add User */}
              <AddCardComponent className="">
                <Form
                  className="needs-validation col-md-12"
                  onSubmit={AddStock.handleSubmit}
                >
                  <Row>
                    <Col md="6 mb-2">
                      <CustomInput
                        name={"inventoryOpt"}
                        type={"select"}
                        label={"Select  Option"}
                        validationType={AddStock}
                      >
                        <option defaultValue>Select Option...</option>
                        {inventoryOperation?.data?.length > 0 ? (
                          inventoryOperation?.data?.operation?.map(
                            (data, idx) => (
                              <option value={data._id} key={idx}>
                                {data?.operationName}
                              </option>
                            )
                          )
                        ) : (
                          <span></span>
                        )}
                      </CustomInput>
                    </Col>
                    {role !== "CENTRALWAREHOUSE" ?
                    <Col md="6 mb-2">
                      <CustomInput
                        name={"warehouse"}
                        type={"select"}
                        label={"Select Central WareHouse"}
                        validationType={AddStock}
                      >
                        <option defaultValue>
                          Select Central WareHouse...
                        </option>
                        {distributionHubData?.data?.length > 0 ? (
                          distributionHubData?.data?.Warehouse?.map(
                            (data, idx) => (
                              <option value={data._id} key={idx}>
                                {data.name}
                              </option>
                            )
                          )
                        ) : (
                          <span></span>
                        )}
                      </CustomInput>
                    </Col>
                    :
                    
                    <span></span>
                        }
                  </Row>
                  <Row>
                    <h6 className="mt-3">Product List</h6>
                    <div className="table-responsive">
                      {filteredProductLoading ? (
                        <div className="text-center mt-5 pt-5">
                          <Spinner />
                        </div>
                      ) : (
                        <Table className="table table-hover mb-0 table-centered ">
                          <thead className="table-light">
                            <tr>
                              <th>Sl.</th>
                           
                              <th>Category</th>
                              <th>Brand</th>
                              <th>Product</th>
                              <th>Database ID</th>
                              <th>Specification</th>
                              <th>QTY</th>

                              {/* <th>Unit Price</th>
                              <th>Total Amount</th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {(productData || []).map((data, key) => {
                              return (
                                <>
                                  <tr>
                                    <td>{key + 1}</td>
                                    
                                    <td>{data?.category?.name}</td>
                                    <td>{data?.brand?.name}</td>
                                    <td>{data?.name}</td>
                                    <td>{data?.productId}</td>
                                    <td>{data?.specification?.slice(0, 50) + "..."}</td>
                                    <td>
                                      <Input
                                        type="text"
                                        style={{
                                          width: "64px",
                                          height: "27px",
                                        }}
                                        onChange={(e) =>
                                          handleSubmitQtyEdit(e, key, data)
                                        }
                                      ></Input>
                                    </td>
                                    {/* <td>{data?.unitPrice}</td>
                                    <td>{data?.totalAmount}</td> */}
                                  </tr>
                                </>
                              );
                            })}
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>

                              <td colSpan="2">
                                {" "}
                                <h6 className="text-end"> Grand Total:</h6>
                              </td>

                              <td>
                                <h6>
                                  {" "}
                                  {productData?.reduce(
                                    (total, item) =>
                                      total + item.totalAmount * 1,
                                    0
                                  )}
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      )}
                    </div>
                    {/* table end */}
                  </Row>

                  <div className="d-flex justify-content-end mt-3">
                    <input
                      type="submit"
                      disabled={stockLoading}
                      value={stockLoading ? "Submitting" : "Submit"}
                      className="btn button "
                    />
                  </div>
                  {/* <button
                    className="btn btn-info w-25 m-auto fw-bold excel_button"
                    disabled={loadingStocking}
                    // onClick={handleExcel}
                  >
                    {loadingStocking ? "Submitting..." : "Submit"}
                  </button> */}
                </Form>
              </AddCardComponent>
              <AddFileUpload setSelectedFiles={setSelectedFiles} />
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  );
};

export default AddStockIn;
