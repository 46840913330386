import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Table,
} from 'reactstrap';
import addCircle from '../../assests/images/add-circle.png';
import closeCircle from '../../assests/images/close-circle.png';
import { post } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/toaster';
import useExcelReader from '../../Hooks/useExcelReader';
import { addDisburseData } from '../../store/Disburse/action';
import AddFileUpload from '../Common/AddFileUpload';
import CustomInput from '../Common/CustomInput';
import AddCardComponent from '../Layout/AddCardComponent';
import InnerLayer from '../Layout/InnerLayer';
import { AddDisbursePlanSchema } from '../Schemas/AddDisbursePlan.Schema';
import SelectBp from './SelectBp';
import SelectHub from './SelectHub';
import SelectPartner from './SelectPartner';
import SelectProduct from './SelectProduct';

const breadcrumbItems = [
  {
    title: 'Disburse Plan',
    link: '#',
  },
  {
    title: 'Create Disburse Plan',
    link: '#',
  },
];
const initialValues = {
  // hubId: '',
  // BpId: '',
  date: '',
};
// const HUbType = [
//   {
//     name: 'Central WareHouse',
//     _id: 1,
//     value: 'Central',
//   },
//   {
//     name: 'Distribution Hub',
//     _id: 2,
//     value: 'Hub',
//   },
// ];

const AddDisbursePlan = ({ history }) => {
  let dispatch = useDispatch();
  const [productList, setProducts] = useState([
    {
      partnerId: '',
      itemId: '',
      quantity: '',
    },
  ]);
  const [selectedHub, setSelectedHub] = useState('');
  const [hubError, setHubError] = useState('');
  const [selectedBp, setSelectedBp] = useState('');
  const [bpError, setBpError] = useState('');
  const [selectedFiles, setSelectedFiles] = useState({});
  const { items, readExcel } = useExcelReader();
  console.log(`Dashboard ~ items`, items);
  const [results, setResults] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [open, setOpen] = useState(false);
  const [addingexcel, setAddingExcel] = useState(false);
  const toggle = () => setOpen(!open);

  console.log(`AddDisbursePlan ~ productList`, productList, selectedBp);
  const {
    // allUserRole,
    // loading,
    authtoken,
    // partnerList,
    // singleDistribution,
    // products,
    adding,
    filteredProduct,
  } = useSelector((state) => ({
    allUserRole: state.UserReducer.allUserRole,
    role: state.Login.userrole,
    singleDistribution: state.DistributionReducer.singleDistribution,
    filteredProduct: state.ProductReducer.filteredProduct,
    partnerList: state.DisburseReducer.partnerList,
    adding: state.DisburseReducer.adding,
    authtoken: state.Login.token,
  }));
  console.log('filteredProduct', filteredProduct);

  // useEffect(() => {
  //   dispatch(getPartner(authtoken));
  // }, []);
  // useEffect(() => {
  //   dispatch(getUserRole(authtoken));
  // }, []);
  // useEffect(() => {
  //   dispatch(getSingleDistribution(authtoken, 0, 0, 'Hub', ''));
  // }, []);
  // useEffect(() => {
  //   dispatch(getFilteringProduct(authtoken, 10, 0, '', '', ''));
  // }, []);

  const handleAddProductInput = () => {
    setProducts([
      ...productList,
      {
        partnerId: '',
        itemId: '',
        quantity: '',
      },
    ]);
  };
  const handleRemoveProductInput = (index) =>
    setProducts(productList.filter((data, idx) => idx !== index));
  // console.log(`AddDisbursePlan ~ productList`, productList);

  const checkTableValidation = () => {
    let isValid = true;
    productList.forEach((data) => {
      if (data.partnerId === '' || data.itemId === '' || data.quantity === '') {
        isValid = false;
      }
    });
    return isValid;
  };

  const onSubmit = (values) => {
    if (!selectedHub || !selectedBp || !checkTableValidation()) {
      if (!selectedHub) {
        setHubError('Please select Hub');
      }
      if (!selectedBp) {
        setBpError('Please select BP');
      }
      if (!checkTableValidation()) {
        toaster('warning', 'Please fill all the fields');
      }
      return;
    }
    const body = {
      ...values,
      hubId: selectedHub,
      BpId: selectedBp,
      list: productList,
    };
    console.log(`onSubmit ~ body`, body);
    dispatch(addDisburseData(body, history, authtoken));
  };
  const DisbursePlanAdd = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddDisbursePlanSchema,
    onSubmit,
  });
  const oldData = [...productList];
  const handlePartnerList = (value, idx) => {
    if (value) {
      oldData[idx].partnerId = value?._id;
    } else {
      oldData[idx].partnerId = '';
    }
    setProducts(oldData);
  };
  const handleProductList = (value, idx) => {
    if (value) {
      oldData[idx].itemId = value?._id;
    } else {
      oldData[idx].itemId = '';
    }
    setProducts(oldData);
  };

  const handleQuantity = (e, idx) => {
    const { value } = e.target;
    oldData[idx].quantity = value;
    setProducts(oldData);
  };

  const handleSelectBP = (data) => {
    setSelectedBp(data?._id);
    setBpError('');
  };

  const handleHub = (data) => {
    setSelectedHub(data?._id);
    setHubError('');
  };

  const handleSubmit = async () => {
    setAddingExcel(true);
    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      const data = {
        partnerCode: element?.partnerCode,
        warehouseId: element?.warehouseId,
        BPUserId: element?.BPUserId,
        productname: element?.productname,
        quantity: element?.quantity,
        date: element?.date,
      };

      await post('/disbursementplan/disbursementplan-excel', data)
        .then((response) => {
          setResults((prev) => [...prev, response]);
        })
        .catch((error) => {
          console.log('error', error);
          setResults((prev) => [...prev, { status: 'failed' }]);
        });

      post('/disbursementplan/disbursementplan-excel', data)
        .then((response) => {
          setResults((prev) => [...prev, response]);
        })
        .catch((error) => {
          console.log('error', error);
          setResults((prev) => [...prev, { status: 'failed' }]);
        });

      console.log('hello');
      if (i === items.length - 1) {
        setIsDone(true);
        setAddingExcel(false);
        toaster('success', 'All data upload successful');
      }
    }
    // Promise.all(promises).then(response =>{
    //   console.log('res', response);
    //   setResults((prev) => [...prev, response]);
    //   setAdding(false);
    //   toaster('success', 'All data upload successful');
    // }).catch((error)=>{
    //   setAdding(false);
    //   setResults((prev) => [...prev, { status: 'failed' }]);
    //   console.log('error', error);
    // }).finally(()=>{
    //   setIsDone(true);
    // })
  };
  const tableHead = items?.[0] || {};
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={'Create Disburse Plan'}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: '100vh' }} className="position-relative">
          <Row className="mt-4">
            <Col md="9" className="mx-auto">
              {/* add User */}
              <AddCardComponent className="">
                <Form
                  className="needs-validation col-md-12"
                  onSubmit={DisbursePlanAdd.handleSubmit}
                >
                  <Row>
                    <Col md="6 mb-2">
                      {/* <CustomInput
                        name={'hubId'}
                        type={'select'}
                        label={'Hub Name'}
                        validationType={DisbursePlanAdd}
                      >
                        <option defaultValue>Select Hub...</option>
                        {singleDistribution?.data?.length > 0 ? (
                          singleDistribution?.data?.Warehouse?.map(
                            (data, idx) => (
                              <option value={data?._id} key={idx}>
                                {data.name}
                              </option>
                            )
                          )
                        ) : (
                          <span></span>
                        )}
                      </CustomInput> */}
                      <Label>Hub Name</Label>
                      <SelectHub
                        handleAdd={handleHub}
                        selectedItem={selectedHub}
                      />
                      {hubError && (
                        <div className="invalid-feedback d-block">
                          {hubError}
                        </div>
                      )}
                    </Col>
                    <Col md="6 mb-2">
                      <CustomInput
                        name={'date'}
                        type={'date'}
                        label={'Date'}
                        validationType={DisbursePlanAdd}
                      ></CustomInput>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md="6 mb-2">
                      <CustomInput
                        name={'BpId'}
                        type={'select'}
                        label={'Select Bp'}
                        validationType={DisbursePlanAdd}
                      >
                        <option defaultValue>Select Bp...</option>
                        {allUserRole.results > 0 ? (
                          allUserRole?.users?.map((data, idx) => (
                            <option value={data?._id} key={idx}>
                              {data.name}
                            </option>
                          ))
                        ) : (
                          <span></span>
                        )}
                      </CustomInput>
                    </Col> */}
                    <Col md="6 mb-2">
                      <Label>Select Bp</Label>
                      <SelectBp
                        handleAdd={handleSelectBP}
                        selectedBp={selectedBp}
                      />
                      {bpError && (
                        <div className="invalid-feedback d-block">
                          {bpError}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {/* <h6 className="mt-3">Product List</h6> */}
                    <div className="table-responsive-custom mt-3">
                      <Table className="table table-hover mb-0 table-centered ">
                        <thead className="table-light">
                          <tr>
                            <th>Sl. No.</th>
                            <th>Partner</th>
                            <th>Product</th>
                            <th>QTY</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {productList.map((product, idx) => (
                            <tr>
                              <td>{idx + 1}</td>
                              <td>
                                <div>
                                  <SelectPartner
                                    idx={idx}
                                    handlePartnerList={handlePartnerList}
                                    selectedPartner={product?.partnerId}
                                  />
                                  {/* <Select
                                    name="partnerId"
                                    className="mb-2"
                                    classNamePrefix="select2-selection"
                                    placeholder="Select Partner"
                                    cacheOptions
                                    getOptionLabel={(e) => e.outletName}
                                    getOptionValue={(e) => e.value}
                                    isClearable
                                    options={partnerList?.data?.partner}
                                    onChange={(e) => handlePartnerList(e, idx)}
                                  /> */}
                                </div>
                              </td>
                              <td>
                                <SelectProduct
                                  idx={idx}
                                  handleProductList={handleProductList}
                                  selectedProduct={product?.itemId}
                                />
                                {/* <Select
                                  name="itemId"
                                  className="mb-2"
                                  classNamePrefix="select2-selection"
                                  placeholder="Select Product"
                                  cacheOptions
                                  getOptionLabel={(e) => e.name}
                                  getOptionValue={(e) => e.value}
                                  isClearable
                                  options={filteredProduct?.data?.totalProducts}
                                  onChange={(e) => handleProductList(e, idx)}
                                /> */}
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  style={{ width: '80px', height: '35px' }}
                                  onChange={(e) => handleQuantity(e, idx)}
                                ></Input>
                              </td>
                              <td>
                                {productList.length - 1 === idx ? (
                                  <img
                                    src={addCircle}
                                    alt=""
                                    className="img-fluid"
                                    onClick={handleAddProductInput}
                                  />
                                ) : (
                                  <img
                                    src={closeCircle}
                                    alt=""
                                    className="img-fluid"
                                    onClick={() =>
                                      handleRemoveProductInput(idx)
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    {/* table end */}
                  </Row>

                  <div className="d-flex justify-content-end mt-3">
                    <input
                      type="submit"
                      disabled={adding}
                      value={adding ? 'Submitting...' : 'Create Plan'}
                      className="btn button mt-4"
                    />
                  </div>
                </Form>
              </AddCardComponent>
              <AddFileUpload
                setSelectedFiles={setSelectedFiles}
                acceptFile={
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                handleFile={(file) => {
                  readExcel(file);
                }}
              />
              <Modal isOpen={open} toggle={toggle} size="xl">
                <ModalBody>
                  <div className="table-responsive">
                    <Table className="table table-hover mb-0 table-centered table-nowrap mb-5">
                      <thead className="table-light">
                        <tr>
                          <th>No.</th>
                          {Object.keys(tableHead)?.map((item, idx) => (
                            <th key={idx}>{item}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {items?.map((item, idx) => (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item?.partnerCode}</td>
                            <td>{item?.warehouseId}</td>
                            <td>{item?.BPUserId}</td>
                            <td>{item?.productname}</td>
                            <td>{item?.quantity}</td>
                            <td>{item?.date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </ModalBody>
              </Modal>
              {items?.length > 0 ? (
                <div>
                  <button className="btn btn-primary me-2" onClick={toggle}>
                    Preview
                  </button>
                  <button
                    disabled={adding}
                    className="btn btn-primary "
                    onClick={handleSubmit}
                  >
                    {adding ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  );
};

export default withRouter(AddDisbursePlan);
