import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Col, Form, Input, Label, Row, Spinner, Table } from "reactstrap";
import {
  addDhChallanData,
  getChallanStock,
  storeChallanData,
} from "../../store/Challan/action";
import {
  getDistributionHub,
  getInvOption,
  getSingleDistribution,
} from "../../store/Distribution Hub/actions.js";
import { getCSDistributionInventory } from "../../store/CurrentStock/actions";
import { getBrand } from "../../store/Info/actions";
import { getProduct, storeProductData } from "../../store/Product/actions";
import AddFileUpload from "../Common/AddFileUpload";
import CustomInput from "../Common/CustomInput";
import AddCardComponent from "../Layout/AddCardComponent";
import InnerLayer from "../Layout/InnerLayer";
import { AddDhChallanSchema } from "../Schemas/AddDhChallanSchema";
import { toaster } from "../../helpers/custom/toaster";
import addCircle from "../../assests/images/add-circle.png";
import closeCircle from "../../assests/images/close-circle.png";
const breadcrumbItems = [
  {
    title: "Challan",
    link: "#",
  },
  {
    title: "Challan Create",
    link: "#",
  },
];
const initialValues = {
  distributionHubIdTo: "",
  InventoryOperation: "",
  deliveryFrom: "",
  centralWarehouseId: "",
  vendorId: "",
  chalancreatedBy: "",
  chalanCreatorPhone: "",
  chalanGiverName: "",
  chalanGiverPhone: "",
  warhouseAddress: "",
  // note: ""
};
const deliveryFrom = [
  {
    name: "Warehouse",
    _id: 1,
    value: "Warehouse",
  },
  {
    name: "Vendor",
    _id: 2,
    value: "Vendor",
  },
];
const AddDhChallan = () => {
  const history = useHistory();
  let dispatch = useDispatch();
  // const [deliveryfrom, setDeliveryFrom] = useState('');
  const [selectedFiles, setSelectedFiles] = useState({});
  console.log(`AddChallan ~ selectedFiles`, selectedFiles);
  const [challanInfo, setChallanInfo] = useState({
    itemId: "",
    quantity: "",
    name: "",
  });
  const [singleChallan, setsingleChallan] = useState([]);
  const addedStock = singleChallan.map((data) => data?.itemId);
  console.log("singleChallan", singleChallan);
  const {
    // role,
    products,
    authtoken,
    // productLoading,
    inventoryOperation,
    distributionHubData,
    brandData,
    singleDistribution,
    productList: productData,
    stokeList,
    challanListStock,
    userId,
    addDhChallanLoading,
    csDistributionInventory,
    csDistributionInventoryLoading,
  } = useSelector((state) => ({
    inventoryOperation: state.DistributionReducer.inventoryOperation,
    products: state.ProductReducer.products,
    brandData: state.InfoReducer.brandData,
    singleDistribution: state.DistributionReducer.singleDistribution,
    role: state.Login.userrole,
    productLoading: state.ProductReducer.productLoading,
    authtoken: state.Login.token,
    distributionHubData: state.DistributionReducer.distributionHubData,
    productList: state.ProductReducer.productList,
    stokeList: state.ChallanReducer.stokeList,
    challanListStock: state.ChallanReducer.challanListStock,
    addDhChallanLoading: state.ChallanReducer.addDhChallanLoading,
    userId: state.Login.warehouse,
    csDistributionInventory: state.CurrentStockReducer.csDistributionInventory,
    csDistributionInventoryLoading:
      state.CurrentStockReducer.csDistributionInventoryLoading,
  }));
  useEffect(() => {
    dispatch(getInvOption(authtoken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getDistributionHub(authtoken, 0, 0, "Central", ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, "Hub", ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(getBrand(authtoken, 0, 0, ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(getCSDistributionInventory(authtoken, "", "", userId, 1000, 1));
  }, []);
  console.log("csDistributionInventory", csDistributionInventory);
  const onSubmit = (values) => {
    console.log("hekjfksld");
    const { centralWarehouseId, vendorId, deliveryFrom, ...rest } = values;
    let body = {
      ...rest,
      productList: singleChallan,
    };

    body.distributionHubIdFrom = userId;
    body.status = "Pending";
    console.log("body", body);
    if (body?.productList.length !== 0) {
      dispatch(addDhChallanData(body, history, authtoken));
    } else {
      toast("Please Select Quantity", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // })
  };

  const handleAddProductInput = () => {
    if (challanInfo.itemId === "" || challanInfo.quantity === "") {
      return toaster("warning", "Please fill all the fields");
    }
    if (challanInfo.quantity <= 0) {
      return toaster("warning", "Quantity Can not be negative or zero");
    }
    if (challanInfo.quantity > challanInfo.stock) {
      return toaster("warning", "Quantity can not be bigger than Stock");
    }
    let data = {
      itemId: challanInfo?.itemId,
      quantity: challanInfo?.quantity * 1,
      name: challanInfo?.name,
      stock: challanInfo?.stock,
    };

    setsingleChallan([...singleChallan, data]);
    setChallanInfo({
      itemId: "",
      quantity: "",
    });
  };
  console.log("singleChallan", singleChallan);
  const handleRemoveProduct = (data) => {
    const removeProd = singleChallan.filter(
      (prod) => data?.itemId !== prod?.itemId
    );
    console.log("removeProd", removeProd);
    setsingleChallan(removeProd);
  };

  const AddDhChallan = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: AddDhChallanSchema,
    onSubmit,
  });

  const handleChallanInput = (e) => {
    setChallanInfo({
      ...challanInfo,
      quantity: e.target.value,
    });
  };
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      };
    },
  };

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Challan"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="mt-4">
            <Col md="11" className="mx-auto">
              {/* add User */}
              <AddCardComponent className="">
                <Form
                  className="needs-validation col-md-12"
                  onSubmit={AddDhChallan.handleSubmit}
                >
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md="12 mb-2">
                          <Label htmlFor="formrow-firstname-Input">
                            Select Distribution Hub
                          </Label>
                          <Select
                            name="distributionHubIdTo"
                            className="mb-2"
                            classNamePrefix="select2-selection"
                            placeholder="Select Distribution Hub"
                            cacheOptions
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.value}
                            isClearable
                            options={singleDistribution?.data?.Warehouse}
                            onChange={(e) => {
                              AddDhChallan.setFieldValue(
                                "distributionHubIdTo",
                                e?._id || ""
                              );
                            }}
                          />
                          {AddDhChallan.errors.distributionHubIdTo ? (
                            <p className="text-danger">
                              {AddDhChallan.errors.distributionHubIdTo}
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md="12 mb-2">
                          <CustomInput
                            name={"InventoryOperation"}
                            type={"select"}
                            label={"Select Inventory  Option"}
                            validationType={AddDhChallan}
                          >
                            <option defaultValue>
                              Select Inventory Option...
                            </option>
                            {inventoryOperation?.data?.length > 0 ? (
                              inventoryOperation?.data?.operation?.map(
                                (data, idx) => (
                                  <option value={data._id} key={idx}>
                                    {data?.operationName}
                                  </option>
                                )
                              )
                            ) : (
                              <span></span>
                            )}
                          </CustomInput>
                        </Col>
                        {/* <Col md="12 mb-2">
                          <CustomInput
                            name={"note"}
                            type={"textarea"}
                            label={"Note"}
                            placeholder={"Type..."}
                            validationType={AddChallan}
                            style={{ height: "80px" }}
                          />
                        </Col> */}
                      </Row>
                    </Col>
                    <Row>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalancreatedBy"}
                          type={"text"}
                          label={"Challan Creator Name"}
                          placeholder={"Type Creator Name"}
                          validationType={AddDhChallan}
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalanCreatorPhone"}
                          type={"text"}
                          label={"Challan Creator Phone"}
                          placeholder={"Type Creator Phone Number"}
                          validationType={AddDhChallan}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalanGiverName"}
                          type={"text"}
                          label={"Bp Name"}
                          placeholder={"Type Challan giver Name"}
                          validationType={AddDhChallan}
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalanGiverPhone"}
                          type={"number"}
                          label={"Bp Phone Number"}
                          placeholder={"Type Challan giver Phone Number"}
                          validationType={AddDhChallan}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalanReceiverName"}
                          type={"text"}
                          label={"Hub Manager Name"}
                          placeholder={"Type Hub manager Name"}
                          validationType={AddDhChallan}
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"chalanReceiverPhoneNumber"}
                          type={"number"}
                          label={"Hub Manager Phone Number"}
                          placeholder={"Type Manager Phone Number"}
                          validationType={AddDhChallan}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"warhouseAddress"}
                          type={"text"}
                          label={"Warehouse Address"}
                          placeholder={"Type Address"}
                          validationType={AddDhChallan}
                        />
                      </Col>
                      <Col md="6 mb-3">
                        <CustomInput
                          name={"note"}
                          type={"textarea"}
                          label={"Note"}
                          placeholder={"Type..."}
                          validationType={AddDhChallan}
                          style={{ height: "80px" }}
                        />
                      </Col>
                    </Row>
                  </Row>

                  <div>
            
                    <Row>
                      <div
                        className=""
                      >
                        <h6 className="mt-3 mb-4 fw-bold">
                          Add New Product in challan
                        </h6>
                        <Table className="table table-hover mb-0 table-centered  mb-5">
                          <thead className="table-light">
                            <tr>
                              <th>No.</th>
                              <th>Select Product</th>
                              <th>Input QTY</th>
                              <th>Stock</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>

                              <td
                                style={{
                                  width: "300px",
                                }}
                              >
                                {csDistributionInventoryLoading ? (
                                  <div className="text-center mt-5 pt-5 mb-5">
                                    <Spinner
                                      animation="border"
                                      variant="primary"
                                    />
                                  </div>
                                ) : (
                                  <Select
                                    name=""
                                    className="mb-2"
                                    classNamePrefix="select2-selection"
                                    placeholder="Select Product"
                                    styles={colourStyles}
                                    cacheOptions
                                    getOptionLabel={(e) => e.products?.name}
                                    getOptionValue={(e) => e.value}
                                    value={
                                      csDistributionInventory?.logdata?.find(
                                        (data) =>
                                          challanInfo?.itemId ===
                                          data?.products?._id
                                      ) || {}
                                    }
                                    isClearable
                                    options={csDistributionInventory?.logdata.filter(
                                      (data) =>
                                        !addedStock.includes(
                                          data?.products?._id
                                        )
                                    )}
                                    onChange={(e) => {
                                      setChallanInfo({
                                        ...challanInfo,
                                        itemId: e?.products?._id || "",
                                        name: e?.products?.name,
                                        stock: e?.stock,
                                      });
                                    }}
                                  />
                                )}
                              </td>

                              <td>
                                <Input
                                  type="number"
                                  style={{
                                    width: "100px",
                                    height: "38px",
                                  }}
                                  value={challanInfo?.quantity}
                                  onChange={(e) => {
                                    handleChallanInput(e);
                                  }}
                                ></Input>
                              </td>
                              <td>{challanInfo?.stock}</td>
                              <td>
                                <img
                                  src={addCircle}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  className="img-fluid"
                                  onClick={() => handleAddProductInput()}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                    <Row>
                      <div className="table-responsive">
                        <h6 className="mb-3 fw-bold">
                          Added Product in challan
                        </h6>
                        <Table className="table table-hover mb-0 table-centered  mb-5">
                          <thead className="table-light">
                            <tr>
                              <th>No.</th>
                              <th>Product Name</th>

                              <th>QTY</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {false ? (
                              <tr>
                                <td colSpan="5">
                                  <h6 className="text-center">Loading...</h6>
                                </td>
                              </tr>
                            ) : (
                              (singleChallan || []).map((data, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>

                                    <td>{data?.name}</td>
                                    <td>{data?.quantity}</td>
                                    <td>
                                      <img
                                        src={closeCircle}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                        className="img-fluid"
                                        onClick={() =>
                                          handleRemoveProduct(data)
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </Table>

                        {addDhChallanLoading ? (
                          <div className="d-flex justify-content-end mt-3">
                            <Spinner className="ms-2" color="primary" />
                          </div>
                        ) : (
                          <div className="d-flex justify-content-end mt-3">
                            <input
                              type="submit"
                              value={"Add Challan"}
                              className="btn button "
                            />
                          </div>
                        )}
                      </div>
                    </Row>
                  </div>
                </Form>
              </AddCardComponent>
              <AddFileUpload setSelectedFiles={setSelectedFiles} />
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  );
};

export default AddDhChallan;
