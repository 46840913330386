import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Row, Spinner, Table } from "reactstrap";
import * as XLSX from "xlsx";
import { get } from "../../helpers/api_helper";
import { getDisburseReport } from "../../store/Disburse/action";
import { getSingleDistribution } from "../../store/Distribution Hub/actions";
import { getUserRole } from "../../store/User/actions";
import CardComponent from "../Layout/CardComponent";
import CustomTable from "../Layout/CustomTable";
import InnerLayer from "../Layout/InnerLayer";
import NoTableData from "../Layout/NoTableData";
const tableHead = [
  "No.",
  "Hub Name",
  "BP. Name",
  "Partner",
  "Category",
  "Brand",
  "Product",
  "QTY",
  "Status",
  "Date",
];

const renderTooltipView = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    View
  </Tooltip>
);
const Hub = [
  {
    name: "Hub 1",
    _id: 1,
    value: "Hub 1",
  },
  {
    name: " Hub2",
    _id: 2,
    value: "Hub2",
  },
];

const StockIn = ({ history }) => {
  let dispatch = useDispatch();
  const [bp, setBp] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(10);
  const [detailShow, setDetailShow] = useState(false);
  const [distribution, setDistribution] = useState("");
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const {
    allUserRole,
    loading,
    authtoken,
    partnerList,
    singleDistribution,
    products,
    adding,
    role,
    disburseReport,
    disburseReportLoading,
  } = useSelector((state) => ({
    allUserRole: state.UserReducer.allUserRole,
    role: state.Login.userrole,
    singleDistribution: state.DistributionReducer.singleDistribution,
    products: state.ProductReducer.products,
    partnerList: state.DisburseReducer.partnerList,
    disburseReport: state.DisburseReducer.disburseReport,
    disburseReportLoading: state.DisburseReducer.disburseReportLoading,
    adding: state.DisburseReducer.adding,
    authtoken: state.Login.token,
  }));
  console.log("disburseReport", disburseReport);
  useEffect(() => {
    dispatch(getUserRole(authtoken));
  }, []);

  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, "Hub"));
  }, []);
  useEffect(() => {
    dispatch(
      getDisburseReport(
        authtoken,
        startDate,
        bp,
        distribution,
        currentPage,
        pageRange
      )
    );
  }, [startDate, bp, distribution, currentPage, pageRange]);
  let totalPageNumber = Math.ceil(disburseReport?.data?.length / pageRange);

  const handleRole = (value) => {
    console.log("value", value);
    if (value) {
      setBp(value?._id);
    } else {
      setBp("");
    }
  };
  const handleDistributionHub = (value) => {
    if (value) {
      setDistribution(value?._id);
    } else {
      setDistribution("");
    }
  };
  const handleStartDate = (e) => {
    console.log("start", e.target.value);
    const value = e.target.value;
    setStartDate(moment(value).format("YYYY-MM-DD"));
  };
  const handleRange = (e) => {
    setPageRange(e.target.value);
  };
  const handleExcel = async () => {
    setLoadingExcel(true);
    let disburseData = [];
    let totalPage = Math.ceil(disburseReport?.data?.length);
    for (let i = 0; i < totalPage; i++) {
      await get(
        `disbursementplan/get-all-disbursementplan?dateId=${startDate}&selectBp=${bp}&hubId=${distribution}&limit=${pageRange}&pageNo=${
          i + 1
        }`,
        { headers: { Authorization: `Bearer ${authtoken}` } }
      )
        .then((response) => {
          console.log("response", response);

          response?.disbursePlan.forEach((disburse, index) => {
            let data = {};
            data.HubName = disburse?.warhouses?.name;
            data.BpName = disburse?.BPInfo?.name;
            data.Partner = disburse?.partnerInfo?.outletName;
            data.Category = disburse?.categories?.name;
            data.Brand = disburse?.brand?.name;
            data.Product = disburse?.products?.name;
            data.Quantity = disburse?.itemQuantity;
            data.Date = disburse?._id?.dateString;

            disburseData.push(data);
          });
        })
        .catch((error) => {
          console.log("kkkk");
          // setResults((prev) => [...prev]);
        });
    }

    downloadxls(disburseData);
  };

  const downloadxls = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Disburse List.xlsx");
    setLoadingExcel(false);
  };
  return (
    <React.Fragment>
      <InnerLayer
        title="Disburse Plan"
        wrapperClass="py-3 users"
        isBreadCrumb={true}
        link={"#"}
        mainTitle={"Disburse Plan"}
        subTitle={"Disburse Plan List"}
        onAddHandler={() => history.push("/disburse-plan/add-disburse-plan")}
        buttonText="Create New"
        isButton={role === "SUPERADMIN" || role ==="ADMIN"? true : false}
      >
        <Row>
          <CardComponent className="user_table_card">
            <Row className="mb-3 table-header-padding">
              <div className="d-flex d-flex-blockCustom justify-content-end">
                <Col md={2} className="me-2 custom-bottom-margin">
                  <Input
                    name="toDate"
                    type="date"
                    onChange={handleStartDate}
                    value={startDate}
                  />
                </Col>
                <Col md={2} className="custom-bottom-margin">
                  <Select
                    name="bp"
                    className="me-2"
                    classNamePrefix="select2-selection"
                    placeholder="Select BP"
                    cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    options={allUserRole?.users}
                    onChange={handleRole}
                  />
                </Col>
                <Col md={3}  className="custom-bottom-margin">
                  <Select
                    name="hub"
                    className="me-2"
                    classNamePrefix="select2-selection"
                    placeholder="Distribution Hub"
                    cacheOptions
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.value}
                    isClearable
                    options={singleDistribution?.data?.Warehouse}
                    onChange={handleDistributionHub}
                  />
                </Col>

                <Col md={2} className="me-2">
                  <Input
                    type="select"
                    onChange={(e) => handleRange(e)}
                    className="me-2 custom-bottom-margin"
                  >
                    {/* <option defaultValue>Select...</option> */}
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Input>
                </Col>
              </div>
            </Row>
            {/* table */}
            <Row>
              <CustomTable
                paginationClass="paginationContainer text-right"
                data={disburseReport?.data?.disbursePlan}
                pageNo={totalPageNumber}
                tableHead={tableHead}
                setCurrentPage={setCurrentPage}
                isPagination
              >
                {disburseReportLoading ? (
                  <tr style={{ width: "100%" }}>
                    <div
                      className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </div>
                  </tr>
                ) : disburseReport?.data?.length > 0 ? (
                  disburseReport?.data?.disbursePlan?.map((data, idx) => (
                    <tr>
                      <th scope="row" style={{ paddingLeft: "25px" }}>
                        {idx + 1}
                      </th>
                      <td>{data?.warhouses?.name}</td>
                      <td>{data?.BPInfo?.name}</td>
                      <td>{data?.partnerInfo?.outletName}</td>
                      <td>{data?.categories?.name}</td>
                      <td>{data?.brand?.name}</td>
                      <td>{data?.products?.name}</td>
                      <td>{data?.itemQuantity}</td>
                      <td>Processing</td>
                      <td>{data?._id?.dateString}</td>
                     
                      {/* <td>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipView}
                        >
                          <button
                            className="btn btn-secondary btn-sm me-2"
                            style={{ borderRadius: "10px" }}
                            onClick={() => setDetailShow(true)}
                          >
                            <i className="bx bx-show mt-1"></i>
                          </button>
                        </OverlayTrigger>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <NoTableData
                    colSpan=""
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "300px", width: `100%` }}
                  >
                    <span>No data Available</span>
                  </NoTableData>
                )}
              </CustomTable>
              {/* table end */}
              {/* modal for detail */}

              <Modal
                size="lg"
                show={detailShow}
                onHide={() => setDetailShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header
                  closeButton
                  style={{ borderBottom: "none" }}
                ></Modal.Header>
                <Modal.Body style={{ padding: "3px 25px" }}>
                  <h4 className="text-center" style={{ color: "#0C1D37" }}>
                    Disburse Plan View
                  </h4>

                  <Row className="mt-5 mb-4 ">
                    <Col md="6">
                      <h6 className="text-black">BP Name</h6>
                      <span className="">Kiaser</span>
                    </Col>
                    <Col md="6">
                      <h6 className="text-black">Date</h6>
                      <span>10/05/2022</span>
                    </Col>
                  </Row>

                  <Row className="mb-5">
                    <Col md="6">
                      <h6 className="text-black">Hub Name</h6>
                      <span>Hb-1</span>
                    </Col>
                  </Row>

                  <h5 style={{ color: "#1C1D1E" }}>Disburse Plan Details</h5>
                  <Table className="table table-hover mb-0 table-centered table-nowrap mb-5">
                    <thead className="table-light">
                      <tr>
                        <th>Sl. No.</th>
                        <th>Shop</th>
                        <th>Product</th>
                        <th>QTY</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>test</td>
                        <td>test</td>
                        <td>23</td>
                      </tr>
                    </tbody>
                  </Table>
                </Modal.Body>
              </Modal>
            </Row>
          </CardComponent>
          <button
            className="btn btn-info w-25 m-auto fw-bold excel_button mb-2"
            disabled={loadingExcel}
            onClick={handleExcel}
          >
            {loadingExcel ? "Downloading..." : "Download Excel"}
          </button>
        </Row>
      </InnerLayer>
    </React.Fragment>
  );
};

StockIn.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(StockIn);
