import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";
import { getChallanDetails } from "../../store/Challan/action";
import AddCardComponent from "../Layout/AddCardComponent";
import InnerLayer from "../Layout/InnerLayer";
import back_left from "./img/back_left.svg";
import back_right from "./img/back_right.svg";
import merp from "./img/merp.svg";

// const breadcrumbItems = [
//   {
//     title: 'Challan',
//     link: '#',
//   },
//   {
//     title: 'Challan Info',
//     link: '#',
//   },
// ];

const PrintChallan = () => {
  // const history = useHistory();
  let dispatch = useDispatch();
  const { id } = useParams();
  const {
    // role,
    authtoken,
    // challanData,
    // singleDistribution,
    challanDetails,
    // userId,
    // challanDataLoading,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    role: state.Login.userrole,
    challanData: state.ChallanReducer.challanData,
    challanDataLoading: state.ChallanReducer.challanDataLoading,
    singleDistribution: state.DistributionReducer.singleDistribution,
    challanDetails: state.ChallanReducer.challanDetails,
    userId: state.Login.warehouse,
  }));
  console.log("challanDetails", challanDetails);
  useEffect(() => {
    dispatch(getChallanDetails(authtoken, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <InnerLayer
      // isMultiple
      // title={"Challan"}
      // breadcrumbItems={breadcrumbItems}
      >
        <div style={{ minHeight: "100vh" }} className="position-relative">
          <Row className="">
            <Col md="11" className="mx-auto">
              {/* add User */}

              <AddCardComponent className="">
                <img
                  src={merp}
                  alt=""
                  style={{ width: "12%" }}
                  className="mb-5 mt-5"
                />
                <img
                  src={back_right}
                  alt=""
                  style={{
                    width: "20%",
                    position: "absolute",
                    right: "0",
                    top: "0",
                  }}
                />

                <h3 style={{ color: "black" }}>Challan Information</h3>
                <Row style={{ marginTop: "10px" }}>
                  <Col sm="3" xs="3" md="3">
                    <h6 className="text-black mt-5">Challan No.</h6>
                    <span>CH-{challanDetails?.data?.challan?.challanNo}</span>
                  </Col>
                  <Col sm="3" xs="3" md="3">
                    <h6 className="text-black mt-5">Challan Date</h6>
                    <span>
                      {challanDetails?.data?.challan?.challanDate.slice(0, 10)}
                    </span>
                  </Col>
                  <Col sm="3" xs="3" md="3"></Col>
                  <Col sm="3" xs="3" md="3"></Col>
                </Row>
                <Row className="mt-4 mb-3">
                  <Col sm="3" xs="3" md="3">
                    <h6 className="text-black mt-4">Distribution Hub</h6>
                    <span>
                      {" "}
                      {challanDetails?.data?.challan?.distributionHubId?.name}
                    </span>
                  </Col>
                  <Col sm="3" xs="3" md="3">
                    <h6 className="text-black mt-4">Date of Receipt</h6>
                    <span>
                      {" "}
                      {challanDetails?.data?.challan?.challanReceivedDate}
                    </span>
                  </Col>
                  <Col sm="3" xs="3" md="3">
                    <h6 className="text-black mt-4">Delivery Source</h6>
                    <span> {challanDetails?.data?.challan?.deliveryFrom}</span>
                  </Col>
                  {challanDetails?.data?.challan?.deliveryFrom ===
                  "Warehouse" ? (
                    <Col sm="3" xs="3" md="3">
                      <h6 className="text-black mt-4">Warehouse Name</h6>
                      <span>
                        {
                          challanDetails?.data?.challan?.centralWarehouseId
                            ?.name
                        }
                      </span>
                    </Col>
                  ) : (
                    <Col sm="3" xs="3" md="3">
                      <h6 className="text-black mt-3">Vendor Name</h6>
                      <span>
                        {challanDetails?.data?.challan?.vendorId?.name}
                      </span>
                    </Col>
                  )}
                </Row>
                <Row>
                  {/* {challanDetails?.data?.challan?.chalancreatedBy ? (
                    <Col sm="3" className="mb-3">
                      <h6 className="text-black">Challan Issuer name</h6>
                      <span>
                        {challanDetails?.data?.challan?.chalancreatedBy}
                      </span>
                    </Col>
                  ) : null} */}
                  {/* {challanDetails?.data?.challan?.chalanCreatorPhone ? (
                    <Col sm="3" className="mb-3">
                      <h6 className="text-black">Challan Issuer phone</h6>
                      <span>
                        {challanDetails?.data?.challan?.chalanCreatorPhone}
                      </span>
                    </Col>
                  ) : null} */}
                  {challanDetails?.data?.challan?.chalanGiverName ? (
                    <Col sm="3" xs="3" md="3" className="mb-3">
                      <h6 className="text-black">BP Name</h6>
                      <span>
                        {challanDetails?.data?.challan?.chalanGiverName}
                      </span>
                    </Col>
                  ) : null}
                  {challanDetails?.data?.challan?.chalanGiverPhone ? (
                    <Col sm="3" xs="3" md="3" className="mb-3">
                      <h6 className="text-black">BP Phone Number</h6>
                      <span>
                        {challanDetails?.data?.challan?.chalanGiverPhone}
                      </span>
                    </Col>
                  ) : null}
                  {challanDetails?.data?.challan?.warhouseAddress ? (
                    <Col sm="3" xs="3" md="3" className="mb-3">
                      <h6 className="text-black">Warehouse Address</h6>
                      <span>
                        {challanDetails?.data?.challan?.warhouseAddress}
                      </span>
                    </Col>
                  ) : null}
                  {/* {challanDetails?.data?.challan?.chalanReceiverName ? (
                    <Col sm="3" className="mb-3">
                      <h6 className="text-black">Hub Manager</h6>
                      <span>
                        {challanDetails?.data?.challan?.chalanReceiverName}
                      </span>
                    </Col>
                  ) : null} */}
                  {/* {challanDetails?.data?.challan?.chalanReceiverPhoneNumber ? (
                    <Col sm="3" className="mb-3">
                      <h6 className="text-black">Hub Manager Phone</h6>
                      <span>
                        {
                          challanDetails?.data?.challan
                            ?.chalanReceiverPhoneNumber
                        }
                      </span>
                    </Col>
                  ) : null} */}
                </Row>
                <Row className="mb-5">
                  <div className="">
                    <div
                      style={{
                        border: "1px solid #B0B2B4",
                        borderRadius: "4px",
                        padding: "15px 70px 15px 15px",
                        display: "inline-block",
                      }}
                    >
                      <h6 className="text-black mt-3 mb-4">Note</h6>
                      <span>{challanDetails?.data?.challan?.note}</span>
                    </div>
                  </div>
                </Row>
                <div className="table-responsive mt-5">
                  <Table className="table table-hover mb-0 table-centered  mb-5">
                    <thead className="table-light mt-5">
                      <tr>
                      <th>No.</th>
                            {/* <th>Category</th>
                            <th>Brand</th> */}
                            <th>Product</th>
                            <th>QTY</th>

                            <th>Received QTY</th>
                            <th>Missing QTY</th>
                            <th>Buffer QTY</th>
                            <th>Damage QTY</th>
                            <th>Claim QTY</th>
                            <th>Settle QTY</th>
                      </tr>
                    </thead>
                    {(challanDetails?.data?.challanDetails || []).map(
                      (data, key) => {
                        return (
                          <>
                            <tbody>
                              <tr>
                              <td>{key + 1}</td>
                                  {/* <td>{data?.itemId?.category?.name}</td>
                                  <td>{data?.itemId?.brand?.name}</td> */}
                                  <td>{data?.itemId?.name}</td>
                                  <td>{data?.quantity}</td>

                                  <td>{data?.actualReceive}</td>
                                  {
                                    data?.quantity > data?.actualReceive ?
                                    <td>{data?.quantity - data?.actualReceive}</td>
                                    :
                                    <td>0</td>
                                  }
                                  {
                                    data?.quantity < data?.actualReceive ?
                                    <td>{data?.actualReceive - data?.quantity}</td>
                                    :
                                    <td>0</td>
                                  }
                                  
                                 
                                  {/* {isNaN(
                                      data?.quantity - data?.receivedQty
                                    ) ? (
                                      <td>0</td>
                                    ) : (
                                      <td>
                                        {data?.quantity - data?.receivedQty}
                                      </td>
                                    )} */}
                                  <td>{data?.damageQty}</td>
                                  <td>{data?.claimQty}</td>
                              </tr>
                            </tbody>
                          </>
                        );
                      }
                    )}
                  </Table>
                  <div className="d-flex justify-content-between align-items-center mt-5">
                    <div style={{ width: "150px" }}>
                      <div className="border_top"></div>
                      <h6 className="text-center">{challanDetails?.data?.challan?.chalancreatedBy} ({challanDetails?.data?.challan?.chalanCreatorPhone})</h6>
                    </div>
                 
                    <div style={{ width: "150px" }}>
                      <div className="border_top"></div>
                      <h6 className="text-center">{challanDetails?.data?.challan?.chalanReceiverName} (   {
                          challanDetails?.data?.challan
                            ?.chalanReceiverPhoneNumber
                        })</h6>
                    </div>
                 
                  </div>
                </div>
                {/* <p className="mb-4 mt-4 fw-bold">
                  This is an autogenerated Challan. Therefore, no signature is
                  needed.
                </p> */}
                <p>
                  Design & Developed by Market Access Analytics and Consulting
                  (MAAC)
                </p>
                <img
                  src={back_left}
                  alt=""
                  style={{
                    width: "20%",
                    position: "absolute",
                    left: "0",
                    bottom: "0",
                  }}
                />
              </AddCardComponent>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  );
};
// render(<PrintChallan />, document.querySelector("#root"));
export default PrintChallan;
