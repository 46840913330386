import { getData, postData } from "../../helpers/api_helper"
import { call, put, takeEvery } from "redux-saga/effects"

import {
    getCentalWarehouseSuccess,
    getCentalWarehouseFail,
    getDistributionHubInvSuccess,
    getDistributionHubInvFail,
    getBpInventorySuccess,
    getBpInventoryFail,
    getReconciliationSuccess,
    getReconciliationFail,
    getCwStockReportSuccess,
    getCwStockReportFail,
    getDhStockReportSuccess,
    getDhStockReportFail
} from "./actions"
import {
    GET_CENTRAL_WAREHOUSE,
    GET_DISTRIBUTION_HUB_INV,
    GET_BP_INVENTORY,
    GET_RECONCILIATION,
    GET_CW_STOCK_REPORT,
    GET_DH_STOCK_REPORT
} from "./actionTypes"

function* fetchCentralWareHouse({ payload: { authtoken, startDate, endDate, csDistribution, pageRange, currentPage, category, brand, product } }) {

  console.log('startDate',startDate);
  try {
    const url = `centralstock/central-log-report-by-date?fromDate=${startDate}&toDate=${endDate}&warehouse=${csDistribution}&limit=${pageRange}&pageNo=${currentPage}&category=${category}&brand=${brand}&product=${product}`
    const response = yield call(getData, url, authtoken)

    yield put(getCentalWarehouseSuccess(response))
  } catch (error) {
    yield put(getCentalWarehouseFail(error))
  }
}
function* fetchDistributionInvHub({ payload: { authtoken,startDate, endDate, category, brand, product, distribution, pageRange, currentPage } }) {
    try {
   
      const url = `dh-inventory/get-hub-log-report?fromDate=${startDate}&toDate=${endDate}&warehouse=${distribution}&limit=${pageRange}&pageNo=${currentPage}&category=${category}&brand=${brand}&product=${product}`
      const response = yield call(getData, url, authtoken)
  
      yield put(getDistributionHubInvSuccess(response))
    } catch (error) {
      yield put(getDistributionHubInvFail(error))
    }
  }

//   function* fetchBpInventory({ payload: { authtoken} }) {
//     try {
//       // console.log(role)
//       // const newQuery = `?${query}`
//       const url = `/org/organizationDashboard/dashboardbannerone`
//       const response = yield call(getData, url, authtoken)
  
//       yield put(getBpInventorySuccess(response))
//     } catch (error) {
//       yield put(getBpInventoryFail(error))
//     }
//   }

function* fetchcWStockReport({ payload: { data, history, authtoken } }) {
  try {
 
    const url = `centralstock/central-log-report-by-ids`
    const response = yield call(postData, url, data, authtoken)

    yield put(getCwStockReportSuccess(response))
  } catch (error) {
    yield put(getCwStockReportFail(error))
  }
}

function* fetchdhStockReport({ payload: { data, history, authtoken } }) {
  try {
 
    const url = `dh-inventory/hub-log-report-by-ids`
    const response = yield call(postData, url, data, authtoken)

    yield put(getDhStockReportSuccess(response))
  } catch (error) {
    yield put(getDhStockReportFail(error))
  }
}



  

function* InventoryReportSaga() {
    yield takeEvery(GET_CENTRAL_WAREHOUSE, fetchCentralWareHouse )
    yield takeEvery(GET_DISTRIBUTION_HUB_INV, fetchDistributionInvHub )
    // yield takeEvery(GET_BP_INVENTORY, fetchBpInventory)
    // yield takeEvery(GET_RECONCILIATION, fetchReconcilitaionData)
   
    yield takeEvery(GET_CW_STOCK_REPORT, fetchcWStockReport)
    yield takeEvery(GET_DH_STOCK_REPORT, fetchdhStockReport)
 
  }
  
  export default InventoryReportSaga