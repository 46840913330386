export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";

export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const GET_SINGLE_PRODUCT_SUCCESS = "GET_SINGLE_PRODUCT_SUCCESS";
export const GET_SINGLE_PRODUCT_FAIL = "GET_SINGLE_PRODUCT_FAIL";

export const GET_FILTERING_PRODUCT = "GET_FILTERING_PRODUCT";
export const GET_FILTERING_PRODUCT_SUCCESS = "GET_FILTERING_PRODUCT_SUCCESS";
export const GET_FILTERING_PRODUCT_FAIL = "GET_FILTERING_PRODUCT_FAIL";


// add
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

export const ADD_PRODUCT_STOCK = "ADD_PRODUCT_STOCK";
export const ADD_PRODUCT_STOCK_SUCCESS = "ADD_PRODUCT_STOCK_SUCCESS";
export const ADD_PRODUCT_STOCK_FAIL = "ADD_PRODUCT_STOCK_FAIL";


export const UPDATE_PRODUCT_STOCK = "UPDATE_PRODUCT_STOCK";



export const STORE_PRODUCT_DATA = "STORE_PRODUCT_DATA";







