import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import {
  Badge,
  BreadcrumbItem,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import * as XLSX from "xlsx";
import { get } from "../../helpers/api_helper";
import { getCSCentalInventory } from "../../store/CurrentStock/actions";
import { getSingleDistribution } from "../../store/Distribution Hub/actions";
import { getBrand, getCategory } from "../../store/Info/actions";
import Pagination from "../Common/Pagination";
import CardComponent from "../Layout/CardComponent";

const CentralInventory = ({ history }) => {
  let dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [warehouse, setWareHouse] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(100);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const {
    brandData,
    loading,
    authtoken,
    categoryData,
    csCentralInventory,
    singleDistribution,
    role,
    userId,
    csCentralInventoryLoading,
  } = useSelector((state) => ({
    categoryData: state.InfoReducer.categoryData,
    csCentralInventory: state.CurrentStockReducer.csCentralInventory,
    csCentralInventoryLoading:
      state.CurrentStockReducer.csCentralInventoryLoading,
    brandData: state.InfoReducer.brandData,
    singleDistribution: state.DistributionReducer.singleDistribution,
    authtoken: state.Login.token,
    role: state.Login.userrole,
    userId: state.Login.warehouse,
  }));
  console.log("csCentralInventory", csCentralInventory);
  let totalPageNumber = Math.ceil(csCentralInventory?.data?.length / pageRange);
  useEffect(() => {
    dispatch(
      getCSCentalInventory(
        authtoken,
        category,
        brand,
        role === "CENTRALWAREHOUSE" ? userId : warehouse,
        pageRange,
        currentPage
      )
    );
  }, [category, brand, pageRange, currentPage, warehouse]);
  useEffect(() => {
    dispatch(getBrand(authtoken, 0, 0, ""));
  }, []);
  useEffect(() => {
    dispatch(getCategory(authtoken, 0, 0, ""));
  }, []);
  useEffect(() => {
    dispatch(getSingleDistribution(authtoken, 0, 0, "Central"));
  }, []);

  const handleCategory = (value) => {
    if (value) {
      setCategory(value?._id);
    } else {
      setCategory("");
    }
  };
  const handleBrand = (value) => {
    if (value) {
      setBrand(value?._id);
    } else {
      setBrand("");
    }
  };
  const handleWarehouse = (value) => {
    if (value) {
      setWareHouse(value?._id);
    } else {
      setWareHouse("");
    }
  };

  const renderTooltipView = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View
    </Tooltip>
  );

  const handleExcel = async () => {
    setLoadingExcel(true);
    let centralInventoryData = [];
    let totalPage = csCentralInventory?.data?.length / 3;
    for (let i = 0; i < totalPage; i++) {
      console.log("hello");

      await get(
        `centralstock/get-central-stock?category=${category}&brand=${brand}&limit=${3}&pageNo=${
          i + 1
        }&centralHub=${role === "CENTRALWAREHOUSE" ? userId : warehouse}`,
        { headers: { Authorization: `Bearer ${authtoken}` } }
      )
        .then((response) => {
          console.log("response", response);

          response?.products.forEach((inventory, index) => {
            let data = {};
            data.productName = inventory?.itemId?.name;
            data.Brand = inventory?.itemId?.brand?.name;
            data.Category = inventory?.itemId?.category?.name;
            data.Stock = inventory?.stock;

            centralInventoryData.push(data);
          });
        })
        .catch((error) => {
          console.log("kkkk");
          // setResults((prev) => [...prev]);
        });
    }

    downloadxls(centralInventoryData);
  };

  const downloadxls = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "Central Inventory List.xlsx");
    setLoadingExcel(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="d-flex align-items-center justify-content-start mb-4">
                <div>
                  <h4 className="mb-0 font-size-16">Central Inventory</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0 p-0 mt-2">
                      <BreadcrumbItem>
                        <Link to="#">Current Stock</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">Central Inventory List</Link>
                      </BreadcrumbItem>
                    </ol>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <CardComponent className="user_table_card">
              <Row className="mb-3 table-header-padding">
                <div className="d-flex d-flex-blockCustom justify-content-end">
                  {role === "SUPERADMIN" || role === "ADMIN" ? (
                    <Col className="me-2" md={2}>
                      <Select
                        name="category"
                        className="mb-2"
                        classNamePrefix="select2-selection"
                        placeholder="Select Central House"
                        cacheOptions
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.value}
                        isClearable
                        options={singleDistribution?.data?.Warehouse}
                        onChange={handleWarehouse}
                      />
                    </Col>
                  ) : (
                    <span></span>
                  )}
                  <Col className="me-2" md={2}>
                    <Select
                      name="category"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Category"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={categoryData?.category}
                      onChange={handleCategory}
                    />
                  </Col>
                  <Col className="me-2" md={2}>
                    <Select
                      name="brand"
                      className="mb-2"
                      classNamePrefix="select2-selection"
                      placeholder="Select Brand"
                      cacheOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.value}
                      isClearable
                      options={brandData?.data?.brand}
                      onChange={handleBrand}
                    />
                  </Col>

                  <Col className="me-2" md={2}>
                    <Input type="select">
                      {/* <option defaultValue>Select...</option> */}
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </Input>
                  </Col>
                </div>
              </Row>
              {/* table */}
              <Row>
                <div className="table-responsive">
                  {csCentralInventoryLoading ? (
                    <div className="text-center mt-5 pt-5 mb-5">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <Table className="table table-hover mb-0 table-centered table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th>No.</th>
                          <th>Product Name</th>
                          <th>Brand</th>
                          <th>Category</th>
                          <th>Stock</th>
                          <th>Status</th>
                          
                        </tr>
                      </thead>
                      {(csCentralInventory?.data?.products || []).map(
                        (data, key) => {
                          return (
                            <>
                              <tbody>
                                <tr>
                                  <td>{key + 1}</td>

                                  <td>{data?.itemId?.name}</td>
                                  <td>{data?.itemId?.brand?.name}</td>
                                  <td>{data?.itemId?.category?.name}</td>
                                  <td>{data?.stock}</td>
                                  <td>
                                    {" "}
                                    <Badge
                                      color="warning"
                                      style={{ padding: "10px !important" }}
                                    >
                                      active
                                    </Badge>
                                  </td>
                             
                                </tr>
                              </tbody>
                            </>
                          );
                        }
                      )}
                    </Table>
                  )}
                  <Pagination
                    parentClass={`paginationContainer text-right py-3`}
                    reInitialize="reInitialize"
                    page={totalPageNumber}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
                {/* table end */}
              </Row>
            </CardComponent>
            <button
              className="btn btn-info w-25 m-auto fw-bold excel_button mb-4"
              disabled={loadingExcel}
              onClick={handleExcel}
            >
              {loadingExcel ? "Downloading..." : "Download Excel"}
            </button>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CentralInventory.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(CentralInventory);
